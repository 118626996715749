
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from './model';

interface InitialState {
	clients: Client | null;
}

const initialState: InitialState = {
	clients: null,
};
 
export const clientSlice = createSlice({
	initialState,
	name: 'client',
	reducers: {
		setclient: (state, action: PayloadAction<Client>) => {
			state.clients = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
