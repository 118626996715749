import React, { useEffect, useState, useTransition } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import IconPlus from '@/components/Icon/IconPlus';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { useGetUsersQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { UserModel } from '@/app/Auth/redux/user';
import formatDateToLongForm from '@/utils/DateFormattter';
import Filter from '@/components/filters/Filter';
import { useGetcategoryQuery } from '..';
import { Category } from '../api';
import { UpdateCategory } from './UpdateCategory';
import { DeleteCategory } from './DeleteCategory';
import { AddCategory } from './AddCategory';
import { capitalize } from '@mui/material';


const Categories = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [role, setRole] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;


    const { isLoading, refetch, isFetching } = useGetcategoryQuery(
        { pageNumber, pageSize, from, to, search, status },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );
     
    const [sortedData, setSortedData] = useState<any>([]);
    const { categories } = useAppSelector((state: any) => state.categoryState);



    const resetFilters = () => {
        setRole('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Categories'));
    });

    
  

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, role, refetch]);

    const columns: TableColumn<Category>[] = [
        {
            title: 'Category Name',
            accessor: 'name',
            render: row => <p className="capitalize">{row?.name || '-'}</p>,
        },
        {
            title: 'Category Description',
            accessor: 'category.description',
            render: row => <p> {capitalize(row?.description || '')}</p>,
        },
        {
            title: 'Created At',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },
      
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p>
                    <span
                        className={`badge ${
                            row.status === 'active' ? 'bg-success' : 'bg-danger'
                        }`}
                    >
                        {row.status === 'active' ? 'Active' : 'Inactive'}
                    </span>
                </p>
            ),
        },
        {
            title: 'Actions',
            accessor: 'actions',
            textAlign: 'center',
            render: row => (
                <div className="flex gap-2 justify-start">
                    <UpdateCategory data={row} handleRefetch ={handleRefetch} />
                    <DeleteCategory data={row}  handleRefetch ={handleRefetch}  />
                </div>
            ),
        },
    ];

 
    const handleRefetch = async () => {
        try {
          setIsLoadingData(true);
          await refetch();
        } catch (error) {
          
        } finally {
          setIsLoadingData(false);
        }
      };

    return (
        <div className="   p-3 ">
            <div className="flex flex-row justify-between items-center my-2 ">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/admin"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>Categories</span>
                    </li>
                </ul>
                <AddCategory  handleRefetch ={handleRefetch}  />
            </div>

            <ExportDataTable
                columns={columns}
                data={categories?.list ?? []}
                total={categories?.total ?? 0}
                currentPage={categories?.currentPage ?? 0}
                nextPage={categories?.nextPage ?? 0}
                previousPage={categories?.previousPage ?? 0}
                lastPage={categories?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={<Filter
                    resetFilters={resetFilters}
                    title="Filter Categories"
                    children={<>
                        <div className="flex flex-col  ">
                            <div className="mb-4">
                                <label htmlFor="fromDate">From :</label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    className="form-input"
                                    onChange={e => setStartDateFilter(
                                        e.target.value
                                    )} />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="toDate" className="">
                                    To
                                </label>
                                <input
                                    type="date"
                                    id="toDate"
                                    className="form-input "
                                    onChange={e => setEndDateFilter(e.target.value)} />
                            </div>
                        </div>
                    </>} />} tableName="Categories"            />
        </div>
    );
};

export default Categories;
