import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link } from 'react-router-dom';
import {
  useGetUsersQuery,
} from '@/app/Auth/redux';
import ExportDataTable, {
  TableColumn,
} from '@/components/datatable';
import formatDateToLongForm from '@/utils/DateFormattter';
import Filter from '@/components/filters/Filter';
import { AddUser } from './AddUser';
import { UpdateUser } from './UpdateUser';
import { UserModel } from '@/app/Auth/redux/user';
import { DeleteUser } from './DeleteUser';


const Users = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [from, setStartDateFilter] = useState('');
  const [to, setEndDateFilter] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const search = queryParams.get('search') || '';
  const pageNumber = Number(queryParams.get('pageNumber')) || 1;
  const pageSize = Number(queryParams.get('pageSize')) || 10;
  const dispatch = useDispatch();

  const { isLoading, refetch, isFetching } = useGetUsersQuery(
    {
      pageNumber,
      pageSize,
      from,
      status,
      to,
      search,
      role,
    },
    {
      skip: !storage.getToken(),
      refetchOnMountOrArgChange: true,
    }
  );

  const handleRefetch = async () => {
    try {
      setIsLoadingData(true);
      await refetch();
    } catch (error) {
      
    } finally {
      setIsLoadingData(false);
    }
  };

  const { users } = useAppSelector((state: any) => state.usersState);
  const sortedData = users?.list || [];

  
  const resetFilters = () => {
    setRole('');
    setStartDateFilter('');
    setEndDateFilter('');
  };

  useEffect(() => {
    setIsLoadingData(isFetching);
  }, [isFetching]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingData(true);
        await refetch();
      } catch (error) {
        
      } finally {
        setIsLoadingData(false);
      }
    };

    if (!isFetching) {
      setIsLoadingData(true);
      fetchData();
    }
  }, [pageNumber, pageSize, from, to, search, role, refetch]);

  const columns: TableColumn<UserModel>[] = [
    {
      title: 'Names',
      accessor: 'name',
      render: (row) => <p className="capitalize">{row.name}</p>,
    },
    {
      title: 'Username',
      accessor: 'username',
      render: (row) => <p>{row.username} </p>,
    },
    {
      title: 'Email',
      accessor: 'email',
      render: (row) => <p>{row.email} </p>,
    },
    {
      title: 'Phone number',
      accessor: 'phoneNumber',
      render: (row) => <p>{row.phoneNumber} </p>,
    },
    {
      title: 'Role',
      accessor: 'role',
      render: (row) => <p>{row.role} </p>,
    },
    {
      title: 'Date Created  ',
      accessor: 'created_at',
      render: (row) => (
        <p>{formatDateToLongForm(row?.created_at)}</p>
      ),
    },
    {
      title: 'Status',
      accessor: 'status',
      render: (row) => (
        <p>
          {row?.status === '1' || row?.status === 'active' ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">Inactive</span>
          )}
        </p>
      ),
    },
    {
      title: 'Actions',
      accessor: 'actions',
      textAlign: 'center',
      render: (row) => (
        <div className="flex gap-2 justify-start">
          <UpdateUser user={row} handleRefetch = {handleRefetch} />
          <DeleteUser user={row}  handleRefetch={handleRefetch} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(setPageTitle('Users'));
  }, [dispatch]);

  return (
    <div className="p-3">
      <div className="flex flex-row justify-between items-center my-2">
        <ul className="flex space-x-2 rtl:space-x-reverse mb-4">
          <li>
            <Link
              to="/admin"
              className="text-primary hover:underline"
            >
              Dashboard
            </Link>
          </li>
          <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
            <span>Users</span>
          </li>
        </ul>
        <AddUser   handleRefetch = {handleRefetch}/>
      </div>
      <ExportDataTable
        columns={columns}
        data={sortedData ?? []}
        total={users?.total ?? 0}
        tableName="Users"   
        currentPage={users?.currentPage ?? 0}
        nextPage={users?.nextPage ?? 0}
        previousPage={users?.previousPage ?? 0}
        lastPage={users?.lastPage ?? 0}
        isLoading={isLoading}
        filterComponent={
          <Filter
            resetFilters={resetFilters}
            title="Filter users"
            children={
              <>
                <div className="flex flex-col  ">
                  <div className="mb-4">
                    <label htmlFor="fromDate">Filter role :</label>
                    <select
                      className="form-select"
                      name="role"
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="">Select role</option>
                      <option value="admin">Admin</option>
                      <option value="management">Manager</option>
                      <option value="finance">Finance</option>
                      <option value="technician">Technician</option>
                      <option value="logistic_manager">
                        Logistic manager
                      </option>
                      <option value="stock_manager">Stock manager</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="fromDate">Filter status :</label>
                    <select
                      className="form-select"
                      name="status"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Select status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>

                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="fromDate">From :</label>
                    <input
                      type="date"
                      id="fromDate"
                      className="form-input"
                      onChange={(e) =>
                        setStartDateFilter(e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="toDate" className="">
                      To
                    </label>
                    <input
                      type="date"
                      id="toDate"
                      className="form-input "
                      onChange={(e) => setEndDateFilter(e.target.value)}
                    />
                  </div>
                </div>
              </>
            }
          />
        }
      />
    </div>
  );
};

export default Users;
