
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StockModel } from './model';

interface InitialState {
	stock_in: StockModel | null;
}

const initialState: InitialState = {
	stock_in: null,
};
 
export const stock_inslice = createSlice({
	initialState,
	name: 'client',
	reducers: {
		setStckin: (state, action: PayloadAction<StockModel>) => {
			state.stock_in = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
