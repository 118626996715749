import toast from 'react-hot-toast';

import baseApi from '@/core/lib/base';
import { ResponseModel } from '@/core/types';
import { StockModel, stock_inslice } from './API';
import { stockInput } from './products/components/AddNewStock';

export const stock_insApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getstock_ins: builder.query<
            ResponseModel<StockModel>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                status?: string;
                search?: string;
                productId?: string;
            }
        >({
            query: queryParameters => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    status,
                    search,
                    productId,
                } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/stock-in',
                    params: {
                        pageNumber,
                        productId,
                        pageSize,
                        from,
                        to,
                        status,
                        search,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;

                    dispatch(stock_inslice.actions.setStckin(data));
                } catch (error) {
                    throw error;
                }
            },
        }),

        getStcokData: builder.query<
            ResponseModel<StockModel>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                status?: string;
                search?: string;
                categoryId?: string;
            }
        >({
            query: queryParameters => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    status,
                    search,
                    categoryId,
                } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/stock',
                    params: {
                        pageNumber,
                        categoryId,
                        pageSize,
                        from,
                        to,
                        status,
                        search,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;

                    dispatch(stock_inslice.actions.setStckin(data));
                } catch (error) {
                    throw error;
                }
            },
        }),
        Addstock_in: builder.mutation<
            ResponseModel<null>,
            { productId: string; data: Partial<stockInput> }
        >({
            query: ({ productId: id, data }: any) => ({
                method: 'POST',
                url: `/api/v1/stock/`,
                body: data,
                headers: {
                    id: id,
                },
            }),

            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('stock_in added successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),
        Updatestock_in: builder.mutation<
            ResponseModel<null>,
            { id: string; data: Partial<StockModel> }
        >({
            query: ({ id, data }): any => ({
                method: 'PATCH',
                url: `/api/v1/stock-in/${id}`,
                body: data,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;

                    toast.success('stock_in  Updated successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
        Deletstock_in: builder.mutation<void, string>({
            query: id => ({
                method: 'DELETE',
                url: `/api/v1/stock-in/${id}`,
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;

                    toast.success('stock in Deleted ');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),

        getStock_out: builder.query<
            ResponseModel<StockModel>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                status?: string;
                search?: string;
            }
        >({
            query: queryParameters => {
                const { pageNumber, pageSize, from, to, status, search } =
                    queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/stock-out',
                    params: {
                        pageNumber,
                        pageSize,
                        from,
                        to,
                        status,
                        search,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),

        RejectLogistic: builder.mutation<ResponseModel<null>, string>({
            query: id => ({
                method: 'POST',
                url: `/api/v1/logistic-request/reject/${id}`,
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Request rejected successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),
        AcceptLogistic: builder.mutation<ResponseModel<null>, string>({
            query: id => ({
                method: 'POST',
                url: `/api/v1/logistic-request/approve/${id}`,
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Request accepted successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),
        CompleteLogistic: builder.mutation<ResponseModel<null>, string>({
            query: id => ({
                method: 'POST',
                url: `/api/v1/logistic-request/complete/${id}`,
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Request  Completed successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),

        RejectTechnician: builder.mutation<ResponseModel<null>, string>({
            query: id => ({
                method: 'POST',
                url: `/api/v1/technician-request/${id}/reject`,
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Request rejected successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),
        AcceptTechnician: builder.mutation<ResponseModel<null>, string>({
            query: id => ({
                method: 'POST',
                url: `/api/v1/technician-request/${id}/approve`,
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Request accepted successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),
        CompleteTechnician: builder.mutation<ResponseModel<null>, string>({
            query: id => ({
                method: 'POST',
                url: `/api/v1/technician-request/${id}/complete`,
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Request  Completed successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),

        getTechniciain: builder.query<
            ResponseModel<StockModel>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                status?: string;
                search?: string;
                productId?: string;
                userId?: string;
            }
        >({
            query: queryParameters => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    status,
                    search,
                    productId,
                    userId,
                } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/technician-request',
                    params: {
                        pageNumber,
                        productId,
                        pageSize,
                        from,
                        to,
                        status,
                        userId,
                        search,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;

                    dispatch(stock_inslice.actions.setStckin(data));
                } catch (error) {
                    throw error;
                }
            },
        }),

        getInventoryReport: builder.query<
            ResponseModel<any>,
            { category?: any }
        >({
            query: queryParameters => {
                const { category } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/report/inventory-report',
                    params: {
                        category,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
        getSalesReport: builder.query<ResponseModel<any>, { clientId?: any }>({
            query: queryParameters => {
                const { clientId } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/report/sales-report',
                    params: {
                        clientId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
        getSalesReportmonthly: builder.query<
            ResponseModel<any>,
            { clientId?: any }
        >({
            query: queryParameters => {
                const { clientId } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/report/sales-report/monthly',
                    params: {
                        clientId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
        getSalesReportYearly: builder.query<
            ResponseModel<any>,
            { clientId?: any }
        >({
            query: queryParameters => {
                const { clientId } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/report/sales-report/yearly',
                    params: {
                        clientId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
        getNewSalesReport: builder.query<
            ResponseModel<any>,
            { clientId?: any }
        >({
            query: queryParameters => {
                const { clientId } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/report/new-sales-report',
                    params: {
                        clientId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
        getLeasingData: builder.query<
            ResponseModel<any>,
            { clientId?: any; branchId?: string }
        >({
            query: queryParameters => {
                const { clientId, branchId } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/report/leasing-report',
                    params: {
                        clientId,
                        branchId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
        getLeasingDataMonthly: builder.query<
            ResponseModel<any>,
            { clientId?: any; branchId?: string }
        >({
            query: queryParameters => {
                const { clientId, branchId } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/report/leasing-report/monthly',
                    params: {
                        clientId,
                        branchId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
        getLeasingDataYearly: builder.query<
            ResponseModel<any>,
            { clientId?: any; branchId?: string }
        >({
            query: queryParameters => {
                const { clientId, branchId } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/report/leasing-report/yearly',
                    params: {
                        clientId,
                        branchId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
        getNewSalesReportmonthly: builder.query<
            ResponseModel<any>,
            { clientId?: any }
        >({
            query: queryParameters => {
                const { clientId } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/report/new-sales-report/monthly',
                    params: {
                        clientId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
        getNewSalesReportYearly: builder.query<
            ResponseModel<any>,
            { clientId?: any }
        >({
            query: queryParameters => {
                const { clientId } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/report/new-sales-report/yearly',
                    params: {
                        clientId,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
    }),
});

export const {
    useGetstock_insQuery,
    useGetStcokDataQuery,
    useAddstock_inMutation,
    useDeletstock_inMutation,
    useUpdatestock_inMutation,
    useGetStock_outQuery,
    useAcceptLogisticMutation,
    useRejectLogisticMutation,
    useCompleteLogisticMutation,
    useAcceptTechnicianMutation,
    useRejectTechnicianMutation,
    useCompleteTechnicianMutation,
    useGetTechniciainQuery,
    useGetInventoryReportQuery,
    useGetSalesReportQuery,
    useGetNewSalesReportQuery,
    useGetLeasingDataQuery,
    useGetLeasingDataMonthlyQuery,
    useGetLeasingDataYearlyQuery,
    useGetNewSalesReportYearlyQuery,
    useGetNewSalesReportmonthlyQuery,
    useGetSalesReportYearlyQuery,
    useGetSalesReportmonthlyQuery,
} = stock_insApi;
