import React, { useEffect } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import IconPlus from '@/components/Icon/IconPlus';
import { Form } from '@/components/form';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { useAdminSignMutation } from '@/app/Auth/redux';
import { useDispatch } from 'react-redux';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import { TextareaField } from '@/components/textArea';
import { useAddLogisticRequestMutation } from '../..';

const schema = z.object({
    quantity: z.string().min(1, 'Quantity is  required'),
    reason: z.string().min(1, 'Reason is  required'),
});

export type stockInput = z.TypeOf<typeof schema>;

export const AddLogisticRequest = ({ data }: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [OnPost, { isSuccess, isLoading }] = useAddLogisticRequestMutation();

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary btn-sm m-1"
                >
                    Request this
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Add Logistic Request
                                        </div>
                                    </div>
                                    <div className="p-5 ">
                                        <Form<stockInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (payload: any) => {
                                                payload.quantity = parseInt(
                                                    payload.quantity,
                                                    10
                                                );
                                                payload.productId =
                                                    data.product.id;
                                                await OnPost({
                                                    id: data.product.id,
                                                    data: payload,
                                                });
                                                setModal1(false);
                                            }}
                                            className="mt-3  "
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="">
                                                        <p className="text-sm ">
                                                            Total quantities in
                                                            stock{' '}
                                                            <span className=" font-bold">
                                                                {data.quantity}
                                                            </span>
                                                        </p>
                                                        <InputField
                                                            type="text"
                                                            label=" Quantity"
                                                            placeholder="Quantities "
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .quantity
                                                            }
                                                            registration={register(
                                                                'quantity'
                                                            )}
                                                        />

                                                        <InputField
                                                            type="text"
                                                            label="Reason"
                                                            placeholder="  reason "
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .reason
                                                            }
                                                            registration={register(
                                                                'reason'
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex justify-end items-center mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger mx-2 "
                                                            onClick={() => {
                                                                setModal1(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Discard
                                                        </button>
                                                        {isLoading ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className=" btn btn-primary"
                                                            >
                                                                Add
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
