import toast from "react-hot-toast";

import baseApi from "@/core/lib/base";
import { ResponseModel } from "@/core/types";
import { Product, productslice } from "./api";
import { UpdateProductInput } from "./components/UpdateProduct";


;

export const productApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getproduct: builder.query<ResponseModel<Product>, { pageNumber?: number, pageSize?: number, from?: string, to?: string, model?: string, search?: string, }>({
      query: (queryParameters) => {
        const { pageNumber, pageSize, from, to, model,search } = queryParameters;
        return {
          method: "GET",
          url: "/api/v1/product",
          params: {
            pageNumber,
            pageSize,
            from,
            to,
            model,
            search
          },
        };
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
        
          dispatch(productslice.actions.setproducts(data));
        } catch (error) {
            
          throw error; 
        }
      },
    }),

    AddProduct: builder.mutation<ResponseModel<null>, Product>({
      query: (payload) => ({
        method: "POST",
        url: "/api/v1/product", 
        body: payload,
        
      }),
      
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
        
          const { data }: any = await queryFulfilled;
          toast.success("product added successfully");
         

        } catch (error: any) {
   
          toast.error(error?.error?.data?.message);
        }
      },
    }),
        Updateproduct: builder.mutation<ResponseModel<null>, { id: string; data: Partial<UpdateProductInput> }>({
      query: ({ id, data }):any => ({
        method: "PATCH",
        url: `/api/v1/product/${id}`, 
        body: data,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
    
        
          toast.success('product  Updated successfully');
        } catch (error:any) {
  
          toast.error(error?.error?.data?.message)
          throw error;
        }
      },
    }),
    Deleteproduct: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/api/v1/product/${id}`, 
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
    
       
          toast.success('product Status changed ');
      
        } catch (error:any) {
          toast.error(error?.error?.data?.message);
          throw error;
        }
      },
    }),
  }),
});

export const { useGetproductQuery,useAddProductMutation,useDeleteproductMutation,useUpdateproductMutation } = productApi;
