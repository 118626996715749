import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IRootState, useAppSelector } from '@/store';
import {
    setPageTitle,
    toggleRTL,
    toggleSidebar,
    toggleTheme,
} from '@/store/themeConfigSlice';

import profile from '@/assets/images/background/widgets/second.png';

import { storage } from '@/utils';
import toast from 'react-hot-toast';
import { useCheckTokenMutation, useWhoamiQuery } from '@/app/Auth/redux';

import IconBellBing from '@/components/Icon/IconBellBing';
import IconCaretDown from '@/components/Icon/IconCaretDown';
import IconLaptop from '@/components/Icon/IconLaptop';
import IconLogout from '@/components/Icon/IconLogout';
import IconMenu from '@/components/Icon/IconMenu';
import IconMoon from '@/components/Icon/IconMoon';
import IconSearch from '@/components/Icon/IconSearch';
import IconSun from '@/components/Icon/IconSun';
import IconUser from '@/components/Icon/IconUser';
import IconXCircle from '@/components/Icon/IconXCircle';
import { formatDistanceToNow } from 'date-fns';

import Dropdown from '@/components/dropdown';
import IconInfoCircle from '@/components/Icon/IconInfoCircle';
import { useGetNotificationsCountQuery, useGetNotificationsQuery } from '../api.notifications';

const Header = () => {
    const isRtl =
        useSelector((state: IRootState) => state.themeConfig.rtlClass) === 'rtl'
            ? true
            : false;

    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { productId } = useParams();
    const [search, setSearch] = useState(false);
    const [serial_number, setSerial_number] = useState('');
    const { isLoading, data } = useWhoamiQuery(null, {
        skip: !storage.getToken(),
        refetchOnMountOrArgChange: true,
    });

    const { user } = useAppSelector((state: any) => state.userState);
    const storedToken: any = storage.getToken();

    useEffect(() => {
        if (!storedToken && !user) {
            navigate('/login');
        }
    }, [navigate, storedToken, user]);

    const [checkToken] = useCheckTokenMutation();

    useEffect(() => {
        if (!storedToken) {
            navigate('/login');
            toast.error('Your session has expired. Please log in again.');
        }
    }, [checkToken, storedToken, navigate]);

    const logout = () => {
        storage.removeToken();
        navigate('/login');
        toast.error('You logged out');
    };

    const { t } = useTranslation();

    useEffect(() => {
        dispatch(setPageTitle('Dashbaord'));
    }, [dispatch]);
    const queryParams = new URLSearchParams(location.search);

    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const [notifications, setNotifications] = useState([]);

    const {
        isLoading: loadingNotifications,
        refetch,
        isFetching,
        data: Notifications,
    }: any = useGetNotificationsQuery(
        { pageNumber, pageSize },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );
   const {data:count}= useGetNotificationsCountQuery({}, {
        skip: !storage.getToken(),
        refetchOnMountOrArgChange: true,
    });
    
    return (
        <header
            className={`z-40 ${
                themeConfig.semidark && themeConfig.menu === 'horizontal'
                    ? 'dark'
                    : ''
            }`}
        >
            <div className="shadow-sm">
                <div className="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-black">
                    <div className="horizontal-logo flex items-center justify-between ltr:mr-2 rtl:ml-2 lg:hidden">
                        <Link
                            to="/"
                            className="main-logo flex shrink-0 items-start"
                        >
                            <img
                                className="inline w-8 ltr:-ml-1 rtl:-mr-1"
                                src={profile}
                                alt="logo"
                            />
                        </Link>
                        <button
                            type="button"
                            className="collapse-icon flex flex-none rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary ltr:ml-2 rtl:mr-2 dark:bg-dark/40 dark:text-[#d0d2d6] dark:hover:bg-dark/60 dark:hover:text-primary lg:hidden"
                            onClick={() => {
                                dispatch(toggleSidebar());
                            }}
                        >
                            <IconMenu className="h-5 w-5" />
                        </button>
                    </div>

                    <div className="flex items-center space-x-1.5 ltr:ml-auto rtl:mr-auto rtl:space-x-reverse dark:text-[#d0d2d6] sm:flex-1 ltr:sm:ml-0 sm:rtl:mr-0 lg:space-x-2">
                        <div className="sm:ltr:mr-auto sm:rtl:ml-auto">
                            <form
                                className={`${
                                    search && '!block'
                                } absolute inset-x-0 top-1/2 z-10 mx-4 hidden -translate-y-1/2 sm:relative sm:top-0 sm:mx-0 sm:block sm:translate-y-0`}
                                onSubmit={e => {
                                    e.preventDefault(); // Prevents the default form submission
                                    navigate(
                                        `/${user.role}/product-info/${serial_number}`
                                    );
                                }}
                            >
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="peer form-input 
                                        bg-gray-100 placeholder:tracking-widest ltr:pl-9 ltr:pr-9 rtl:pl-9 rtl:pr-9 sm:bg-transparent ltr:sm:pr-4 rtl:sm:pl-4"
                                        placeholder="Search serial number..."
                                        onChange={e =>
                                            setSerial_number(e.target.value)
                                        }
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-0 h-9 w-9 appearance-none peer-focus:text-primary ltr:right-auto rtl:left-auto"
                                    >
                                        <IconSearch className="mx-auto" />
                                    </button>
                                    <button
                                        type="button"
                                        className="absolute top-1/2 block -translate-y-1/2 hover:opacity-80 ltr:right-2 rtl:left-2 sm:hidden"
                                        onClick={() => setSearch(false)}
                                    >
                                        <IconXCircle />
                                    </button>
                                </div>
                            </form>
                            <button
                                type="button"
                                onClick={() => setSearch(!search)}
                                className="search_btn rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 dark:bg-dark/40 dark:hover:bg-dark/60 sm:hidden"
                            >
                                <IconSearch className="mx-auto h-4.5 w-4.5 dark:text-[#d0d2d6]" />
                            </button>
                        </div>
                        <div>
                            {themeConfig.theme === 'light' ? (
                                <button
                                    className={`${
                                        themeConfig.theme === 'light' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => {
                                        dispatch(toggleTheme('dark'));
                                    }}
                                >
                                    <IconSun />
                                </button>
                            ) : (
                                ''
                            )}
                            {themeConfig.theme === 'dark' && (
                                <button
                                    className={`${
                                        themeConfig.theme === 'dark' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => {
                                        dispatch(toggleTheme('system'));
                                    }}
                                >
                                    <IconMoon />
                                </button>
                            )}
                            {themeConfig.theme === 'system' && (
                                <button
                                    className={`${
                                        themeConfig.theme === 'system' &&
                                        'flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                                    }`}
                                    onClick={() => {
                                        dispatch(toggleTheme('light'));
                                    }}
                                >
                                    <IconLaptop />
                                </button>
                            )}
                        </div>
                        <div className="dropdown shrink-0">
                            <Dropdown
                                offset={[0, 8]}
                                placement={`${
                                    isRtl ? 'bottom-start' : 'bottom-end'
                                }`}
                                btnClassName="relative block p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60"
                                button={
                                    <span>
                                        <IconBellBing />
                                        <span className="flex absolute w-3 h-3 ltr:right-0 rtl:left-0 top-0">
                                            <span className="animate-ping absolute ltr:-left-[3px] rtl:-right-[3px] -top-[3px] inline-flex h-full w-full rounded-full bg-success/50 opacity-75"></span>
                                            <span className="relative inline-flex rounded-full w-[6px] h-[6px] bg-success"></span>
                                        </span>
                                    </span>
                                }
                            >
                                <ul className="!py-0 text-dark dark:text-white-dark w-[300px]  sm:w-[350px] divide-y dark:divide-white/10">
                                    <li onClick={e => e.stopPropagation()}>
                                        <div className="flex items-center px-4 py-2 justify-between font-semibold">
                                            <h4 className="text-lg">
                                                Notification
                                            </h4>
                                            {Notifications?.total > 0 ? (
                                                <span className="badge bg-primary/80">
                                                    {
                                                
                                                        Notifications.list.filter(
                                                            (notification:any) =>
                                                                !notification.isRead
                                                        ).length
                                                    } {''}
                                                    New
                                                </span>
                                            ) : (
                                                <span className="badge bg-primary/80">
                                                    No new
                                                </span>
                                            )}
                                        </div>
                                    </li>
                                    <div className='max-h-[60vh] overflow-scroll scrollbar-hidden '>
                                    {Notifications?.total > 0 ? (
                                        <>
                                            {Notifications?.list.map(
                                                (notification: any) => {
                                                    const timeAgo =
                                                        formatDistanceToNow(
                                                            new Date(
                                                                notification.created_at
                                                            ),
                                                            { addSuffix: true }
                                                        );

                                                       
                                     
                                                    return (
                                                        <li
                                                            key={
                                                                notification.id
                                                            }
                                                            className={`dark:text-white-light/90 `}
                                                            onClick={e =>
                                                                e.stopPropagation()
                                                            }
                                                        >
                                                            <div className={`group flex items-center px-4 py-2  `}>
                                                                <div className=" ">
                                                                    <div className="">
                                                                        <h6
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: notification.content,
                                                                            }}
                                                                        ></h6>
                                                                        <div className='flex fle-row gap-2 '>
                                                                        <h6
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: notification.title,
                                                                            }}
                                                                            className="font-bold"
                                                                        ></h6>
                                                                        <p>
                                                                        {
                                                                            !notification.isRead && (
                                                                                <span className="badge bg-blue-500">
                                                                                    New
                                                                                        
                                                                                    
                                                                                </span>
                                                                            )
                                                                        }
                                                                        </p>
                                                                        
                                                                        </div>
                                                                        
                                                                        <span className="text-xs block font-normal capitalize dark:text-gray-500">
                                                                            {
                                                                                timeAgo
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </>
                                    ) : (
                                        <li onClick={e => e.stopPropagation()}>
                                            <button
                                                type="button"
                                                className="!grid place-content-center hover:!bg-transparent text-lg min-h-[200px]"
                                            >
                                                <div className="mx-auto ring-4 ring-primary/30 rounded-full mb-4 text-primary">
                                                    <IconInfoCircle
                                                        fill={true}
                                                        className="w-10 h-10"
                                                    />
                                                </div>
                                                No notifications.
                                            </button>
                                        </li>
                                    )}
                                    </div>
                                   
                                </ul>
                            </Dropdown>
                        </div>
                        <div className="dropdown flex shrink-0">
                            <Dropdown
                                offset={[0, 8]}
                                placement={`${
                                    isRtl ? 'bottom-start' : 'bottom-end'
                                }`}
                                btnClassName="relative group block"
                                button={
                                    <img
                                        className="h-9 w-9 rounded-full object-cover saturate-50 group-hover:saturate-100"
                                        src={profile}
                                        alt="userProfile"
                                    />
                                }
                            >
                                <ul className="w-[230px] !py-0 font-semibold text-dark dark:text-white-dark dark:text-white-light/90">
                                    <li>
                                        <div className="flex items-center px-4 py-4">
                                            <img
                                                className="h-10 w-10 rounded-md object-cover"
                                                src={profile}
                                                alt="userProfile"
                                            />
                                            <div className="truncate ltr:pl-4 rtl:pr-4">
                                                <h4 className="text-base">
                                                    {user && user?.username}
                                                    <span className="rounded bg-success-light px-1 text-xs text-success ltr:ml-2 rtl:ml-2">
                                                        {' '}
                                                        {user && user?.role}
                                                    </span>
                                                </h4>
                                                <button
                                                    type="button"
                                                    className="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white"
                                                >
                                                    {user && user?.email}
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <Link
                                            to={`profile`}
                                            className="dark:hover:text-white"
                                        >
                                            <IconUser className="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                                            Profile
                                        </Link>
                                    </li>
                                    <li className="border-t border-white-light dark:border-white-light/10">
                                        <button onClick={logout}>
                                            <Link
                                                to="/auth/boxed-signin"
                                                className="flex flex-row !py-3 text-danger "
                                            >
                                                <IconLogout className="h-4.5 w-4.5 shrink-0 rotate-90 ltr:mr-2 rtl:ml-2" />
                                                Sign Out
                                            </Link>
                                        </button>
                                    </li>
                                </ul>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                {/* horizontal menu */}
            </div>
        </header>
    );
};

export default Header;
