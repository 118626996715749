import React, { useEffect, useState, useTransition } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import IconPlus from '@/components/Icon/IconPlus';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom';

import { useGetSalesQuery, useGetUsersQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { UserModel } from '@/app/Auth/redux/user';
import formatDateToLongForm from '@/utils/DateFormattter';

import Filter from '@/components/filters/Filter';

import Search from '@/components/search';
import { More } from './More';

const TechSales = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [role, setRole] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const { isLoading, refetch, isFetching,data }:any = useGetSalesQuery(
        { pageNumber, pageSize, from, to, search, status },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const resetFilters = () => {
        setRole('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

    const [sortedData, setSortedData] = useState<any>([]);
   

    useEffect(() => {
        if (data) {
            setSortedData(data?.list);
        }
    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, role, refetch]);

    const columns: TableColumn<any>[] = [
        {
            title: 'Selling Price',
            accessor: 'selling_price',
            render: row => <p className="capitalize">{row?.selling_price || '-'}</p>,
        },
        {
            title: 'Client Name',
            accessor: 'technician_mini_stock.client_name',
            render: row => <p>{row?.technician_mini_stock?.client_name|| '-'}</p>,
        },
        {
            title: 'Availability status',
            accessor: 'technician_mini_stock.availablility_status',
            render: row => <p>{row?.technician_mini_stock?.availablility_status || '-'}</p>,
        },
        {
            title: 'Type',
            accessor: 'technician_mini_stock.type',
            render: row => <p>{row?.technician_mini_stock?.type || '-'}</p>,
        },
        {
            title: 'Status',
            accessor: 'technician_mini_stock.status',
            render: row => <p>{row?.technician_mini_stock?.availablility_status || '-'}</p>,
        },
        {
            title: 'Availability status',
            accessor: 'technician_mini_stock.availablility_status.',
            render: row => <p>{row?.technician_mini_stock?.logistic_mini_stock.serialNumber || '-'}</p>,
        },
        {
            title: 'Product serial Number',
            accessor: 'technician_mini_stock.availablility_status.',
            render: row => <p>{row?.technician_mini_stock?.logistic_mini_stock.serialNumber || '-'}</p>,
        },
        {
            title: 'Product Name',
            accessor: 'technician_mini_stock.availablility_status.',
            render: row => <p>{row?.technician_mini_stock?.logistic_mini_stock?.product?.name || '-'}</p>,
        },
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => <p><More list = {row}/></p>,
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Sales'));
    });

    return (
        <><ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
            <li>
                <Link
                    to="/technician"
                    className="text-primary hover:underline"
                >
                    Dashboard
                </Link>
            </li>

            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                <span> Sales </span>
            </li>
        </ul><ExportDataTable
                columns={columns}
                tableName="Sales"   
                data={sortedData ?? []}
                total={data?.total ?? 0}
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={<Filter
                    resetFilters={resetFilters}
                    title="Filter sales"
                    children={<>
                        <div className="flex flex-col  ">

                            <div className="mb-4">
                                <label htmlFor="fromDate">From :</label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    className="form-input"
                                    onChange={e => setStartDateFilter(
                                        e.target.value
                                    )} />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="toDate" className="">
                                    To
                                </label>
                                <input
                                    type="date"
                                    id="toDate"
                                    className="form-input "
                                    onChange={e => setEndDateFilter(e.target.value)} />
                            </div>
                        </div>
                    </>} />} /></>
       
    );
};

export default TechSales;
