import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from '@reduxjs/toolkit';
import baseApi from '@/core/lib/base';
import config from '@/config';
import { useDispatch, useSelector } from 'react-redux';
import { TypedUseSelectorHook } from 'react-redux';
import themeConfigSlice from './themeConfigSlice';
import { userSlice } from '@/app/Auth/redux/user';
import { authApi } from '@/app/Auth/redux';
import { usersSlice } from '@/app/Auth/redux/user/usersSlice';
import { clientSlice } from '@/app/dashboard/admin/Clients/API';
import { branchlice } from '@/app/dashboard/admin/branch/API';
import { officeslice } from '@/app/dashboard/admin/offices/API';
import { productslice } from '@/app/dashboard/admin/products/api';
import { categoryslice } from '@/app/dashboard/admin/categories/api';
import { stock_inslice } from '@/app/dashboard/stock/API';

const rootReducer = combineReducers({
    themeConfig: themeConfigSlice,
    userState: userSlice.reducer,
    usersState: usersSlice.reducer,
    branchState: branchlice.reducer,
    clientState: clientSlice.reducer,
    productsState: productslice.reducer,
    categoryState: categoryslice.reducer,
    stockInState: stock_inslice.reducer,
    officeState: officeslice.reducer,
    [authApi.reducerPath]: authApi.reducer,
});

const middlewares: any = [baseApi.middleware];

export const store = configureStore({
    reducer: rootReducer,
    devTools: config.NODE_ENV === 'development',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(middlewares),
});

export type IRootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
