import React, { useEffect } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import IconPlus from '@/components/Icon/IconPlus';
import { Form } from '@/components/form';
import { z } from 'zod';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
    useAdminSignMutation,
    useDeleteUserMutation,
    useUpdateUserMutation,
} from '@/app/Auth/redux';
import { useDispatch } from 'react-redux';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import IconPencil from '@/components/Icon/IconPencil';
import IconEdit from '@/components/Icon/IconEdit';
import { toHaveStyle } from '@testing-library/jest-dom/matchers';
import IconTrash from '@/components/Icon/IconTrash';
import { FaReadme } from 'react-icons/fa';
import formatDateToLongForm from '@/utils/DateFormattter';
import { ReactI18NextChild } from 'react-i18next';
import IconChatDots from '@/components/Icon/IconChatDots';
import IconEye from '@/components/Icon/IconEye';

export const More = ({ data }: any) => {
    const [modal1, setModal1] = useState(false);
 
   
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary"
                >
                    <IconEye className="w-4" />
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full  ">
                                            Office Details
                                        </div>
                                    </div>
                                    <div className="p-5">
                                    <p className=" text-md flex flex-row justify-center">
                                                    {' '}
                                                    Office 
                                                </p>
                                        <div className=" grid grid-cols-2">
                                            <p className="font-bold ">
                                                Office Id:{' '}
                                                <span className=" font-normal text-sm">
                                                    {' '}
                                                    {data.id}
                                                </span>
                                            </p>
                                            <p className="font-bold ">
                                                Office Name:{' '}
                                                <span className=" font-normal text-sm">
                                                    {' '}
                                                    {data.name}
                                                </span>
                                            </p>

                                            <p className="font-bold ">
                                                Office Status
                                                <span className="font-normal text-sm ">
                                                    {' '}
                                                    {data.status}
                                                </span>{' '}
                                            </p>
                                           </div>
                                          

                                            <div className="mt-4">
                                            <div>
                                                <p className=" text-md flex flex-row justify-center">
                                                    {' '}
                                                    Client 
                                                </p>
                                                <div className="grid grid-cols-2 gap-2 mt-4 ">
                                                <p className="font-bold ">
                                                        Id:
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {data?.client?.id}
                                                        </span>
                                                    </p>
                                                   
                                                    <p className="font-bold ">
                                                        Name:
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {data?.client?.name}
                                                        </span>
                                                    </p>
                                                   
                                                    <p className="font-bold ">
                                                        Type:
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {data?.client?.type}
                                                        </span>
                                                    </p>
                                                    <p className="font-bold ">
                                                        Email:{' '}
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {
                                                                data?.cleint
                                                                    ?.email
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="font-bold ">
                                                        Phone:
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {
                                                                data?.client
                                                                    ?.phone
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="font-bold ">
                                                        Created At:
                                                        <span className=" font-normal text-sm">
                                                         
                                                            {' '}
                                                            { formatDateToLongForm(data?.client?.created_at)}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div>
                                                <p className=" text-md flex flex-row justify-center">
                                                    {' '}
                                                    Branch
                                                </p>
                                                <div className="grid grid-cols-2 gap-2 mt-4 ">
                                                    <p className="font-bold ">
                                                        Name:
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {data?.branch?.name}
                                                        </span>
                                                    </p>
                                                    <p className="font-bold ">
                                                        Location:
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {
                                                                data?.branch
                                                                    ?.location
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="font-bold ">
                                                        Person Name:{' '}
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {
                                                                data?.branch
                                                                    ?.person_name
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="font-bold ">
                                                        Email:{' '}
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {
                                                                data?.branch
                                                                    ?.email
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="font-bold ">
                                                        Phone:
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {
                                                                data?.branch
                                                                    ?.phone
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className="font-bold ">
                                                        Created At:
                                                        <span className=" font-normal text-sm">
                                                            {' '}
                                                            {formatDateToLongForm
                                                               ( data?.branch
                                                                    ?.created_at)
                                                            }
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-end items-center mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger mx-2 "
                                                onClick={() => {
                                                    setModal1(false);
                                                }}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
