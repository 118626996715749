
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from './model';

interface InitialState {
	products: Product | null;
}
const initialState: InitialState = {
	products: null,
};
 
export const productslice = createSlice({
	initialState,
	name: 'product',
	reducers: {
		setproducts: (state, action: PayloadAction<Product>) => {
			state.products = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
