import { IRootState } from '@/store';
import { useEffect, useState } from 'react';

import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { storage } from '@/utils';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { useGetNewSalesReportQuery, useGetSalesReportQuery } from '../../stock';
import SalesReportMonthly from './MonthlyReports';
import SalesReportYearly from './YearlyReports';
export default function SalesReport() {
    const [selectedTimeFrame, setSelectedTimeFrame] = useState('weekly'); // Default to weekly

    const handleTimeFrameChange = (timeFrame: string) => {
        setSelectedTimeFrame(timeFrame);
    };

    return (
        <>
            <div>
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/stock_manager"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>sales report </span>
                    </li>
                </ul>

                <div className="flex flex-col w-[50%] my-5 ">
                    <label htmlFor="time" className="text-lg">
                        {' '}
                        Select Time interval
                    </label>
                    <select
                        className="form-select"
                        value={selectedTimeFrame}
                        onChange={e => handleTimeFrameChange(e.target.value)}
                    >
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                    </select>
                </div>

                {selectedTimeFrame === 'weekly' && <Weekly />}
                {selectedTimeFrame === 'monthly' && <SalesReportMonthly />}
                {selectedTimeFrame === 'yearly' && <SalesReportYearly />}
            </div>
            <div></div>
        </>
    );
}

function Weekly() {
    const isDark = useSelector(
        (state: IRootState) =>
            state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode
    );
    const isRtl = useSelector(
        (state: IRootState) => state.themeConfig.rtlClass === 'rtl'
    );
    const [clientId, setClientId] = useState<string | any>('');
    const { isLoading, data, refetch, isFetching }: any =
        useGetNewSalesReportQuery(clientId ? { clientId } : clientId, {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        });
    const {
        isLoading: loader,
        data: Products,
        refetch2,
        isFetching1,
    }: any = useGetSalesReportQuery(clientId ? { clientId } : clientId, {
        skip: !storage.getToken(),
        refetchOnMountOrArgChange: true,
    });
    const [selectedCategory, setSelectedCategory] = useState<string | any>(
        null
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                await refetch();
            } catch (error) {}
        };

        if (!isFetching) {
            fetchData();
        }
    }, [clientId, refetch]);



    const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
    const [selectedProductData, setSelectedProductData] = useState<Array<any>>(
        Products && Products?.length > 0 ? Products[0]?.data || [] : []
    );

    const handleCategoryClick = (productId: string) => {
        setSelectedProduct(productId);
    };

    useEffect(() => {
        if (!selectedProduct && Products && Products?.length > 0) {
            setSelectedProduct(Products[0]?.product_name || null);
        }

        const filteredData =
            Products?.find(
                (product: { product_name: string | null }) =>
                    product.product_name === selectedProduct
            )?.data || [];

        setSelectedProductData(filteredData);
    }, [selectedProduct, data, Products]);

    const formattedDates = selectedProductData.map((entry: any) => {
        const date = new Date(entry.date);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
        }); // Adjust the locale as needed
    });

    const [allProductData, setAllProductData] = useState<Array<any>>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await refetch();
            } catch (error) {
                console.error(error);
            }
        };

        if (!isFetching) {
            fetchData();
        }
    }, [clientId, refetch]);

    useEffect(() => {
        if (Products && Products.length > 0) {
            const formattedData = Products.map((product: any) => ({
                product_name: product.product_name,
                data: product.data.map((entry: any) => ({
                    date: new Date(entry.date).toISOString().split('T')[0],
                    landing_prices: entry.landing_prices,
                    selling_price: parseInt(entry.selling_price, 10),
                })),
            }));

            setAllProductData(formattedData);
        }
    }, [Products]);

    const chartSeries = allProductData.flatMap((product: any) => [
        {
            name: `${product.product_name} - Revenue`,
            data: product.data.map((entry: any) => ({
                x: entry.date,
                y: entry.selling_price,
            })),
        },
        {
            name: `${product.product_name} - Cost`,
            data: product.data.map((entry: any) => ({
                x: entry.date,
                y: entry.landing_prices,
            })),
        },
    ]);

    const columnChart4: any = {
        series: chartSeries,
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#805dca', '#e7515a'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: '#e0e6ed',
            },
            xaxis: {
                categories: allProductData
                    .flatMap((product: any) =>
                        product.data.map((entry: any) => entry.date)
                    )
                    .filter(
                        (value, index, self) => self.indexOf(value) === index
                    ),
                axisBorder: {
                    color: '#e0e6ed',
                },
            },
            yaxis: {
                labels: {
                    formatter: function (value: number) {
                        // Convert large numbers to K format
                        if (value >= 1000) {
                            return value / 1000 + 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };

    const columnChart3: any = {
        series: [
            {
                name: 'Total Quntitiies',
                data: Array.isArray(data)
                    ? Products?.map((product: any) =>
                          product.data.reduce(
                              (total: number, entry: any) =>
                                  total + entry.total_quantity,
                              0
                          )
                      )
                    : [],
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#805dca', '#e7515a'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            xaxis: {
                categories: Array.isArray(data)
                    ? Products?.map((product: any) => product.product_name)
                    : [],
                axisBorder: {
                    color: isDark ? '#e0e6ed' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                labels: {
                    formatter: function (value: number) {
                        // Convert large numbers to K format
                        if (value >= 1000) {
                            return value / 1000 + 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };



    const filteredData = data?.filter((obj: { client_id: string; }) => obj.client_id); 
    const categories = filteredData?.map((client: any) => client.client_names);
    const quantities = filteredData?.map((client: any) => (client.total_selling_price / 1000).toString() + 'K');
    
    const barChart: any = {
        series: [
            {
                name: 'Amount',
                data: quantities,
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 1,
            },
            colors: ['#4361ee'],
            xaxis: {
                categories: categories,
                axisBorder: {
                    color: isDark ? '#191e3a' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                reversed: isRtl ? true : false,
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            fill: {
                opacity: 0.8,
            },
        },
    };

    const formatValueInK = (value: any) => {
        if (value >= 1000000) {
            return value / 1000000 + 'M';
        } else if (value >= 1000) {
            return value / 1000 + 'K';
        }
        return value;
    };

    return (
        <div>
            {data && data?.length ? (
                <>
                    <div className=" panel">
                        <h1 className="text-3xl font-bold mb-6">
                            Weekly sales report
                        </h1>

                        <div className="flex flex-col lg:flex-row lg:justify-between gap-5">
                            <div className="grid grid-cols-2 gap-8">
                                <div className="card p-6 bg-blue-500 text-white">
                                    <p className="text-4xl font-bold text-center">
                                        {formatValueInK(
                                            data[data?.length - 1]
                                                ?.total_revenue
                                        )}
                                    </p>
                                    <p className="text-md text-center">
                                        Total revenue
                                    </p>
                                </div>

                                <div className="card p-6 mx-5 bg-[#805dca] text-white">
                                    <p className="text-4xl font-bold text-center">
                                        {data[data?.length - 1]?.total_quantity}
                                    </p>
                                    <p className="text-md text-center">
                                        Total Quantity
                                    </p>
                                </div>
                            </div>

                            <div className="lg:w-2/3 flex flex-col justify-end">
                                <p className="text-lg font-bold ">Customers</p>
                                <div className="grid  grid-cols-1 sm:grid-cols-2  md:grid-cols-6 gap-4">
                                    <button
                                        className={`category-item bg-gray-200 p-4 text-center w-full text-gray-800 rounded-md`}
                                        onClick={() => setClientId('')}
                                    >
                                        All
                                    </button>
                                    {data?.slice(0, -1)?.map((client: any) => (
                                        <button
                                            key={client.id}
                                            className={`category-item bg-gray-200 p-4 text-center text-gray-800 rounded-md ${
                                                selectedCategory === client?.id
                                                    ? 'selected'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                setClientId(client?.client_id)
                                            }
                                        >
                                            {client?.client_names}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mx-auto mt-8 p-8 ">
                        <div className="grid grid-cols- gap-2">
                            <div className="panel">
                                <h1 className="text-xl font-bold ">
                                    {' '}
                                    Daily revenue vs cost
                                </h1>
                              

                                <ReactApexChart
                                    series={columnChart4.series}
                                    options={columnChart4.options}
                                    className="rounded-lg bg-white dark:bg-black overflow-hidden"
                                    type="bar"
                                    height={300}
                                />
                            </div>

                            <div className="panel ">
                                <h1 className="text-xl font-bold ">
                                    Revenue contribution by customer
                                </h1>
                                <ReactApexChart
                                    series={barChart.series}
                                    options={barChart.options}
                                    className="rounded-lg bg-white dark:bg-black overflow-hidden"
                                    type="bar"
                                    height={300}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2  mx-auto  px-8 ">
                        <div className="panel">
                            <h1 className="text-xl font-bold ">
                                Total Product Consumption
                            </h1>

                            <ReactApexChart
                                series={columnChart3.series}
                                options={columnChart3.options}
                                className="rounded-lg bg-white dark:bg-black overflow-hidden"
                                type="bar"
                                height={300}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex flex-row justify-center items-start">
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}
