import React, { useEffect, useState, useTransition } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import IconPlus from '@/components/Icon/IconPlus';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { useGetUsersQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { UserModel } from '@/app/Auth/redux/user';
import formatDateToLongForm from '@/utils/DateFormattter';
import Filter from '@/components/filters/Filter';
import { useGetstock_insQuery } from '..';
import { StockModel } from '../API';
import { formatMoney } from '@/utils/Money';




const StockStockIn = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);

    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [role, setRole] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const { isLoading, refetch, isFetching } = useGetstock_insQuery(
        { pageNumber, pageSize, from, to, search, status },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const [sortedData, setSortedData] = useState<any>([]);
    const { stock_in } = useAppSelector((state: any) => state.stockInState);

    useEffect(() => {
        if (stock_in) {
            setSortedData(stock_in?.List);
        }
    }, [stock_in]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, role, refetch]);

    const columns: TableColumn<any>[] = [
        {
            title: 'Quantity',
            accessor: 'quantity',
            render: row => <p className="capitalize">{row.quantity}</p>,
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p className=" text-capitalize">{row.status || '-'}</p>
            ),
        },
        {
            title: 'Product Name',
            accessor: 'product.name',
            render: row => (
                <p className=" text-capitalize">{row?.product?.name}</p>
            ),
        },

        {
            title: 'Product Model',
            accessor: 'product.model',
            render: row => <p>{row?.product?.model}</p>,
        },
      

        {
            title: 'Selling Price',
            accessor: 'product.selling_price',
            render: row => <p>{formatMoney(row?.selling_price) || '-'}</p>,
        },
        {
            title: 'User Name',
            accessor: 'user.name',
            render: row => <p>{row?.user?.name}</p>,
        },

        {
            title: 'User Email',
            accessor: 'user.email',
            render: row => <p>{row?.user?.email}</p>,
        },
        {
            title: 'User phone',
            accessor: 'user.phoneNumber',
            render: row => <p>{row?.user?.phoneNumber}</p>,
        },
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
       
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('stock In'));
    });

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
        } finally {
            setIsLoadingData(false);
        }
    };

    const resetFilters = () => {
        setRole('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

    return (
        <div className="   p-3 ">
            <div className="flex flex-row justify-between items-center my-2 ">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/stock_manager"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>stock In</span>
                    </li>
                </ul>
            </div>

            <ExportDataTable
                columns={columns}
                data={stock_in?.list ?? []}
                tableName="Stock-In"   
                total={stock_in?.total ?? 0}
                currentPage={stock_in?.currentPage ?? 0}
                nextPage={stock_in?.nextPage ?? 0}
                previousPage={stock_in?.previousPage ?? 0}
                lastPage={stock_in?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={  <Filter
                    resetFilters={resetFilters}
                    title="Filter Stock In"
                    children={
                        <>
                            <div className="flex flex-col  ">
                                
                                <div className="mb-4">
                                    <label htmlFor="fromDate">From :</label>
                                    <input
                                        type="date"
                                        id="fromDate"
                                        className="form-input"
                                        onChange={e =>
                                            setStartDateFilter(
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="toDate" className="">
                                        To
                                    </label>
                                    <input
                                        type="date"
                                        id="toDate"
                                        className="form-input "
                                        onChange={e =>
                                            setEndDateFilter(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    }
                />
            }
            />
        </div>
    );
};

export default StockStockIn;