import { useGetLeasesQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Filter from '@/components/filters/Filter';
import { storage } from '@/utils';
import formatDateToLongForm from '@/utils/DateFormattter';
import React, { useEffect, useState } from 'react';

import { useGetlogistic_requestQuery } from '..';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { capitalize } from '@mui/material';
import { useCompleteLogisticMutation } from '../../stock';

function Requests() {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [status, setStatus] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const [sortedData, setSortedData] = useState<any>([]);
    const [loadingRows, setLoadingRows] = useState<any>({});

    const { isLoading, refetch, isFetching, data }: any =
        useGetlogistic_requestQuery(
            { pageNumber, pageSize, from, to, search, status },
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );

    const resetFilters = () => {
        setStatus('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

    useEffect(() => {
        if (data) {
            setSortedData(data?.list);
        }
    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, status, refetch]);

    const columns: TableColumn<any>[] = [
        {
            title: '    Quantity',
            accessor: 'quantity',
            render: row => <p className="capitalize">{row?.quantity}</p>,
        },
        {
            title: 'Reason',
            accessor: 'reason',
            render: row => <p> {capitalize(row?.reason || '')}</p>,
        },
        {
            title: 'Product Name',
            accessor: 'product.name',
            render: row => <p>{row?.product?.name || '-'}</p>,
        },

        {
            title: 'Status',
            accessor: 'status',
            render: row => {
                let badgeColor;
                let badgeText;

                switch (row?.status) {
                    case 'complete':
                        badgeColor = 'bg-primary';
                        badgeText = 'Complete';
                        break;
                    case 'pending':
                        badgeColor = 'bg-warning';
                        badgeText = 'Pending';
                        break;
                    case 'rejected':
                        badgeColor = ' bg-danger ';
                        badgeText = 'Rejected';
                        break;
                    case 'approved':
                        badgeColor = ' bg-success';
                        badgeText = 'Approved';
                        break;
                    case 'partial':
                        badgeColor = ' bg-green-300';
                        badgeText = 'Partial';
                        break;
                        case 'assigned':
                            badgeColor = ' bg-blue-500';
                            badgeText = 'assigned';
                            break;
                    default:
                        badgeColor = 'bg-gray-500';
                        badgeText = 'Unknown';
                }
                return (
                    <div className={`badge ${badgeColor} text-center p-1  `}>
                        {badgeText}
                    </div>
                );
            },
        },
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex items-center justify-center">
                    {row.status === 'approved'  ||row.status === 'assigned' ?  (
                        <button
                            className={`btn ${
                                Complload && loadingRows[row.id]
                                    ? ''
                                    : 'btn-success'
                            } btn-md`}
                            onClick={() => handleCompleteRequests(row.id)}
                        >
                            {Complload && loadingRows[row.id] ? (
                                <span className="">
                                    Receiving...
                                </span>
                            ) : (
                                <span>Receive</span>
                            )}
                        </button>
                    ) : (
                        <p className="text-gray-500 dark:text-gray-300 capitalize">
                            {row.status}
                        </p>
                    )}
                </div>
            ),
        },
    ];

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
        } finally {
            setIsLoadingData(false);
        }
    };

    const [CompleteRequest, { isLoading: Complload }] =
        useCompleteLogisticMutation();
    const handleCompleteRequests = async (id: string) => {
        try {
            setLoadingRows((prevLoadingRows: any) => ({
                ...prevLoadingRows,
                [id]: true,
            }));

            await CompleteRequest(id);
            await handleRefetch();
            setLoadingRows((prevLoadingRows: any) => ({
                ...prevLoadingRows,
                [id]: false,
            }));
        } catch (error) {
            console.error('CompleteRequest Error:', error);

            setLoadingRows((prevLoadingRows: any) => ({
                ...prevLoadingRows,
                [id]: false,
            }));
        }
    };

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Requests'));
    });

    return (
        <>
            <div className="flex flex-row justify-between items-center my-4 ">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/logistic_manager"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span> Requests </span>
                    </li>
                </ul>
            </div>
            <ExportDataTable
                columns={columns}
                data={sortedData ?? []}
                tableName="Requests"   
                total={data?.total ?? 0}
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter "
                        children={
                            <>
                                <div className="flex flex-col  ">
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">
                                            {' '}
                                            Status :
                                        </label>
                                        <select
                                            className="form-select"
                                            name="role"
                                            onChange={e =>
                                                setStatus(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                {' '}
                                                Select status
                                            </option>

                                            <option value="complete">
                                                {' '}
                                                Complete{' '}
                                            </option>
                                            <option value="pending">
                                                {' '}
                                                Pending{' '}
                                            </option>
                                            <option value="rejected">
                                                {' '}
                                                Rejected{' '}
                                            </option>
                                            <option value="approved">
                                                {' '}
                                                Approved{' '}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">From :</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setStartDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="fromDate">To :</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                    setEndDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />
        </>
    );
}

export default Requests;
