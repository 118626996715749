import React, { useEffect, useState, useTransition } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import IconPlus from '@/components/Icon/IconPlus';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom';

import { useGetUsersQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { UserModel } from '@/app/Auth/redux/user';
import formatDateToLongForm from '@/utils/DateFormattter';

import Filter from '@/components/filters/Filter';
import { AddLogisticRequest } from './AddLogisticRequest';
import { useGetcategoryQuery } from '@/app/dashboard/admin/categories';
import { useGetstock_insQuery } from '@/app/dashboard/stock';
import { useGetproductQuery } from '@/app/dashboard/admin/products';
import { formatMoney } from '@/utils/Money';

const LogisticProducts = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [productId, setProducts] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const { data: initialCat }: any = useGetproductQuery(
        { pageNumber:1 },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const Total = initialCat?.total;

    const { isLoading: catLoad, data: categories } = useGetproductQuery(
        { pageNumber:1, pageSize:Total },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );


    const { isLoading, refetch, isFetching } = useGetstock_insQuery(
        { pageNumber, pageSize, from, to, search, productId  },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const resetFilters = () => {
        setProducts('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

    const [sortedData, setSortedData] = useState<any>([]);
    const { stock_in } = useAppSelector((state: any) => state.stockInState);

    useEffect(() => {
        if (stock_in) {
            setSortedData(stock_in?.List);
        }
    }, [stock_in]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, refetch]);

    const columns: TableColumn<any>[] = [
        {
            title: 'Quantity',
            accessor: 'quantity',
            render: row => <p className="capitalize">{row.quantity}</p>,
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p className=" text-capitalize">{row.status || '-'}</p>
            ),
        },
        {
            title: 'Product Name',
            accessor: 'product.name',
            render: row => (
                <p className=" text-capitalize">{row?.product?.name}</p>
            ),
        },
        {
            title: 'Product Count',
            accessor: 'product.count',
            render: row => <p>{formatMoney(row?.product?.count)}</p>,
        },
        {
            title: 'Product Model',
            accessor: 'product.model',
            render: row => <p>{row?.product?.model}</p>,
        },
        {
            title: 'Product Color',
            accessor: 'product.color',
            render: row => <p>{row?.product?.color}</p>,
        },
       
        {
            title: 'Selling Price',
            accessor: 'selling_price',
            render: row => 
         
                <p>{formatMoney(row?.selling_price) || '-'}</p>
          
          
        },

        {
            title: 'Created At',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <p>
                    <AddLogisticRequest data={row} />
                </p>
            ),
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Products'));
    });

    return (
        <div className="   p-3 ">
            <div className="flex flex-row justify-between items-center my-2 ">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/logistic_manager"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>Products In stock </span>
                    </li>
                </ul>
            </div>

            <ExportDataTable
                columns={columns}
                data={stock_in?.list ?? []}
                total={stock_in?.total ?? 0}
                currentPage={stock_in?.currentPage ?? 0}
                nextPage={stock_in?.nextPage ?? 0}
                previousPage={stock_in?.previousPage ?? 0}
                lastPage={stock_in?.lastPage ?? 0}
                isLoading={isLoading}
                tableName="Products"
                filterComponent={
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter Products "
                        children={
                            <>
                                <div className="flex flex-col  ">
                                <div className="my-3">
                                        <label
                                           
                                            className="block text-sm font-medium text-gray-600 dark:text-gray-400"
                                        >
                                            Select Products
                                        </label>
                                        <select
                                            id="category"
                                            className="form-select"
                                          
                                            onChange={e =>
                                                setProducts(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="" disabled>
                                                Select products
                                            </option>
                                            {catLoad ? (
                                                <option value="" disabled>
                                                    Loading products...
                                                </option>
                                            ) : categories?.list.length === 0 ? (
                                                <option value="" disabled>
                                                    No produts found
                                                </option>
                                            ) : (
                                                categories?.list
                                                .filter((item: any) => item.status === 'active')
                                                .map((category: { id: string | any, name: string | any }) => (
                                                    <option
                                                        key={category.id}
                                                        value={category.id}
                                                    >
                                                        {category.name}
                                                    </option>
                                                ))
                                            )}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">From :</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setStartDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="fromDate">To :</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setEndDateFilter(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default LogisticProducts;
