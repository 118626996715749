import toast from 'react-hot-toast';

import baseApi from '@/core/lib/base';
import { ResponseModel } from '@/core/types';
import { LogisticRequest, logistic_requestslice } from './api';

export const logistic_requestApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getlogistic_request: builder.query<
            ResponseModel<LogisticRequest>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                userId?:string;
                status?: string;
                search?: string;
            }
        >({
            query: queryParameters => {
                const { pageNumber, pageSize, from, to, status, search,  userId } =
                    queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/logistic-request',
                    params: {
                        pageNumber,
                        pageSize,
                        from,
                        userId,
                        to,
                        status,
                        search,
                    },
                };
            },
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;

                    dispatch(
                        logistic_requestslice.actions.setlogistic_requests(data)
                    );
                } catch (error) {
                    throw error;
                }
            },
        }),

        AddLogisticRequest: builder.mutation<
            ResponseModel<null>,
            { id: string; data: Partial<any> }
        >({
            query: ({ id, data }): any => ({
                method: 'POST',
                url: '/api/v1/logistic-request',
                body: data,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('logistic-request added successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),
        getMiniStock: builder.query<
        ResponseModel<any>,
        {
            pageNumber?: number;
            pageSize?: number;
            from?: string;
            userId?:string;
            productId?:string;
            to?: string;
            status?: string;
            availability_status?:string;
            search?: string;
            summary?:boolean
        }
    >({
        query: queryParameters => {
            const { pageNumber, pageSize, from, to, search,availability_status,productId,summary } =
                queryParameters;
            return {
                method: 'GET',
                url: '/api/v1/logistic-mini-stock',
                params: {
                    pageNumber,
                    pageSize,
                    availability_status,
                    from,
                    productId,
                    to,
                    summary,
                    
                    search,
                },
            };
        },
        onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
            try {
                const { data }: any = await queryFulfilled;

              
            } catch (error) {
                throw error;
            }
        },
        
    }),
    AddMINIStock: builder.mutation<
    ResponseModel<null>,
    { id: string; data: Partial<any> }
>({
    query: ({ id, data }): any => ({
        method: 'POST',
        url: `/api/v1/logistic-mini-stock/update/${id}`,
        body: data,
    }),
    onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
            const { data }: any = await queryFulfilled;
            toast.success('Serial Number Added ');
        } catch (error: any) {
            toast.error(error?.error?.data?.message);
        }
    },
}),

AssignProducts: builder.mutation<
ResponseModel<null>,
{ id: string; data: Partial<any> }
>({
query: ({ id, data }): any => ({
    method: 'POST',
    url: `/api/v1/technician-request/assign-product/${id}`,
    body: data,
}),
onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
    try {
        const { data }: any = await queryFulfilled;
        toast.success('  Products assigned  ');
    } catch (error: any) {
        toast.error(error?.error?.data?.message);
    }
},
}),


StockAssignProducts: builder.mutation<
ResponseModel<null>,
{ id: string; data: Partial<any> }
>({
query: ({ id, data }): any => ({
    method: 'POST',
    url: `/api/v1/logistic-request/assign/${id}`,
    body: data,
}),
onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
    try {
        const { data }: any = await queryFulfilled;
        toast.success('  Products assigned  ');
    } catch (error: any) {
        toast.error(error?.error?.data?.message);
    }
},
}),

getlogistic_request_serial_number: builder.query<
ResponseModel<LogisticRequest>,
{
    serial_number?: string;
   
}
>({
query: queryParameters => {
    const { serial_number } =
        queryParameters;
    return {
        method: 'GET',
        url: `/api/v1/logistic-mini-stock/find/${serial_number}`,
       
    };
},
onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
    try {
        const { data }: any = await queryFulfilled;

        dispatch(
            logistic_requestslice.actions.setlogistic_requests(data)
        );
    } catch (error) {
        throw error;
    }
},
}),
    }),
});

export const {
    useGetlogistic_request_serial_numberQuery,
    useGetlogistic_requestQuery,
    useAddMINIStockMutation,
    useAddLogisticRequestMutation,
    useGetMiniStockQuery,
    useAssignProductsMutation,
    useStockAssignProductsMutation
} = logistic_requestApi;
