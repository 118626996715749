import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Form } from '@/components/form';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '@/app/Auth/redux';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';

import {
    useAssignProductsMutation,
    useGetMiniStockQuery,
} from '../../logistic';
import { storage } from '@/utils';

const schema = z.object({});

export type stockInput = z.TypeOf<typeof schema>;

const AssignProducts = ({ handleRefetch, data }: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [OnPost, { isLoading }] = useAssignProductsMutation();
    const role = 'logistic_manager';
    const [selectedMinistockIds, setSelectedMinistockIds] = useState<string[]>(
        []
    );
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [productId, setProductId] = useState<any>(data?.product.id);
    const { isLoading: userLoad, data: users }: any = useGetUsersQuery(
        { role },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );
    
   
    const {
        isLoading: LogLoader,
        refetch,
        isFetching,
        data: Ministock,
    }: any = useGetMiniStockQuery(
        {
            productId, 
            pageNumber,
           
        },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

   

    const filteredList = Ministock?.list.filter(
        (item: any) =>
            item.availability_status === 'available' &&
            item?.product?.id === productId &&
            item.serial_number
                ?.toLowerCase()
                .includes(searchQuery?.toLowerCase())
    );
  

    const [remainingItemsList, setRemainingItemsList] = useState<any[]>([]);

    useEffect(() => {
        const modifiedSearchQuery = searchQuery.replace(/^0+/, '');
        setSearchQuery(modifiedSearchQuery);
        setRemainingItemsList(
            filteredList?.filter(
                (item: any) => !selectedMinistockIds.includes(item.id)
            ) || []
        );
    }, [searchQuery, selectedMinistockIds]);

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary "
                >
                    Assign Products
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Assign Products
                                        </div>
                                    </div>
                                    <div className="p-5 ">
                                        <input
                                            type="text"
                                            placeholder="Enter serial number"
                                            value={searchQuery}
                                            onChange={e =>
                                                setSearchQuery(e.target.value)
                                            }
                                            className="form-input"
                                        />
                                        <Form<stockInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (payload: any) => {
                                                payload.logistic_mini_stockIds =
                                                    selectedMinistockIds;

                                                await OnPost({
                                                    id: data.id,
                                                    data: payload,
                                                });
                                                await handleRefetch();
                                                setModal1(false);
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="my-4 text-ms">
                                                        <div className="flex flex-row justify-between items-center">
                                                            <label className="text-sm">
                                                                Select Products:
                                                            </label>
                                                            <div className="flex   justify-between items-center my-2">
                                                                <button
                                                                    type="button"
                                                                    className="btn  rounded-full btn-sm btn-outline-primary mx-2 "
                                                                    disabled={
                                                                        pageNumber ===
                                                                        1
                                                                    }
                                                                    onClick={() =>
                                                                        setPageNumber(
                                                                            prev =>
                                                                                prev -
                                                                                1
                                                                        )
                                                                    }
                                                                >
                                                                    Prev
                                                                </button>

                                                                <button className="btn  rounded-full btn-primary btn-sm">
                                                                    {pageNumber}
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn rounded-full  btn-sm btn-outline-primary mx-2 "
                                                                    onClick={() =>
                                                                        setPageNumber(
                                                                            prev =>
                                                                                prev +
                                                                                1
                                                                        )
                                                                    }
                                                                >
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col min-h-[5vh] max-h-[20vh] overflow-scroll scrollbar-hidden form-input py-2">
                                                            {(() => {
                                                                const renderList =
                                                                    searchQuery
                                                                        ? filteredList?.filter(
                                                                              (
                                                                                  item: any
                                                                              ) =>
                                                                                  item
                                                                                      .product
                                                                                      .id ===
                                                                                      data
                                                                                          ?.product
                                                                                          ?.id &&
                                                                                  item.availability_status ===
                                                                                      'available'
                                                                          )
                                                                        : Ministock?.list?.filter(
                                                                              (
                                                                                  item: any
                                                                              ) =>
                                                                                  item
                                                                                      .product
                                                                                      .id ===
                                                                                      data
                                                                                          ?.product
                                                                                          ?.id &&
                                                                                  item.availability_status ===
                                                                                      'available'
                                                                          );

                                                                return (
                                                                    <div>
                                                                        {renderList &&
                                                                        renderList.length >
                                                                            0 ? (
                                                                            <>
                                                                                {renderList.map(
                                                                                    (
                                                                                        item: any
                                                                                    ) => (
                                                                                        <div
                                                                                            key={
                                                                                                item.id
                                                                                            }
                                                                                            className="grid grid-cols-2 hover:bg-gray-100 hover:dark:bg-slate-800 py-2 px-2 items-center"
                                                                                        >
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                id={`product-${item.id}`}
                                                                                                name={`product-${item.id}`}
                                                                                                checked={selectedMinistockIds.includes(
                                                                                                    item.id
                                                                                                )}
                                                                                                onChange={() => {
                                                                                                    const newSelectedMinistockIds =
                                                                                                        selectedMinistockIds.includes(
                                                                                                            item.id
                                                                                                        )
                                                                                                            ? selectedMinistockIds.filter(
                                                                                                                  id =>
                                                                                                                      id !==
                                                                                                                      item.id
                                                                                                              )
                                                                                                            : [
                                                                                                                  ...selectedMinistockIds,
                                                                                                                  item.id,
                                                                                                              ];

                                                                                                    setSelectedMinistockIds(
                                                                                                        newSelectedMinistockIds
                                                                                                    );
                                                                                                }}
                                                                                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                                                            />
                                                                                            <label
                                                                                                htmlFor={`product-${item.id}`}
                                                                                                className="text-sm text-gray-600 dark:text-gray-400 capitalize"
                                                                                            >
                                                                                                {
                                                                                                    item
                                                                                                        ?.product
                                                                                                        ?.name
                                                                                                }{' '}
                                                                                                (
                                                                                                <span className="text-xs">
                                                                                                    {item?.serial_number ||
                                                                                                        'No serial number '}
                                                                                                </span>

                                                                                                )
                                                                                            </label>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <p className="text-red-400">
                                                                                {searchQuery
                                                                                    ? 'Product with this serial number  not found in your stock'
                                                                                    : 'Product not available in stock'}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })()}
                                                        </div>
                                                    </div>

                                                    <div className="flex justify-end items-center mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger mx-2 "
                                                            onClick={() => {
                                                                setModal1(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Discard
                                                        </button>
                                                        {isLoading ||
                                                        LogLoader ||
                                                        isFetching ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className=" btn btn-primary"
                                                            >
                                                                Assign
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default AssignProducts;