import { Tab } from '@headlessui/react';

import { Link, NavLink, Route, Routes } from 'react-router-dom';

import { Fragment } from 'react';
import Sales from './sales';
import IconShoppingBag from '@/components/Icon/IconShoppingBag';
import IconShare from '@/components/Icon/IconShare';
import Leases from './leasing';

function Reports() {
    const tabs = [
        {
            icon: IconShoppingBag,
            title: 'Sales',
            selected: true,
        },
        {
            icon: IconShare,
            title: ' Leases',
            selected: true,
        },
    ];
    const tabItems = [<Sales />, <Leases />];
    return (
        <div className="text-gray-700 dark:grey-200">
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <Link
                        to="/finance"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                    <span>Reports</span>
                </li>
            </ul>

            <div className="panel mt-5" id="icon">
                <div className="mb-5">
                    <Tab.Group>
                        <Tab.List className="mt-3 mr-3 flex flex-wrap border-b border-white-light dark:border-[#39437b]">
                            {tabs.map((item, index) => (
                                <Tab key={index} as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={`${
                                                selected
                                                    ? '!border-white-light !border-b-white text-primary !outline-none dark:!border-[#39437b] dark:!border-b-black'
                                                    : 'dark:text-gray-400'
                                            }
                              dark:hover:border-b-black' -mb-[1px] flex items-center border border-transparent p-3.5 py-2 hover:text-primary`}
                                        >
                                            <item.icon className="ltr:mr-2 w-5 rtl:ml-2" />
                                            {item.title}
                                        </button>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels>
                            {tabItems.map((item, index) => (
                                <Tab.Panel key={index}>
                                    <div className="">{item}</div>
                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    );
}

export default Reports;
