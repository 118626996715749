import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { Form } from '@/components/form';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import { useUpdateclientMutation } from '..';
import IconEdit from '@/components/Icon/IconEdit';

const schema = z.object({
    name: z.string().min(1, 'Nameis  required'),
    type: z.string().min(1, 'type are  required'),
    office: z.string().min(1, 'office is  required'),
    phoneNumber: z.string().min(1, 'Phone Number  is  required'),
    email: z.string().min(1, 'Email is  required'),
    contract_end_at: z.string(),
    contract_start_at: z.string(),
});

export type RegisterInput = z.TypeOf<typeof schema>;

export const UpdateClient = ({ data, handleRefetch }: any) => {
    const [modal1, setModal1] = useState(false);
    const [contract_end_at, setcontract_end_at] = useState(
        new Date(data.contract_end_at || null)
    );

    const [contract_start_at, setcontract_start_at] = useState(
        new Date(data.contract_start_at || null)
    );
    const navigate = useNavigate();
    const [onLogin, { isSuccess, isLoading }] = useUpdateclientMutation();

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-warning "
                >
                    <IconEdit className="w-4 " />
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Update Client
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<RegisterInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (
                                                payload: RegisterInput
                                            ) => {
                                                const {
                                                    contract_start_at,
                                                    contract_end_at,
                                                    ...restPayload
                                                } = payload;

                                                if (
                                                    contract_start_at &&
                                                    contract_end_at
                                                ) {
                                                    await onLogin({
                                                        id: data.id,
                                                        data: payload,
                                                    });
                                                } else {
                                                    await onLogin({
                                                        id: data.id,
                                                        data: restPayload,
                                                    });
                                                }

                                                await handleRefetch();
                                                setModal1(false);
                                            }}
                                            className="mt-10"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="gap-2 grid grid-cols-2">
                                                        <InputField
                                                            type="text"
                                                            label="Names"
                                                            placeholder="Ex. X campany"
                                                            defaultValue={
                                                                data?.name
                                                            }
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .name
                                                            }
                                                            registration={register(
                                                                'name'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Office"
                                                            placeholder="ex. Nyamirambo"
                                                            loading={isLoading}
                                                            defaultValue={
                                                                data?.office
                                                            }
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .office
                                                            }
                                                            registration={register(
                                                                'office'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Type"
                                                            placeholder="Ex.Private"
                                                            loading={isLoading}
                                                            defaultValue={
                                                                data?.type
                                                            }
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .type
                                                            }
                                                            registration={register(
                                                                'type'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Email"
                                                            placeholder="Email"
                                                            defaultValue={
                                                                data?.email
                                                            }
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .email
                                                            }
                                                            registration={register(
                                                                'email'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Phone Number"
                                                            placeholder="Phone"
                                                            defaultValue={
                                                                data?.phoneNumber
                                                            }
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .phoneNumber
                                                            }
                                                            registration={register(
                                                                'phoneNumber'
                                                            )}
                                                        />
                                                        <InputField
    type="date"
    label="Contract start date"
    placeholder="Contract start date"
    loading={isLoading}
    defaultValue={data?.contract_start_at ? contract_start_at.toISOString().split('T')[0] : undefined}
    onChange={(e: any) => setcontract_start_at(new Date(e.target.value))}
    error={formState.errors.contract_start_at}
    registration={register('contract_start_at')}
    className="h-13"
/>
<InputField
    type="date"
    label="Contract end date"
    placeholder="Contract end date"
    loading={isLoading}
    defaultValue={data?.contract_end_at ? contract_end_at.toISOString().split('T')[0] : undefined}
    onChange={(e: any) => setcontract_end_at(new Date(e.target.value))}
    error={formState.errors.contract_end_at}
    registration={register('contract_end_at')}
    className="h-13"
/>

                                                    </div>
                                                    <div className="flex justify-end items-center mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger mx-2 "
                                                            onClick={() => {
                                                                setModal1(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Discard
                                                        </button>
                                                        {isLoading ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className=" btn btn-primary"
                                                            >
                                                                Update
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
