import React, { useEffect, useState, useTransition } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import IconPlus from '@/components/Icon/IconPlus';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom';

import { useGetUsersQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { UserModel } from '@/app/Auth/redux/user';
import formatDateToLongForm from '@/utils/DateFormattter';

import Filter from '@/components/filters/Filter';
import { useGetbranchQuery } from '..';
import { Branch } from '../API';

import {UpdateBranch} from './UpdateBranch';
import {AddBranch} from './AddBranch';
import { DeleteBranch } from './DeleteBranch';
import Search from '@/components/search';

const Branches = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [role, setRole] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const dispatch = useDispatch();
    const { isLoading, refetch, isFetching } = useGetbranchQuery(
        { pageNumber, pageSize, from, to, search, status },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const resetFilters = () => {
        setRole('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

    const [sortedData, setSortedData] = useState<any>([]);
    const { branches } = useAppSelector((state: any) => state.branchState);
   

    useEffect(() => {
        dispatch(setPageTitle('Branches'));
    });

    useEffect(() => {
        if (branches) {
            setSortedData(branches?.list);
        }
    }, [branches]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, role, refetch]);

    const columns: TableColumn<Branch>[] = [
        {
            title: 'Name',
            accessor: 'name',
            render: row => <p className="capitalize">{row?.name || '-'}</p>,
        },
        {
            title: 'Client',
            accessor: 'phone',
            render: row => <p>{row?.client?.name || '-'}</p>,
        },
        {
            title: 'Location',
            accessor: 'location',
            render: row => <p>{row?.location || '-'}</p>,
        },
        {
            title: 'Person Name',
            accessor: 'person_name',
            render: row => <p>{row?.person_name || '-'}</p>,
        },
        {
            title: 'Email',
            accessor: 'email',
            render: row => <p>{row?.email || '-'}</p>,
        },
        {
            title: 'Phone',
            accessor: 'phone',
            render: row => <p>{row?.phone || '-'}</p>,
        },
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p>
                    <span
                        className={`badge ${
                            row.status === 'active' ? 'bg-success' : 'bg-danger'
                        }`}
                    >
                        {row.status === 'active' ? 'Active' : 'Inactive'}
                    </span>
                </p>
            ),
        },
        {
            title: 'Actions',
            accessor: 'actions',
            textAlign: 'center',
            render: row => (
                <div className="flex gap-2 justify-start">
                    <UpdateBranch data={row} handleRefetch = {handleRefetch} />
                    <DeleteBranch data={row}  handleRefetch = {handleRefetch}/>
                </div>
            ),
        },
    ];

    

    const handleRefetch = async () => {
        try {
          setIsLoadingData(true);
          await refetch();
        } catch (error) {
          
        } finally {
          setIsLoadingData(false);
        }
      };

    return (
        <div className="   p-3 ">
          
            <div className="flex flex-row justify-between items-center my-2 ">
            <ul className="flex space-x-2 rtl:space-x-reverse">
          <li>
              <Link to="/admin" className="text-primary hover:underline">
                  Dashboard
              </Link>
          </li>

          <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
              <span>Branches</span>
          </li>
      </ul>
         
                <AddBranch  handleRefetch = {handleRefetch}/>
            </div>

            <ExportDataTable
                columns={columns}
                data={sortedData ?? []}
                tableName="Branches"   
                total={branches?.total ?? 0}
                currentPage={branches?.currentPage ?? 0}
                nextPage={branches?.nextPage ?? 0}
                previousPage={branches?.previousPage ?? 0}
                lastPage={branches?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter Branches "
                        children={
                            <>
                                <div className="flex flex-col  ">
                                    
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">From :</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setStartDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="toDate" className="">
                                            To
                                        </label>
                                        <input
                                            type="date"
                                            id="toDate"
                                            className="form-input "
                                            onChange={e =>
                                                setEndDateFilter(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default Branches;
