import { IRootState } from '@/store';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { useGetInventoryReportQuery } from '..';
import { storage } from '@/utils';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
export default function InventoryReport() {
    const isDark = useSelector(
        (state: IRootState) =>
            state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode
    );
    const isRtl = useSelector(
        (state: IRootState) => state.themeConfig.rtlClass === 'rtl'
    );
    const [category, setCategory] = useState<string | any>('');

    const { isLoading, data, refetch, isFetching }: any =
        useGetInventoryReportQuery(
          category?  { category }:{},
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );              
    const [selectedCategory, setSelectedCategory] = useState<string | any>(
        null
    );

    const handleCategoryClick = (categoryName: string) => {
        setCategory(categoryName);
        setSelectedCategory(categoryName);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await refetch();
            } catch (error) {}
        };

        if (!isFetching) {
            fetchData();
        }
    }, [category, refetch]);

    const quantityByProduct = data?.list?.map((productData: any) => ({
        name: productData.product_name,
        data: parseInt(productData.total_quantity, 10),
    }));

    const quantityBycategory = data?.category?.map((productData: any) => ({
        name: productData.category_name,
        data: productData.category_total_quantity,
    }));

    const distinctByCategory = data?.category?.map((category: any) => ({
        name: category.category_name,
        data: category.count,
    }));

    const columnChart4: any = {
        series: [
            {
                name: 'Total Quantity',
                data: quantityByProduct?.map((item: any) => item.data),
            },
        ],

        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#805dca'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            xaxis: {
                categories: quantityByProduct?.map((item: any) => item.name),

                axisBorder: {
                    color: isDark ? '#191e3a' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                labels: {
                    formatter: function (value: number) {
                        // Convert large numbers to K format
                        if (value >= 1000) {
                            return (value / 1000)+ 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };

    const columnChart2: any = {
        series: [
            {
                name: 'total Product',
                data: distinctByCategory?.map((item: any) => item.data),
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#4361ee', '#4361ee'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            xaxis: {
                categories: distinctByCategory?.map((item: any) => item.name),
                axisBorder: {
                    color: isDark ? '#191e3a' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                labels: {
                    formatter: function (value: number) {
                        // Convert large numbers to K format
                        if (value >= 1000) {
                            return (value / 1000)+ 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };

    const columnChart3: any = {
        series: [
            {
                name: 'Quantity',
                data: quantityBycategory?.map((item: any) => item.data),
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#e7515a', '#e7515a'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            xaxis: {
                categories: quantityBycategory?.map((item: any) => item.name),
                axisBorder: {
                    color: isDark ? '#e0e6ed' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                labels: {
                    formatter: function (value: number) {
                        // Convert large numbers to K format
                        if (value >= 1000) {
                            return (value / 1000)+ 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };
    const dynamicCategories = [
        { value: null, name: 'All' },
        { value: 'empty', name: 'Empty' },
        { value: 'first_priority', name: 'First Priority' },
        { value: 'second_priority', name: 'Second Priority' },
        { value: 'middle_stock', name: 'Middle Stock' },
        { value: 'abundant', name: 'Abundant' },
    ];

    return (
        <>
            <div>
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/stock_manager"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>Inventory report </span>
                    </li>
                </ul>
            </div>
            {data?.list ? (
                <>
                    <div className="  mx-auto mt-8 p-8 panel">
                        <h1 className="text-3xl font-bold mb-6">
                            INVENTORY REPORT
                        </h1>

                        <div className="flex flex-col lg:flex-row lg:justify-between gap-5">
                            <div className="grid  grid-cols-1 sm:grid-cols-2 gap-8">
                                <div className="card p-6 bg-blue-500 text-white">
                                    <p className="text-4xl font-bold text-center">
                                    {Math.floor(
                                            data?.category.reduce(
                                                (total: number, item: any) =>
                                                    total +
                                                    item.category_total_quantity,
                                                0
                                            )
                                        ) || 0}
                                    </p>
                                    <p className="text-md text-center">
                                        Total Inventory
                                    </p>
                                </div>
                                <div className="card p-6 bg-[#805dca] text-white">
                                    <p className="text-4xl font-bold text-center">
                                       
                                          {data?.category.reduce(
                                            (total: any, item: any) =>
                                                total + item.count,
                                            0
                                        ) || 0}
                                    </p>
                                    <p className="text-md text-center">
                                        Distinct Stock Items
                                    </p>
                                </div>
                            </div>

                            <div className="category-card mt-8">
                                <p className="text-lg font-bold mb-4">
                                    Categories
                                </p>
                                <div className="grid  grid-cols-1 sm:grid-cols-2  md:grid-cols-6 gap-4">
                                    {dynamicCategories.map(
                                        (categoryItem: any, index) => (
                                            <button
                                                key={index}
                                                className={`category-item bg-gray-200 p-4 text-center text-gray-800 rounded-md ${
                                                    selectedCategory ===
                                                    categoryItem.value
                                                        ? 'selected'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    handleCategoryClick(
                                                        categoryItem.value
                                                    )
                                                }
                                            >
                                                {categoryItem.name}
                                            </button>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto mt-8 p-8 bg-white dark:bg-slate-800 rounded-md shadow-lg">
                        <h1 className="text-xl font-bold ">
                            {' '}
                            Total quantity by inventory{' '}
                        </h1>
                        <ReactApexChart
                            series={columnChart4.series}
                            options={columnChart4.options}
                            className="rounded-lg bg-white dark:bg-black overflow-hidden"
                            type="bar"
                            height={300}
                        />
                    </div>
                    <div className="flex flex-col  md:flex-row  gap-3  ">
                        <div className="w-full md:w-1/2 mx-auto mt-8 p-8 bg-white dark:bg-slate-800 rounded-md shadow-lg">
                            <h1 className="text-xl font-bold ">
                                {' '}
                                Total Ddstinct stock by category
                            </h1>
                            <ReactApexChart
                                series={columnChart2.series}
                                options={columnChart2.options}
                                className="rounded-lg bg-white dark:bg-black overflow-hidden"
                                type="bar"
                                height={300}
                            />
                        </div>
                        <div className="w-full md:w-1/2 mx-auto mt-8 p-8 bg-white dark:bg-slate-800 rounded-md shadow-lg">
                            <h1 className="text-xl font-bold ">
                                Total stock under category
                            </h1>

                            <ReactApexChart
                                series={columnChart3.series}
                                options={columnChart3.options}
                                className="rounded-lg bg-white dark:bg-black overflow-hidden"
                                type="bar"
                                height={300}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex flex-row justify-center">
                    <CircularProgress />
                </div>
            )}
        </>
    );
}



