import React, { useEffect, useState, useTransition } from 'react';
import { useAppSelector } from '@/store';
import { storage } from '@/utils';
import IconPlus from '@/components/Icon/IconPlus';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom';

import { useGetUsersQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import { UserModel } from '@/app/Auth/redux/user';
import formatDateToLongForm from '@/utils/DateFormattter';

import Filter from '@/components/filters/Filter';
import { useGetproductQuery } from '..';
import { Product } from '../api';
import { UpdateProduct } from '@/app/dashboard/admin/products/components/UpdateProduct';
import { AddStock } from './AddNewStock';
import { StockHistory } from '../../stock/StockHistory';
import { capitalize } from '@mui/material';
import { formatMoney } from '@/utils/Money';

const StockProducts = () => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [role, setRole] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const { isLoading, refetch, isFetching,data:products }:any = useGetproductQuery(
        { pageNumber, pageSize, from, to, search, status },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );
   
    const resetFilters = () => {
        setRole('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

  
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, refetch, role]);

    const columns: TableColumn<any>[] = [
        {
            title: 'Names',
            accessor: 'name',
            render: row => <p className="capitalize">{row?.name}</p>,
        },
        {
            title: 'Quantity in stock',
            accessor: 'stockIn[0].quantity',
            render: row => (
                <p>
                    {row?.stockIn.map((item: any, index: any) => (
                        <p key={index}>{item?.quantity || '-'}</p>
                    ))}
                </p>
            ),
        },
        {
            title: 'Model',
            accessor: 'model',
            render: row => <p>{row?.model}</p>,
        },

        {
            title: 'Color',
            accessor: 'color',
            render: row => <p className='capitalize'>{row?.color}</p>,
        },
        {
            title: 'Category Name',
            accessor: 'category.name',
            render: row => <p className=' capitalize'>{row?.category?.name || '-'}</p>,
        },
        {
            title: 'Category Description',
            accessor: 'category.description',
            render: row => <p className=' whitespace-pre-wrap'> {capitalize(row?.category?.description || '')}</p>,
        },
        {
            title: 'Selling Price',
            accessor: 'stockIn[0].selling_price',
            render: row => (
                <p>
                    {row?.stockIn.map((item:any, index:any) => (
                        <p key={index}>{formatMoney(item?.selling_price) || '-'}</p>
                    ))}
                </p>
            ),
        },
        

        {
            title: 'Created At',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            textAlign: 'center',
            render: row => (
                <div className="flex gap-2 justify-start">
                    <AddStock data={row} handleRefetch={handleRefetch} />
                 
                </div>
            ),
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Products'));
    });

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
        } finally {
            setIsLoadingData(false);
        }
    };
    return (
        <div className="   p-3 ">
            <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                <li>
                    <Link
                        to="/stock_manager"
                        className="text-primary hover:underline"
                    >
                        Dashboard
                    </Link>
                </li>

                <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                    <span>Products</span>
                </li>
            </ul>
            <ExportDataTable
                columns={columns}
                data={products?.list ?? []}
                total={products?.total ?? 0}
                currentPage={products?.currentPage ?? 0}
                nextPage={products?.nextPage ?? 0}
                previousPage={products?.previousPage ?? 0}
                lastPage={products?.lastPage ?? 0}
                isLoading={isLoading}
                tableName="Products"   
                filterComponent={
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter Products"
                        children={
                            <>
                                <div className="flex flex-col  ">
                                    <div className="mb-4">
                                        <label htmlFor="fromDate">From :</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setStartDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="toDate" className="">
                                            To
                                        </label>
                                        <input
                                            type="date"
                                            id="toDate"
                                            className="form-input "
                                            onChange={e =>
                                                setEndDateFilter(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default StockProducts;
