import { useGetLeasesQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Filter from '@/components/filters/Filter';
import { storage } from '@/utils';
import formatDateToLongForm from '@/utils/DateFormattter';
import React, { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { useGetTechMiniStockQuery } from '..';


function TechProductMini() {
    const {productId}  = useParams()
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [status, setStatus] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const { isLoading, refetch, isFetching, data }: any =
        useGetTechMiniStockQuery(
            { pageNumber, pageSize, from, to, search, status,productId },
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );

    const resetFilters = () => {
        setStatus('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

    

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, status, refetch]);

    const columns: TableColumn<any>[] = [
        {
            title: '    Serial Number',
            accessor: 'logidtic_mini_stock.serial_number',
            render: row => <p className="capitalize">{row?.logistic_mini_stock?.serial_number || '-'}</p>,
        },
        {
            title: 'AvailabilityStatus',
            accessor: 'availability_status',
            render: row => <p className=' capitalize'>{row?.availability_status || '-'} </p>,
        },
        {
            title: 'AvailabilityStatus',
            accessor: 'client_name',
            render: row => <p className=' capitalize'>{row?.client_name || '-'} </p>,
        },
        
        {
            title: 'Product selling Price',
            accessor: 'stockIn.selling_price',
            render: row => <p>{row?.stockIn?.selling_price || '-'}</p>,
        },
      
        
{
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => {
                let badgeColor;
                let badgeText;

                switch (row?.status) {
                    case 'complete':
                        badgeColor = '  bg-primary';
                        badgeText = 'complete';
                        break;
                    case 'pending':
                        badgeColor = '  bg-warning';
                        badgeText = 'Pending';
                        break;
                    case 'rejected':
                        badgeColor = ' bg-danger';
                        badgeText = 'Rejected';
                        break;
                        case 'approved':
                            badgeColor = ' bg-success';
                            badgeText = 'Approved';
                            break;
                    default:
                        badgeColor = 'bg-gray-500';
                        badgeText = 'Unknown';
                }

                return (
                    <div className={`badge ${badgeColor} text-center  text-white rounded`}>
                        {badgeText}
                    </div>
                );
            },
        },
        
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Ministock'));
    });

    const handleRefetch = async () => {
        try {
          setIsLoadingData(true);
          await refetch();
        } catch (error) {
          
        } finally {
          setIsLoadingData(false);
        }
      };

    return (
        <>
            <div className="flex flex-row justify-between items-center my-2 ">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/logistic_manager"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/technician/mini-stock"
                            className="text-primary hover:underline before:content-['/'] ltr:before:mr-2 rtl:before:ml-2"
                        >
                            Mini Stock
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span> Product Detail </span>
                    </li>
                </ul>
{/*               
              < UpdateMiniSTOCK /> */}
            </div>
            <ExportDataTable
                columns={columns}
                data={data?.list ?? []}
                total={data?.total ?? 0}
                tableName="Logistic_Ministock"   
                currentPage={data?.currentPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={<Filter
                    resetFilters={resetFilters}
                    title="Filter your stock  "
                    children={<>
                        <div className="flex flex-col  ">
                            <div className="mb-4">
                                <label htmlFor="fromDate">
                                    {' '}
                                    Status :
                                </label>
                                <select
                                    className="form-select"
                                    name="role"
                                    onChange={e => setStatus(e.target.value)}
                                >
                                    <option value="">
                                        {' '}
                                        Select status
                                    </option>

                                    <option value="complete">
                                        {' '}
                                        Complete{' '}
                                    </option>
                                    <option value="pending">
                                        {' '}
                                        Pending{' '}
                                    </option>
                                    <option value="rejected">
                                        {' '}
                                        rejected{' '}
                                    </option>
                                    <option value="approved">
                                        {' '}
                                        Approved{' '}
                                    </option>

                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="fromDate">From :</label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    className="form-input"
                                    onChange={e => setStartDateFilter(
                                        e.target.value
                                    )} />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="fromDate">From :</label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    className="form-input"
                                    onChange={e => setStartDateFilter(
                                        e.target.value
                                    )} />
                            </div>

                        </div>
                    </>} />} nextPage={0} previousPage={0}            />
        </>
    );
}

export default TechProductMini;
