import { useGetLeasesQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Filter from '@/components/filters/Filter';
import { storage } from '@/utils';
import formatDateToLongForm from '@/utils/DateFormattter';
import React, { useEffect, useState } from 'react';

import { Link, NavLink } from 'react-router-dom';
import { useGetMiniStockQuery } from '../..';
import { UpdateMiniSTOCK } from './UpdateMinistock';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';

function MiniStock() {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [availability_status, setStatus] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
    const summary = true;

    const { isLoading, refetch, isFetching, data }: any = useGetMiniStockQuery(
        { pageNumber, pageSize,availability_status, from, to, search, summary },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const resetFilters = () => {
        setStatus('');

        setStartDateFilter('');
        setEndDateFilter('');
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, status, refetch]);


    const columns: TableColumn<any>[] = [
        {
            title: 'Product Name',
            accessor: 'product.name',
            render: row => (
                <p className=" capitalize">{row?.product?.name || '-'}</p>
            ),
        },
        {
            title: 'Product Model',
            accessor: 'product.model',
            render: row => <p>{row?.product?.model || '-'}</p>,
        },
        {
            title: 'All Quantities',
            accessor: 'quantity',
            render: row => <p>{row?.quantity || '-'}</p>,
        },
        {
            title: 'Available Quantities',
            accessor: 'quantity',
            render: row => <p>{row?.availableQuantity || '-'}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <p>
                    <NavLink
                        to={`/logistic_manager/mini-stock/${row.product.id}`}
                        className="btn btn-primary btn-sm"
                    >
                        View details
                    </NavLink>
                </p>
            ),
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Ministock'));
    });

    const handleRefetch = async () => {
        try {
            setIsLoadingData(true);
            await refetch();
        } catch (error) {
        } finally {
            setIsLoadingData(false);
        }
    };

    return (
        <>
            <div className="flex flex-row justify-between items-center my-2 ">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/logistic_manager"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span> Mini Stock </span>
                    </li>
                </ul>
                {/*               
              < UpdateMiniSTOCK /> */}
            </div>
            <ExportDataTable
                columns={columns}
                data={data?.list ?? []}
                total={data?.total ?? 0}
                tableName="Logistic_Ministock"
                currentPage={data?.currentPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                isLoading={isLoading} filterComponent={
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter Requests "
                        children={
                            <>
                                <div className="flex flex-col  ">
                                   
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                            {' '}
                                            Availability status :
                                        </label>
                                        <select
                                            className="form-select"
                                            name="role"
                                            onChange={e =>
                                                setStatus(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                {' '}
                                                Select status
                                            </option>

                                            <option value="available">
                                                {' '}
                                                Available{' '}
                                            </option>
                                            <option value="technician_owned">
                                                {' '}
                                                Technicain Owned
                                            </option>
                                           
                                        </select>
                                    </div>
                                
                                </div>
                            </>
                        }
                    />
                }               
                
            />
        </>
    );
}

export default MiniStock;
