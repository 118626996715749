import React, { useEffect } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import IconPlus from '@/components/Icon/IconPlus';
import { Form } from '@/components/form';
import { z } from 'zod';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
    useAdminSignMutation,
    useDeleteUserMutation,
    useUpdateUserMutation,
} from '@/app/Auth/redux';
import { useDispatch } from 'react-redux';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import IconPencil from '@/components/Icon/IconPencil';
import IconEdit from '@/components/Icon/IconEdit';
import { toHaveStyle } from '@testing-library/jest-dom/matchers';
import IconTrash from '@/components/Icon/IconTrash';
import { FaReadme } from 'react-icons/fa';
import formatDateToLongForm from '@/utils/DateFormattter';
import { ReactI18NextChild } from 'react-i18next';
import IconChatDots from '@/components/Icon/IconChatDots';
import IconEye from '@/components/Icon/IconEye';


export const More = ({ user }: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [deleteUser, { isSuccess, isLoading }] = useDeleteUserMutation();

 
   
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary"
                >
                    <IconEye className="w-4" />
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full  ">
                                            Client Details
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <div className=" grid grid-cols-2">
                                            <p className='font-bold '>Name:  <span className=' font-normal text-sm' > {user.name}</span></p>
                                            <p className='font-bold '>Type: <span className=' font-normal text-sm'> {user.type}</span></p>
                                            <p className='font-bold '>Contract Start Date: <span className='  font-normal text-sm'> {formatDateToLongForm(user.contract_start_at)}</span> </p>
                                            <p className='font-bold '>Contract End Date: <span className='font-normal text-sm '> {formatDateToLongForm(user.contract_end_at)}</span> </p>
                                            <p className='font-bold '>Phone Number: <span className='font-normal text-sm'> {user.phoneNumber}</span> </p>
                                            <p className='font-bold '>Email:<span className=' font-normal text-sm'> {user.email}</span></p>
                                            <p className='font-bold '>Office: <span className=' font-normal text-sm'> {user.office}</span></p>
                                            <p className='font-bold '>Address: <span className='font-normal text-sm'> {user.address  || "N/A"}</span></p>
                                        </div>
                                        <div className="mt-4">
                                           
                                            {user.branch.map((branch:any,index:any) => (
                                                <div key={branch?.id}>
                                                     <p className=' text-md flex flex-row justify-center'> Branch {index+1}</p>
                                                    <div className='grid grid-cols-2 gap-2 mt-4 '>
                                                    <p className='font-bold '>Name:<span className=' font-normal text-sm' > {branch?.name}</span></p>
                                                    <p className='font-bold '>Location:<span className=' font-normal text-sm' > {branch?.location}</span></p>
                                                    <p className='font-bold '>Person Name: <span className=' font-normal text-sm' > {branch?.person_name}</span></p>
                                                    <p className='font-bold '>Email: <span className=' font-normal text-sm' > {branch?.email}</span></p>
                                                    <p className='font-bold '>Phone:<span className=' font-normal text-sm' > {branch?.phone}</span></p>
                                                    </div>
                                                  
                                                </div>
                                            ))}
                                        </div>
                                        <div className="flex justify-end items-center mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger mx-2 "
                                                onClick={() => {
                                                    setModal1(false);
                                                }}
                                            >
                                                Close
                                            </button>
              
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
