import React, { useEffect } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import IconPlus from '@/components/Icon/IconPlus';
import { Form } from '@/components/form';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { useAdminSignMutation } from '@/app/Auth/redux';
import { useDispatch } from 'react-redux';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import { TextareaField } from '@/components/textArea';
import { useAddProductMutation, useSetPriceMutation } from '..';

const schema = z.object({
    landing_price: z.string().min(1, 'Name are  required'),
    selling_price: z.string().min(1, 'Category is  required'),
});

export type setPriceInput = z.TypeOf<typeof schema>;

export const SetPrices = ({ data, handleRefetch }: any) => {
   
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [OnPost, { isSuccess, isLoading }] = useSetPriceMutation();

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary  btn-sm"
                >
                    {data?.selling_price === null ? (
                        <span> Set Prices </span>
                    ) : (
                        <span> Update Prices </span>
                    )}
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            {data?.selling_price === null ? (
                                                <span> Set Prices </span>
                                            ) : (
                                                <span> Update Prices </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<setPriceInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async payload => {
                                                await OnPost({
                                                    id: data.id,
                                                    data: payload,
                                                });
                                                await handleRefetch();
                                                setModal1(false);
                                            }}
                                            className="mt-5 "
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="">
                                                        <InputField
                                                            type="number"
                                                            label="Landing Price"
                                                            placeholder="Ex. 100,000 RWF"
                                                            loading={isLoading}
                                                            focus
                                                            defaultValue={
                                                                data?.landing_price
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .landing_price
                                                            }
                                                            registration={register(
                                                                'landing_price'
                                                            )}
                                                        />

                                                        <InputField
                                                            type="number"
                                                            label="Selling Price"
                                                            placeholder="Ex.  120,000 RWF"
                                                            loading={isLoading}
                                                            defaultValue={
                                                                data?.selling_price
                                                            }
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .selling_price
                                                            }
                                                            registration={register(
                                                                'selling_price'
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex justify-end items-center mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger mx-2 "
                                                            onClick={() => {
                                                                setModal1(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Discard
                                                        </button>
                                                        {isLoading ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className=" btn btn-primary"
                                                            >
                                                                {data?.selling_price ===
                                                                null ? (
                                                                    <span>
                                                                        {' '}
                                                                        Set Price
                                                                        {' '}
                                                                    </span>
                                                                ) : (
                                                                    <span>
                                                                        {' '}
                                                                        Update Price
                                                                        {' '}
                                                                    </span>
                                                                )}
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
