import React, { useEffect } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import IconPlus from '@/components/Icon/IconPlus';
import { Form } from '@/components/form';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { useAdminSignMutation } from '@/app/Auth/redux';
import { useDispatch } from 'react-redux';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import { useAddOfficeMutation, useUpdateofficeMutation } from '..';
import IconEdit from '@/components/Icon/IconEdit';
import { useGetClientsQuery } from '../../Clients';
import { storage } from '@/utils';
import { useGetbranchQuery } from '../../branch';

const schema = z.object({
    name: z.string().min(1, 'Name is  required'),
    clientId: z.string().min(1, 'client ID are  required'),
    branchId: z.string().min(1, 'Branch ID is  required'),
});

export type RegisterInput = z.TypeOf<typeof schema>;

export const UpdateOffice = ({data,handleRefetch}:any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [OnPost, { isSuccess, isLoading }] = useUpdateofficeMutation();
    const [selectedClientId, setSelectedClientId] = useState<string | null>(
        null
    );
    
    const [sekectedBranchId, setSelectedBranchId] = useState<string | null>(
        null
    );
    const { data: client }: any = useGetClientsQuery(
        { pageNumber: 1 },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );
    const totalClients = client?.total || 0;

    const { isLoading: clientsLoading, data: clients }: any =
        useGetClientsQuery(
            { pageNumber: 1, pageSize: totalClients },
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );

    const { data: branche }: any = useGetbranchQuery(
        { pageNumber: 1 },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );
    const total = branche?.total || 0;

    const { isLoading: branchesLoading, data: branches }: any =
        useGetbranchQuery(
            { pageNumber: 1, pageSize: total },
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );
   
    const filteredBranches: any = branches?.list.filter(
        (branch: { client: any }) => branch?.client?.id === selectedClientId
    );
    const handleClientChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedClientId(event.target.value);
    };

    const handleBranchChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSelectedBranchId(event.target.value);
    };

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-warning"
                >
                    <IconEdit className="w-4" />
                
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Update Office
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<RegisterInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (payload) => {
                                                await OnPost({ id: data.id, data: payload });
                                                await handleRefetch()
                                                setModal1(false)
                                            }}
                                            className="mt-10  "
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="gap-2 grid grid-cols-2">
                                                        <InputField
                                                            type="text"
                                                            label="Names"
                                                            placeholder="Ex. John Doe"
                                                            loading={isLoading}
                                                            defaultValue={data?.name}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .name
                                                            }
                                                            registration={register(
                                                                'name'
                                                            )}
                                                        />
                                                        <div className="">
                                                            <label
                                                                htmlFor="clientId"
                                                                className="block text-sm font-medium text-gray-600 dark:text-gray-400"
                                                            >
                                                                Select Client
                                                            </label>
                                                            <select
                                                                id="clientId"
                                                                className="form-select"
                                                                onChange={e =>
                                                                    setSelectedClientId(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                               
                                                                defaultValue={data?.client?.id}
                                                            >
                                                                <option
                                                                    value=""
                                                                    disabled
                                                                >
                                                                    Select a
                                                                    client
                                                                </option>
                                                                {isLoading ? (
                                                                    <option
                                                                        value=""
                                                                        disabled
                                                                    >
                                                                        Loading
                                                                        clients...
                                                                    </option>
                                                                ) : (
                                                                    clients?.list.map(
                                                                        (client: {
                                                                            id:
                                                                                | string
                                                                                | any;
                                                                            name:
                                                                                | string
                                                                                | any;
                                                                        }) => (
                                                                            <option
                                                                                key={
                                                                                    client.id
                                                                                }
                                                                                value={
                                                                                    client.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    client.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="">
                                                            <label
                                                                htmlFor="branchId"
                                                                className="block text-sm font-medium text-gray-600 dark:text-gray-400"
                                                            >
                                                                Select Branch
                                                            </label>
                                                            <select
                                                                id="branchId"
                                                                className="form-select"
                                                                {...register(
                                                                    'branchId',
                                                                    {
                                                                        required:
                                                                            'Branch ID is required',
                                                                    }
                                                                )}
                                                                defaultValue={data?.branch?.id}
                                                            >

                                                                <option
                                                                    value=""
                                                                    disabled
                                                                >
                                                                    Select a
                                                                    branch
                                                                </option>
                                                                {isLoading ? (
                                                                    <option
                                                                        value=""
                                                                        disabled
                                                                    >
                                                                        Loading
                                                                        branches...
                                                                    </option>
                                                                ) : filteredBranches.length ===
                                                                  0 ? (
                                                                    <option
                                                                        value=""
                                                                        disabled
                                                                    >
                                                                        No
                                                                        branches
                                                                        found
                                                                    </option>
                                                                ) : (
                                                                    filteredBranches.map(
                                                                        (branch: {
                                                                            id:
                                                                                | string
                                                                                | any;
                                                                            name:
                                                                                | string
                                                                                | any;
                                                                        }) => (
                                                                            <option
                                                                                key={
                                                                                    branch.id
                                                                                }
                                                                                value={
                                                                                    branch.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    branch.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )
                                                                )}
                                                            </select>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="flex justify-end items-center mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger mx-2 "
                                                            onClick={() => {
                                                                setModal1(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Discard
                                                        </button>
                                                        {isLoading ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className=" btn btn-primary"
                                                            >
                                                                Update
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
