import { useGetLeasesQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Filter from '@/components/filters/Filter';
import { storage } from '@/utils';
import formatDateToLongForm from '@/utils/DateFormattter';
import React, { useEffect, useState } from 'react';
import { More } from '../../Finance/reports/leasing/More';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';

function TechLeases() {
   
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [role, setRole] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const { isLoading, refetch, isFetching, data }: any = useGetLeasesQuery(
        { pageNumber, pageSize, from, to, search, status },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const resetFilters = () => {
        setRole('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

    const [sortedData, setSortedData] = useState<any>([]);

    useEffect(() => {
        if (data) {
            setSortedData(data?.list);
        }
    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, role, refetch]);

    const columns: TableColumn<any>[] = [
      
       
        {
            title: 'Retun Count',
            accessor: 'returnCount',
            render: row => <p>{row?.returnCount || "-"}</p>,
        },
        {
            title: 'Reason',
            accessor: 'reason',
            render: row => <p>{row?.reason || "-"}</p>,
        },
        {
            title: 'UsedParcentage',
            accessor: 'usedPercentage',
            render: row => <p>{row?.usedPercentage || "-"}</p>,
        },

        {
            title: 'Email',
            accessor: 'user.email',
            render: row => <p>{row?.user?.email}</p>,
        },
        {
            title: 'Phone',
            accessor: 'user.PhoneNumber',
            render: row => <p>{row?.user?.phoneNumber}</p>,
        },
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => <p><More list={row}/></p>,
        },
    ];


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Leases'));
    });

    return (
        <><ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
            <li>
                <Link
                    to="/technician"
                    className="text-primary hover:underline"
                >
                    Dashboard
                </Link>
            </li>

            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                <span> Leases </span>
            </li>
        </ul><ExportDataTable
                columns={columns}
                data={sortedData ?? []}
                tableName="Leases"   
                total={data?.total ?? 0}
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={<Filter
                    resetFilters={resetFilters}
                    title="Filter "
                    children={<>
                        <div className="flex flex-col  ">
                            <div className="mb-4">
                                <label htmlFor="fromDate">From :</label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    className="form-input"
                                    onChange={e => setStartDateFilter(e.target.value)} />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="toDate" className="">
                                    To
                                </label>
                                <input
                                    type="date"
                                    id="toDate"
                                    className="form-input "
                                    onChange={e => setEndDateFilter(e.target.value)} />
                            </div>
                        </div>
                    </>} />} /></>
    );
}

export default TechLeases;
