import toast from "react-hot-toast";
import baseApi from "@/core/lib/base";
import { ResponseModel } from "@/core/types";
import { Product, productslice } from "./api";

export const productApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getproduct: builder.query<ResponseModel<Product>, { pageNumber?: number, pageSize?: number, from?: string, to?: string, status?: string, search?: string }>({
      query: (queryParameters) => {
        const { pageNumber, pageSize, from, to, status,search } = queryParameters;
        return {
          method: "GET",
          url: "/api/v1/product",
          params: {
            pageNumber,
            pageSize,
            from,
            to,
            status,
            search
          },
        };
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
        
          dispatch(productslice.actions.setproducts(data));
        } catch (error) {
            
          throw error; 
        }
      },
    }),

    GetStockHiistory: builder.query<ResponseModel<null>, { pageNumber?: number, pageSize?: number, from?: string, to?: string, stockInId?: string, productId?: string }>({
      query: (queryParameters) => {
        const { pageNumber, pageSize, from, to, stockInId,productId } = queryParameters;
        return {
          method: "GET",
          url: "/api/v1/stock-history",
          params: {
            pageNumber,

            pageSize,
            from,
            to,
            productId,
            stockInId
          },
        };
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data }: any = await queryFulfilled;
        
        
        } catch (error) {
            
          throw error; 
        }
      },
    }),

    AddProduct: builder.mutation<ResponseModel<null>, Product>({
      query: (payload) => ({
        method: "POST",
        url: "/api/v1/product", 
        body: payload,
        
      }),
      
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
        
          const { data }: any = await queryFulfilled;
          toast.success("product added successfully");
         

        } catch (error: any) {
   
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    
    Deleteproduct: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/api/v1/product/${id}`, 
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
    
       
          toast.success('Product status changed ');
      
        } catch (error:any) {
          toast.error(error?.error?.data?.message);
          throw error;
        }
      },
    }),
  }),
});

export const { useGetproductQuery,useAddProductMutation,useDeleteproductMutation, useGetStockHiistoryQuery} = productApi;
