import toast from 'react-hot-toast';

import baseApi from '@/core/lib/base';
import { ResponseModel } from '@/core/types';



export const technician_requestApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getTechMiniStock: builder.query<
        ResponseModel<any>,
        {
            pageNumber?: number;
            pageSize?: number;
            from?: string;
            to?: string;
            status?: string;
            availability_status?:string;
            search?: string;
            summary?:boolean
            productId?:string
        }
    >({
        query: queryParameters => {
            const { pageNumber, pageSize, from, to, status, search,availability_status,summary,productId } =
                queryParameters;
            return {
                method: 'GET',
                url: '/api/v1/technician-mini-stock',
                params: {
                    pageNumber,
                    pageSize,
                    availability_status,
                    from,
                    to,
                    status,
                    search,
                    summary,
                    productId
                },
            };
        },
        onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
            try {
                const { data }: any = await queryFulfilled;

              
            } catch (error) {
                throw error;
            }
        },
    }),
    GetTecchnicia: builder.query<
    ResponseModel<any>,
    {
        pageNumber?: number;
        pageSize?: number;
        from?: string;
        to?: string;
        status?: string;
        availability_status?:string;
        search?: string;
        summary?:boolean

    }
>({
    query: queryParameters => {
        const { pageNumber, pageSize, from, to, status, search,availability_status,summary } =
            queryParameters;
        return {
            method: 'GET',
            url: '/api/v1/technician-mini-stock',
            params: {
                pageNumber,
                pageSize,
                availability_status,
                from,
                to,
                status,
                search,
                summary
            },
        };
    },
    onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
            const { data }: any = await queryFulfilled;

          
        } catch (error) {
            throw error;
        }
    },
}),
AddTechnicianReqs: builder.mutation<
ResponseModel<null>,
{ data: Partial<any> }
>({
query: ({ data }): any => ({
    method: 'POST',
    url: '/api/v1/technician-request',
    body: data,
}),
onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
    try {
        const { data }: any = await queryFulfilled;
        toast.success('request sent  successfully');
    } catch (error: any) {
        toast.error(error?.error?.data?.message);
    }
},
}),
    }),
});

export const {
    useGetTechMiniStockQuery
    ,useAddTechnicianReqsMutation
} = technician_requestApi;
