import * as z from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import svg from '@/assets/3572493-removebg-preview.png'
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { useLoginMutation, useWhoamiQuery } from '../redux';
import Logo from '@/assets/logo/logo_large.png';
import { useAppSelector } from '@/store';

const schema = z.object({
    username: z.string().min(1, 'Username  required'),
    password: z.string().min(1, 'Password required'),
});

export type LoginInput = z.TypeOf<typeof schema>;


export const Login = () => {
    const navigate = useNavigate();
    const [onLogin, { isLoading }] = useLoginMutation();
    const dispatch = useDispatch();

    const { data: whoAmIData, isLoading: whoAmILoading } :any= useWhoamiQuery(null, {
        skip: !storage.getToken(),
        refetchOnMountOrArgChange: true,
    });

    const { user } = useAppSelector((state: any) => state.userState);

    useEffect(() => {
        dispatch(setPageTitle('Login'));
    }, [dispatch]);


    const handleLogin = async (payload: LoginInput) => {
        try {
            await onLogin(payload).unwrap();
            await whoAmILoading();

            if (user && user.role) {
                location.href = `/${user.role}`;
            }
        } catch (error) {
          
            console.error('Login error:', error);
        }
    };


    useEffect(() => {
        
        if (!whoAmILoading && whoAmIData) {
          
            if (user && user.role) {
                navigate(`/${user.role}`);
            }
        }
    }, [whoAmILoading, whoAmIData, user, navigate]);

    
    return (
        <div>
            <div className="relative flex min-h-screen items-center justify-center  px-6 py-10 dark:bg-[#060818] sm:px-16">
                <div className="relative flex w-full max-w-[1502px] flex-col justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[90vh] lg:flex-row lg:gap-10 xl:gap-0">
                    <div className="relative hidden w-full items-center justify-center bg-gradient-to-r from-blue-500 via-gray-300 to-blue-900 p-5 lg:inline-flex lg:max-w-[835px] xl:-ms-28 ">
                        <div className="absolute inset-y-0 w-8 from-primary/10 via-transparent to-transparent ltr:-right-10 ltr:bg-gradient-to-r rtl:-left-10 rtl:bg-gradient-to-l xl:w-16 "></div>
                        <div className="">
                            <Link
                                to="/"
                                className="ms-10 block w-48 text-center lg:w-72"
                            >
                                <div className="text-4xl font-bold text-primary">
                                    <img src={Logo} alt="logo" />
                                </div>
                            </Link>

                            <div className="mt-24 hidden w-full max-w-[430px] lg:block">
                                <img
                                    src={svg}
                                    alt="Cover Image"
                                    className="w-full"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="relative flex w-full flex-col items-center justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
                        <div className="flex w-full max-w-[440px] items-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
                            <Link to="/" className="block lg:hidden">
                            <div className="flex flex-col items-center justify-center">
                                <p className='text-xl font-extrabold text-primary'><span className='text-5xl text-black dark:text-white'>RD</span>TECH</p>
                            </div>
                            </Link>
                        </div>
                        <div className="w-full max-w-[440px] lg:mt-16">
                            <div className="mb-10">
                                <h1 className="text-3xl font-bold uppercase !leading-snug text-primary md:text-4xl">
                                    Sign in
                                </h1>
                                <p className="text-base  leading-normal text-white-dark">
                                    Enter your email and password to login
                                </p>
                            </div>

                            <Form<LoginInput, typeof schema>
                                schema={schema}
                                onSubmit={(payload: any) => handleLogin(payload)}
                                className="mt-10"
                            >
                                {({ register, formState }) => (
                                    <>
                                        <div className="space-y-4">
                                            <InputField
                                                type="text"
                                                label="Username"
                                                placeholder="Username"
                                                loading={isLoading}
                                                focus
                                                error={
                                                    formState.errors.username
                                                }
                                                registration={register(
                                                    'username'
                                                )}
                                                className="h-13"
                                            />

                                            <InputField
                                                type="password"
                                                label="Password"
                                                placeholder="********"
                                                loading={isLoading}
                                                error={
                                                    formState.errors.password
                                                }
                                                registration={register(
                                                    'password'
                                                )}
                                                className="h-13"
                                            />

                                            <div className="flex flex-col  ">
                                                <div className="flex flex-row items-center  justify-between">
                                                    <Link
                                                        to="/reset-password"
                                                        className="text-sm font-medium text-primary"
                                                    >
                                                        Forgot password?
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        {isLoading ? (
                                            <button
                                                type="submit"
                                                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase  text-white"
                                            >
                                                <CircularProgress
                                                    size={24}
                                                    sx={{ color: 'white' }}
                                                />
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                                            >
                                                Log In
                                            </button>
                                        )}
                                    </>
                                )}
                            </Form>

                           
                        </div>
                        <p className="absolute bottom-6 w-full text-center dark:text-white">
                            ©RDTech {new Date().getFullYear()} All Rights
                            Reserved.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
