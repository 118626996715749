
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogisticRequest } from './model';

interface InitialState {
	logistic_requests: LogisticRequest | null;
}

const initialState: InitialState = {
	logistic_requests: null,
};
 
export const logistic_requestslice = createSlice({
	initialState,
	name: 'logistic_request',
	reducers: {
		setlogistic_requests: (state, action: PayloadAction<LogisticRequest>) => {
			state.logistic_requests = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
