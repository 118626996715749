import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Form } from '@/components/form';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '@/app/Auth/redux';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';

import {
    useAssignProductsMutation,
    useGetMiniStockQuery,
    useStockAssignProductsMutation,
} from '../../logistic';
import { storage } from '@/utils';
import { useGetstock_insQuery } from '..';


const schema = z.object({
    quantity: z.string().min(1, 'quantity is  required'),
    stockInId: z.string().min(1, 'Stock In is  required'),
});
export type stockInput = z.TypeOf<typeof schema>;

export const AssignProducts = ({ data, handleRefetch }: any) => {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [OnPost, { isSuccess, isLoading }] = useStockAssignProductsMutation();
    const role = 'logistic_manager';
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [stockInId, setStockId] = useState('');

    const { isLoading: userLoad, data: users }: any = useGetUsersQuery(
        { role },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const { isLoading: totalLoader, data: totalData }: any =
        useGetstock_insQuery(
            { pageNumber: 1 },
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );

    const total = totalData?.total || 0;

    const {
        isLoading: LogLoader,
        refetch,
        isFetching,
        data: Ministock,
    }: any = useGetstock_insQuery(
        {
            pageNumber: 1,
            pageSize: total,
        },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const [searchQuery, setSearchQuery] = useState('');

    const filteredList = Ministock?.list.filter(
        (item: any) => item?.product?.id === data?.product?.id
    );
 

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary "
                >
                    Assign Products
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Assign Products
                                        </div>
                                    </div>
                                    <div className="p-5 ">
                                        <Form<stockInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (payload: any) => {
                                               
                                                await OnPost({
                                                    id: data.id,
                                                    data: payload,
                                                });
                                                await handleRefetch();
                                                setModal1(false);
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                <div className="text-sm mb-2">
                                                                            Requested Quantity <span className='font-bold'> {data.quantity}</span>
                                                                        </div>
                                                    <div className="my-4 text-ms">
                                                        <label className="text-sm">
                                                            Select Products:
                                                        </label>
                                                        <div className="flex flex-col min-h-[5vh] max-h-[20vh] overflow-scroll scrollbar-hidden form-input py-2">
                                                            {filteredList &&
                                                                filteredList.length >
                                                                    0 && (
                                                                    <>
                                                                        {/* Selected Products Section */}
                                                                        
                                                                        {filteredList.map(
                                                                            (
                                                                                item: any
                                                                            ) => (
                                                                                <div
                                                                                    key={
                                                                                        item.id
                                                                                    }
                                                                                    className="grid grid-cols-2 hover:bg-gray-100 hover:dark:bg-slate-800 py-2 px-2 items-center"
                                                                                >
                                                                                    {/* Checkbox and Label */}
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={`product-${item.id}`}
                                                                                       
                                                                                        value={
                                                                                            item.id
                                                                                        }
                                                                                       
                                                                                        {...register('stockInId', {
                                                                                            required: 'stockIn  ID is required',
                                                                                        })}
                                                                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                                                    />
                                                                                    <label
                                                                                        htmlFor={`product-${item.id}`}
                                                                                        className="text-sm text-gray-600 dark:text-gray-400 capitalize"
                                                                                    >
                                                                                        {
                                                                                            item
                                                                                                .product
                                                                                                ?.name
                                                                                        }{' '}
                                                                                        (
                                                                                        <span className="text-xs">
                                                                                            {item
                                                                            
                                                                                                ?.selling_price ||
                                                                                                'Price '}
                                                                                        </span>

                                                                                        )
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <InputField
                                                        type="text"
                                                        label="Quantity"
                                                        placeholder="Quantities"
                                                        loading={isLoading}
                                                        focus
                                                        error={
                                                            formState.errors
                                                                .quantity
                                                        }
                                                        registration={register(
                                                            'quantity'
                                                        )}
                                                    />

                                                    <div className="flex justify-end items-center mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger mx-2 "
                                                            onClick={() => {
                                                                setModal1(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Discard
                                                        </button>
                                                        {isLoading ||
                                                        LogLoader ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className=" btn btn-primary"
                                                            >
                                                                Add
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
