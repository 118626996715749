import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCodeBranch, FaHouseUser, FaLocationArrow, FaProjectDiagram, FaSearchLocation, FaTags, FaUsers } from 'react-icons/fa';
import { DashCard } from '@/components/Dashcard/Index';
import { storage } from '@/utils';
import { useGetLeasesQuery, useGetSalesQuery, useReportsQuery } from '@/app/Auth/redux';
import { useGetMiniStockQuery, useGetlogistic_requestQuery } from '../../logistic';
import IconCashBanknotes from '@/components/Icon/IconCashBanknotes';
import IconBarChart from '@/components/Icon/IconBarChart';
import { IconBase, IconContext } from 'react-icons/lib';
import IconArchive from '@/components/Icon/IconArchive';
import IconCpuBolt from '@/components/Icon/IconCpuBolt';
import IconCode from '@/components/Icon/IconCode';
import IconCreditCard from '@/components/Icon/IconCreditCard';
import IconShoppingCart from '@/components/Icon/IconShoppingCart';
import IconShoppingBag from '@/components/Icon/IconShoppingBag';
import { useGetproductQuery } from '../../admin/products';
import { useGetTechniciainQuery } from '../../stock';

function TechWedgets() {
  const [leaseTotal, setLeaseTotal] = useState(0);
  const [salesTotal, setSalesTotal] = useState(0);
  const [techniTotal, setTechniTotal] = useState(0);
  const [productTotal, setProductTotal] = useState(0);

  const { isLoading: leaseLoader, data: leases }: any = useGetLeasesQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  const { isLoading: salesLoad, data: sales }: any = useGetSalesQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  const { isLoading: logisticLoad, data: techRequests }: any = useGetTechniciainQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  const { isLoading: miniStockLoad, data: miniStock }: any = useGetproductQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    // Calculate totals when data is loaded
    if (!leaseLoader && leases) {
      const totalLease = leases.total || 0;
      setLeaseTotal(totalLease);
    }
  }, [leaseLoader, leases]);

  useEffect(() => {
    // Calculate totals when data is loaded
    if (!salesLoad && sales) {
      const totalSales = sales.total || 0;
      setSalesTotal(totalSales);
    }
  }, [salesLoad, sales]);

  useEffect(() => {
    // Calculate totals when data is loaded
    if (!logisticLoad && techRequests) {
      const totalTechRequests = techRequests.total || 0;
      setTechniTotal(totalTechRequests);
    }
  }, [logisticLoad, techRequests]);

  useEffect(() => {
    // Calculate totals when data is loaded
    if (!miniStockLoad && miniStock) {
      const totalMiniStock = miniStock.total || 0;
      setProductTotal(totalMiniStock);
    }
  }, [miniStockLoad, miniStock]);

  return (
    <><ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
      <li>
        <Link to="/technician" className="text-primary hover:underline">
          Dashboard
        </Link>
      </li>


    </ul><div className='grid grid-cols-1 sm:grid-cols-2 gap-3'>
        <Link to="/technician/products">
          <DashCard
            title="Products"
            value={productTotal}
            icon={<IconArchive className='w-7 h-7 ' />}
            backgroundColor="#FF9800"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/technician/sale">
          <DashCard
            title="Sales"
            value={salesTotal}
            icon={<IconShoppingCart className='w-7 h-7 ' />}
            backgroundColor="#00BFA5"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/technician/lease">
          <DashCard
            title="Leases"
            value={leaseTotal}
            icon={<IconBarChart className='w-7 h-7 ' />}
            backgroundColor="#2196F3"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/technician/requests">
          <DashCard
            title="Requests"
            value={techniTotal}
            icon={<IconCashBanknotes className='w-7 h-7 ' />}
            backgroundColor="#9C27B0"
            iconColor="#FFFFFF" />
        </Link>
      </div></>
  );
}

export default TechWedgets;
