import { useGetLeasesQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Filter from '@/components/filters/Filter';
import { storage } from '@/utils';
import formatDateToLongForm from '@/utils/DateFormattter';
import React, { useEffect, useState } from 'react';

import { Link, NavLink } from 'react-router-dom';
import { useGetTechMiniStockQuery } from '..';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';

function TechMniStock() {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [status, setStatus] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;
const summary = true
    const { isLoading, refetch, isFetching, data }: any =
        useGetTechMiniStockQuery(
            { pageNumber, pageSize, from, to, search, status,summary },
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );

    const resetFilters = () => {
        setStatus('');

        setStartDateFilter('');
        setEndDateFilter('');
    };

    const [sortedData, setSortedData] = useState<any>([]);

    useEffect(() => {
        if (data) {
            setSortedData(data?.list);
        }
    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, status, refetch]);

    const columns: TableColumn<any>[] = [
    
        {
            title: 'Product Name',
            accessor: 'logistic_mini_stock.product.name',
            render: row => (
                <p className=" capitalize">{row?.logistic_mini_stock?.product?.name || '-'}</p>
            ),
        },

      
        {
            title: 'Product Model',
            accessor: 'logistic_mini_stock.product.model',
            render: row => <p>{row?.logistic_mini_stock?.product?.model || '-'}</p>,
        },
       

        {
            title: 'Status',
            accessor: 'status',
            render: row => {
                let badgeColor;
                let badgeText;

                switch (row?.status) {
                    case 'complete':
                        badgeColor = '  bg-success';
                        badgeText = 'complete';
                        break;
                    case 'pending':
                        badgeColor = '  bg-warning';
                        badgeText = 'Pending';
                        break;
                    case 'failed':
                        badgeColor = ' bg-danger';
                        badgeText = 'Failed';
                        break;
                    default:
                        badgeColor = 'bg-gray-500';
                        badgeText = 'Unknown';
                }

                return (
                    <div className={`badge ${badgeColor} text-white text-center rounded`}>
                        {badgeText}
                    </div>
                );
            },
        },
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <p>
                    <NavLink
                        to={`/technician/mini-stock/${row?.product?.id}`}
                        className="btn btn-primary btn-sm"
                    >
                        View details
                    </NavLink>
                </p>
            ),
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Ministock'));
    });

    return (
        <>
            <div className="flex flex-row justify-between items-center my-2 ">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/technician"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span> Mini Stock </span>
                    </li>
                </ul>
                {/* <AddMinistock/> */}
            </div>
            <ExportDataTable
                columns={columns}
                data={data?.list ?? []}
                tableName="Technician_miniStock"   
                total={data?.total ?? 0}
                currentPage={data?.currentPage ?? 0}
                lastPage={data?.lastPage ?? 0}
               
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
               
                isLoading={isLoading}
                filterComponent={<Filter
                    resetFilters={resetFilters}
                    title="Filter "
                    children={<>
                        <div className="flex flex-col  ">
                            <div className="mb-4">
                                <label htmlFor="fromDate">
                                    {' '}
                                    Status :
                                </label>
                                <select
                                    className="form-select"
                                    name="role"
                                    onChange={e => setStatus(e.target.value)}
                                >
                                    <option value="">
                                        {' '}
                                        Select status
                                    </option>

                                    <option value="complete">
                                        {' '}
                                        Complete{' '}
                                    </option>
                                    <option value="pending">
                                        {' '}
                                        Pending{' '}
                                    </option>
                                    <option value="rejected">
                                        {' '}
                                        Rejected{' '}
                                    </option>
                                    <option value="approved">
                                        {' '}
                                        Approved{' '}
                                    </option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="fromDate">From :</label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    className="form-input"
                                    onChange={e => setStartDateFilter(
                                        e.target.value
                                    )} />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="fromDate">To :</label>
                                <input
                                    type="date"
                               
                                    className="form-input"
                                    onChange={e => setEndDateFilter(
                                        e.target.value
                                    )} />
                            </div>

                        </div>
                    </>} />}        />
        </>
    );
}

export default TechMniStock;
