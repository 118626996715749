import { useReportsQuery } from '@/app/Auth/redux';
import { DashCard } from '@/components/Dashcard/Index';
import { storage } from '@/utils';
import React from 'react';
import { FaCodeBranch, FaHouseUser, FaLocationArrow, FaProjectDiagram, FaSearchLocation, FaTags, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Charts from '@/components/charts';
function Widgets() {
  const { isLoading, data }: any = useReportsQuery(null, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
        <li>
          <Link to="/admin" className="text-primary hover:underline">
            Dashboard
          </Link>
        </li>
      </ul>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-3'>
        <Link to="/admin/users">
          <DashCard
            title="Users"
            value={data?.report?.user || 0}
            icon={<FaUsers />}
            backgroundColor="#FF9800"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/admin/branches">
          <DashCard
            title="Branches"
            value={data?.report?.branch  || 0}
            icon={<FaCodeBranch />}
            backgroundColor="#00BFA5"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/admin/offices">
          <DashCard
            title="Offices"
            value={data?.report?.office  || 0}
            icon={<FaSearchLocation />}
            backgroundColor="#2196F3"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/admin/clients">
          <DashCard
            title="Clients"
            value={data?.report?.client   || 0}
            icon={<FaHouseUser />}
            backgroundColor="#9C27B0"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/admin/products">
          <DashCard
            title="Products"
            value={data?.report?.product  || 0}
            icon={<FaProjectDiagram />}
            backgroundColor="#F44336"
            iconColor="#FFFFFF" />
        </Link>

        <Link to="/admin/products/categories">
          <DashCard
            title="Categories"
            value={data?.report?.category  || 0}
            icon={<FaTags />}
            backgroundColor="#4CAF50"
            iconColor="#FFFFFF" />
        </Link>
      </div>
      
    </>
  );
}

export default Widgets;
