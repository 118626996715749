
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Branch } from './model';

interface InitialState {
	branches: Branch | null;
}

const initialState: InitialState = {
	branches: null,
};
export const branchlice = createSlice({
	initialState,
	name: 'client',
	reducers: {
		setBranch: (state, action: PayloadAction<Branch>) => {
			state.branches = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
