import { ResponseModel } from '@/core';
import baseApi from '@/core/lib/base';
import { get } from 'lodash';

export const NotificationApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getNotifications: builder.query<
            ResponseModel<any>,
            {
                pageNumber?: number;
                pageSize?: number;
            }
        >({
            query: queryParameters => {
                const { pageNumber, pageSize } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/notifications',
                    params: {
                        pageNumber,
                        pageSize,
                    },
                };
            },
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    // Handle the data if necessary
                } catch (error) {
                    throw error;
                }
            },
        }),
        getNotificationsCount: builder.query<
            ResponseModel<any>,{}
        >({
            query: () => {
                return {
                    method: 'GET',
                    url: '/api/v1/notifications/count',
                };
            },
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;                } catch (error) {
                    throw error;
                }
            },
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    useGetNotificationsCountQuery,
} = NotificationApi;
