import { useEffect, useState } from 'react';
import {
    useGetUsersQuery,
} from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Filter from '@/components/filters/Filter';
import { storage } from '@/utils';
import formatDateToLongForm from '@/utils/DateFormattter';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { capitalize } from '@mui/material';
import { useGetproductQuery } from '../../admin/products';
import AssignProducts from './AssignProduct';
import {
    useAcceptTechnicianMutation,
    useGetTechniciainQuery,
    useRejectTechnicianMutation,
} from '../../stock';
                     
function TechicianRequestsByLogistic() {
    const [userId, setUserId] = useState('');
    const [productId, setProductId] = useState('');
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [status, setStatus] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const { isLoading, refetch, isFetching, data }: any =
        useGetTechniciainQuery(
            {
                pageNumber,
                pageSize,
                from,
                to,
                search,
                status,
                productId,
                userId,
            },
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );
    const [sortedData, setSortedData] = useState<any>([]);
    const [loadingRows, setLoadingRows] = useState<any>({});

    const resetFilters = () => {
        setStatus('');
        setStartDateFilter('');
        setEndDateFilter('');
    };

    const [AcceptRequests, { isLoading: acceptLoad }] =
        useAcceptTechnicianMutation();
    const [RejectRequests, { isLoading: rejectLoad }] =
        useRejectTechnicianMutation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await refetch();
            } catch (error) {}
        };

        if (!isFetching) {
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, status, refetch]);

    const { data: initaialProd }: any = useGetproductQuery(
        { pageNumber: 1 },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const Total = initaialProd?.total;

    const { isLoading: catLoad, data: Products } = useGetproductQuery(
        { pageNumber: 1, pageSize: Total },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const { data: inistialUsers }: any = useGetUsersQuery(
        { pageNumber: 1, role: 'technician' },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const UserTotal = inistialUsers?.total;

    const { isLoading: userLoad, data: users } = useGetUsersQuery(
        { pageNumber: 1, pageSize: UserTotal, role: 'technician' },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const handleAcceptRequests = async (id: string) => {
        try {
            setLoadingRows((prevLoadingRows: any) => ({
                ...prevLoadingRows,
                [id]: true,
            }));

            await AcceptRequests(id);
            await handleRefetch();
            setLoadingRows((prevLoadingRows: any) => ({
                ...prevLoadingRows,
                [id]: false,
            }));
        } catch (error) {
            console.error('AcceptRequests Error:', error);

            setLoadingRows((prevLoadingRows: any) => ({
                ...prevLoadingRows,
                [id]: false,
            }));
        }
    };

    const handlerejectRequests = async (id: string) => {
        try {
            setLoadingRows((prevLoadingRows: any) => ({
                ...prevLoadingRows,
                [id]: true,
            }));

            await RejectRequests(id);
            await handleRefetch;
            setLoadingRows((prevLoadingRows: any) => ({
                ...prevLoadingRows,
                [id]: false,
            }));
        } catch (error) {
            console.error('RejectRequests Error:', error);

            setLoadingRows((prevLoadingRows: any) => ({
                ...prevLoadingRows,
                [id]: false,
            }));
        }
    };

    const columns: TableColumn<any>[] = [
        {
            title: 'Reason',
            accessor: 'reason',
            render: row => <p> {capitalize(row?.reason || '')}</p>,
        },
        {
            title: 'Quantity',
            accessor: 'quantity',
            render: row => <p>{row?.quantity || '-'}</p>,
        },
        {
            title: 'Product Name',
            accessor: 'product.name',
            render: row => <p>{row?.product?.name || '-'}</p>,
        },

        {
            title: 'User Names',
            accessor: 'user.name',
            render: row => <p>{row?.user?.name || '-'}</p>,
        },
        {
            title: 'User Email',
            accessor: 'user.email',
            render: row => <p>{row?.user?.email || '-'}</p>,
        },
        {
            title: 'User Phone',
            accessor: 'user.phoneNumber',
            render: row => <p>{row?.user?.phoneNumber || '-'}</p>,
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => {
                let badgeColor;
                let badgeText;
                switch (row?.status) {
                    case 'complete':
                        badgeColor = 'bg-primary';
                        badgeText = 'Complete';
                        break;
                    case 'pending':
                        badgeColor = 'bg-warning';
                        badgeText = 'Pending';
                        break;
                    case 'rejected':
                        badgeColor = ' bg-danger ';
                        badgeText = 'Rejected';
                        break;
                    case 'approved':
                        badgeColor = ' bg-success';
                        badgeText = 'Approved';
                        break;
                    case 'partial':
                        badgeColor = ' bg-green-300';
                        badgeText = 'Partial';
                        break;
                    case 'assigned':
                        badgeColor = ' bg-blue-500';
                        badgeText = 'assigned';
                        break;
                    default:
                        badgeColor = 'bg-gray-500';
                        badgeText = 'Unknown';
                }

                return (
                    <div className={`badge ${badgeColor} text-white rounded`}>
                        {badgeText}
                    </div>
                );
            },
        },
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="">
                    {row?.status === 'approved' && (
                        <AssignProducts
                            data={row}
                            handleRefetch={handleRefetch}
                        />
                    )}
                    {row?.status === 'pending' && (
                        <div className="flex flex-row gap-2 ">
                            <button
                                className={`btn ${
                                    acceptLoad && loadingRows[row.id]
                                        ? 'btn-loading'
                                        : 'btn-primary'
                                }`}
                                onClick={() => handleAcceptRequests(row.id)}
                            >
                                {acceptLoad && loadingRows[row.id] ? (
                                    <span> Approving ...</span>
                                ) : (
                                    <span>Approve</span>
                                )}
                            </button>
                            <button
                                className={`btn ${
                                    rejectLoad && loadingRows[row.id]
                                        ? 'btn-loading'
                                        : 'btn-danger'
                                }`}
                                onClick={() => handlerejectRequests(row.id)}
                            >
                                {rejectLoad && loadingRows[row.id] ? (
                                    <span> Rejecting...</span>
                                ) : (
                                    <span>Reject</span>
                                )}
                            </button>
                        </div>
                    )}
                    {row?.status === 'complete' && (
                        <p className="text-gray-500 dark:text-gray-300 capitalize">
                            {row.status}
                        </p>
                    )}
                    {row?.status === 'rejected' && (
                        <p className="text-gray-500 dark:text-gray-300 capitalize">
                            {row.status}
                        </p>
                    )}
                    {row?.status === 'partial' && (
                        <p className="text-gray-500 dark:text-gray-300 capitalize">
                            {row.status}
                        </p>
                    )}
                </div>
            ),
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('technician requests'));
    });

    const handleRefetch = async () => {
        try {
            await refetch();
        } catch (error) {}
    };
    return (
        <>
            <div className="flex flex-row justify-between items-center my-2 ">
                <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
                    <li>
                        <Link
                            to="/logistic_manager"
                            className="text-primary hover:underline"
                        >
                            Dashboard
                        </Link>
                    </li>

                    <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                        <span>Technician Requests </span>
                    </li>
                </ul>
            </div>
            <ExportDataTable
                columns={columns}
                data={data?.list ?? []}
                tableName="Technisian_requests"
                total={data?.total ?? 0}
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter Requests "
                        children={
                            <>
                                <div className="flex flex-col  ">
                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                            Filter by user
                                        </label>
                                        <select
                                            id="products"
                                            className="form-select"
                                            onChange={e =>
                                                setUserId(e.target.value)
                                            }
                                        >
                                            <option value="" disabled>
                                                Select Technician
                                            </option>
                                            {userLoad ? (
                                                <option value="" disabled>
                                                    Loading Technicians...
                                                </option>
                                            ) : users?.list.length === 0 ? (
                                                <option value="" disabled>
                                                    No Technician found
                                                </option>
                                            ) : (
                                                users?.list.map(
                                                    (category: {
                                                        id: string | any;
                                                        name: string | any;
                                                    }) => (
                                                        <option
                                                            key={category.id}
                                                            value={category.id}
                                                        >
                                                            {category.name}
                                                        </option>
                                                    )
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <div className="my-3">
                                        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                            Filter by Product
                                        </label>
                                        <select
                                            id="products"
                                            className="form-select"
                                            onChange={e =>
                                                setProductId(e.target.value)
                                            }
                                        >
                                            <option value="" disabled>
                                                Select Product
                                            </option>
                                            {catLoad ? (
                                                <option value="" disabled>
                                                    Loading Products...
                                                </option>
                                            ) : Products?.list.length === 0 ? (
                                                <option value="" disabled>
                                                    No Products found
                                                </option>
                                            ) : (
                                                Products?.list.map(
                                                    (category: {
                                                        id: string | any;
                                                        name: string | any;
                                                    }) => (
                                                        <option
                                                            key={category.id}
                                                            value={category.id}
                                                        >
                                                            {category.name}
                                                        </option>
                                                    )
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                            {' '}
                                            Status :
                                        </label>
                                        <select
                                            className="form-select"
                                            name="role"
                                            onChange={e =>
                                                setStatus(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                {' '}
                                                Select status
                                            </option>

                                            <option value="complete">
                                                {' '}
                                                Complete{' '}
                                            </option>
                                            <option value="pending">
                                                {' '}
                                                Pending
                                            </option>
                                            <option value="rejected">
                                                {' '}
                                                Rejected{' '}
                                            </option>
                                            <option value="approved">
                                                {' '}
                                                Approved{' '}
                                            </option>
                                            <option value="partial">
                                                {' '}
                                                Partially Approved{' '}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className=" text-sm font-medium text-gray-600 dark:text-gray-400">
                                            From :
                                        </label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setStartDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className=" text-sm font-medium text-gray-600 dark:text-gray-400">
                                            To :
                                        </label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setEndDateFilter(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />
        </>
    );
}

export default TechicianRequestsByLogistic;
