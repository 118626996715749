import baseApi from '@/core/lib/base';
import { userSlice } from './user';
import { UserModel } from './user';
import toast from 'react-hot-toast';
import { ResponseModel } from '@/core/types';
import { storage } from '@/utils';
import { usersSlice } from './user/usersSlice';
import { LoginModel } from './types';
import { LoginInput } from '../login';
import { RegisterInput } from '@/app/dashboard/admin/users/AddUser';
import { ReportModel } from './user/report';
import { ResetInput } from '../reset/RequestOtp';
import { CreatePasswordInput } from '../reset/ResetPassword';

export const authApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation<ResponseModel<LoginModel>, LoginInput>({
            query: payload => ({
                method: 'POST',
                url: '/api/v1/user/login',
                body: payload,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Login successful');
                    dispatch(userSlice.actions.setUser(data));
                    storage.setToken(data.access_token);
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),
        whoami: builder.query<ResponseModel<UserModel>, null>({
            query: () => ({
                method: 'GET',
                url: '/api/v1/user/check',
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    dispatch(userSlice.actions.setUser(data));
                } catch (error) {}
            },
        }),

        reports: builder.query<ResponseModel<ReportModel>, any>({
            query: () => ({
                method: 'GET',
                url: '/api/v1/report/admin',
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {}
            },
        }),

        AdminSign: builder.mutation<ResponseModel<null>, RegisterInput>({
            query: payload => ({
                method: 'POST',
                url: '/api/v1/user',
                body: payload,
            }),

            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('User created successful');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),

        getUsers: builder.query<
            ResponseModel<UserModel>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                search?: string;
                role?: string;
                status?: string;
                occupation?: string;
            }
        >({
            query: queryParameters => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    search,
                    status,
                    role,
                    occupation,
                } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/user',
                    params: {
                        pageNumber,
                        pageSize,
                        from,
                        to,
                        role,
                        status,
                        occupation,
                        search,
                    },
                };
            },
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;

                    dispatch(usersSlice.actions.setUsers(data));
                } catch (error) {
                    throw error;
                }
            },
        }),
        UpdateUser: builder.mutation<
            ResponseModel<null>,
            { id: string; data: Partial<any> }
        >({
            query: ({ id, data }): any => ({
                method: 'PATCH',
                url: `/api/v1/user/${id}`,
                body: data,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;

                    toast.success('User  Updated successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
        DeleteUser: builder.mutation<void, string>({
            query: id => ({
                method: 'DELETE',
                url: `/api/v1/user/${id}`, // Change the endpoint URL
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
        checkToken: builder.mutation<ResponseModel<null>, string>({
            query: token => ({
                method: ' post',
                url: `/api/v1/user/check-token/${token}`,
            }),
            onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;

                    storage.removeToken();
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                    throw error;
                }
            },
        }),
        resetPassword: builder.mutation<ResponseModel<ResetInput>, any>({
            query: payload => ({
                method: 'POST',
                url: '/api/v1/user/password-reset',
                body: payload,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('check your Phone for OTP');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),
        ConfitmResetOtp: builder.mutation<
            ResponseModel<CreatePasswordInput>,
            any
        >({
            query: payload => ({
                method: 'POST',
                url: '/api/v1/user/confirm-password-reset-otp',
                body: payload,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                    toast.success('Password Reset Successfully');
                } catch (error: any) {
                    toast.error(error?.error?.data?.message);
                }
            },
        }),

        getSales: builder.query<
            ResponseModel<UserModel>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                search?: string;
                userId?: string;
                status?: string;
                clientId?: string;
                occupation?: string;
            }
        >({
            query: queryParameters => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    search,
                    status,
                    userId,
                    clientId,
                    occupation,
                } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/sale',
                    params: {
                        pageNumber,
                        pageSize,
                        from,
                        to,
                        userId,
                        status,
                        clientId,
                        occupation,
                        search,
                    },
                };
            },
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),

        GetLeases: builder.query<
            ResponseModel<UserModel>,
            {
                pageNumber?: number;
                pageSize?: number;
                from?: string;
                to?: string;
                search?: string;
                userId?: string;
                clientId?: string;
                status?: string;
                occupation?: string;
            }
        >({
            query: queryParameters => {
                const {
                    pageNumber,
                    pageSize,
                    from,
                    to,
                    search,
                    status,
                    clientId,
                    userId,
                    occupation,
                } = queryParameters;
                return {
                    method: 'GET',
                    url: '/api/v1/leasing',
                    params: {
                        pageNumber,
                        pageSize,
                        from,
                        to,
                        userId,
                        clientId,
                        status,
                        occupation,
                        search,
                    },
                };
            },
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),

        ProductDetail: builder.query<
            ResponseModel<UserModel>,
            { serial_number?: string }
        >({
            query: ({ serial_number }) => ({
                method: 'GET',
                url: `/api/v1/leasing/${serial_number}`,
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                try {
                    const { data }: any = await queryFulfilled;
                } catch (error) {
                    throw error;
                }
            },
        }),
    }),
});

export const {
    useLoginMutation,
    useWhoamiQuery,
    useReportsQuery,
    useGetUsersQuery,
    useDeleteUserMutation,
    useUpdateUserMutation,
    useAdminSignMutation,
    useConfitmResetOtpMutation,
    useCheckTokenMutation,
    useResetPasswordMutation,
    useGetSalesQuery,
    useGetLeasesQuery,
    useProductDetailQuery,
} = authApi;
