import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { storage } from '@/utils';
import { useAppSelector } from '@/store';
import Img from '@/assets/images/background/sweet-bg.jpg';
import { setPageTitle } from '@/store/themeConfigSlice';
import { useDispatch } from 'react-redux';
import { useUpdateUserMutation, useWhoamiQuery } from '@/app/Auth/redux';
import { InputField } from '@/components/input';
import { z } from 'zod';
import { Form } from '@/components/form';

const schema = z.object({
    username: z.string().min(1, 'Username address required'),
    name: z.string().min(1, 'Name address required'),
    email: z.string().min(1, 'Email address required'),
    phoneNumber: z.string().min(1, 'Phone Number is required').max(10, 'Phone Number must be at most 10 characters').min(10,'Phone number are 10 numbers'),
   
});

export type UpdateuserInp = z.TypeOf<typeof schema>;

export const Settings = () => {
    
    

    const { isLoading } = useWhoamiQuery(null, {
        skip: !storage.getToken(),
        refetchOnMountOrArgChange: true,
    });
    const { user: userData }:any = useAppSelector((state) => state.userState);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Profile'));
    });
    const [OnPost, { isSuccess, isLoading:loader }] = useUpdateUserMutation();



    return (
        <div className="">
         <div className="border border-[#ebedf2] dark:border-[#191e3a] rounded-md p-4 mb-5 bg-white dark:bg-black">
                            <h6 className="text-lg font-bold mb-5">Account Informantion</h6>
                            <div className="flex flex-col sm:flex-row">
                                <div className="ltr:sm:mr-4 rtl:sm:ml-4 w-full flex flex-col items-center sm:w-2/12 mb-5">
                                    <img src={Img} alt="img" className="w-20 h-20 md:w-32 md:h-32 rounded-full object-cover mx-auto" />
                                    <p>{userData?.name}</p> 
                                    <span className='  mx-2 badge bg-primary'> {userData?.role}</span>
                                </div>
                                <Form<UpdateuserInp, typeof schema>
                                            schema={schema}
                                            onSubmit={async (payload) => {
                                                await OnPost({ id: userData?.id, data: payload });
                                             
                                            }}
                                            className="mt-10    flex  justify-center  flex-col  w-full"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="gap-2 grid grid-cols-1 md:grid-cols-2">
                                                        <InputField
                                                            type="text"
                                                            label="Names"
                                                            placeholder="Ex. John Doe"
                                                            loading={isLoading}
                                                            defaultValue={userData?.name}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .name
                                                            }
                                                            registration={register(
                                                                'name'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Username"
                                                            placeholder="Username"
                                                            loading={isLoading}
                                                            defaultValue={userData?.username}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .username
                                                            }
                                                            registration={register(
                                                                'username'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Email"
                                                            defaultValue={userData?.email}
                                                            placeholder="Email"
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .email
                                                            }
                                                            registration={register(
                                                                'email'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Phone Number"
                                                            defaultValue={userData?.phoneNumber}
                                                            placeholder="Phone"
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .phoneNumber
                                                            }
                                                            registration={register(
                                                                'phoneNumber'
                                                            )}
                                                            
                                                        />
                                                        

                                                    </div>
                                                    <div className="flex justify-end items-center mt-2">
                                   
                                                        {loader ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className=" btn btn-primary"
                                                            >
                                                                Save
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                            </div>
                        </div>
        </div>
    );
};
