import Login from '@/app/Auth/login';
import FinanceProducts from '@/app/dashboard/Finance/products/components';
import Reports from '@/app/dashboard/Finance/reports/Reports';
import FinanceWidgets from '@/app/dashboard/Finance/widgets';
import Clients from '@/app/dashboard/admin/Clients/clients';
import { Settings } from '@/app/dashboard/admin/Profile';
import Branches from '@/app/dashboard/admin/branch/components';
import Categories from '@/app/dashboard/admin/categories/components';
import Offices from '@/app/dashboard/admin/offices/components';
import Products from '@/app/dashboard/admin/products/components';
import Users from '@/app/dashboard/admin/users';
import Widgets from '@/app/dashboard/admin/widgets';
import Requests from '@/app/dashboard/logistic/Requests';
import MiniStock from '@/app/dashboard/logistic/products/Ministock';
import LogisticProducts from '@/app/dashboard/logistic/products/components';
import LogisticRequests from '@/app/dashboard/stock/requests';
import TechicianRequests from '@/app/dashboard/technician/technisianRequests';
import StockWidgets from '@/app/dashboard/stock/widgets';
import AdminLayout from '@/components/Layouts/Admin/DefaultLayout';
import FinanceLayout from '@/components/Layouts/Finance/DefaultLayout';
import LogisticLayout from '@/components/Layouts/logistic/DefaultLayout';
import ManagerLayout from '@/components/Layouts/manager/DefaultLayout';
import StockLayout from '@/components/Layouts/stock/DefaultLayout';
import { Navigate, useRoutes } from 'react-router-dom';
import TechnicianLayout from '@/components/Layouts/technician/DefaultLayout';
import TechMniStock from '@/app/dashboard/technician/ministock';
import TechLeases from '@/app/dashboard/technician/leases';
import TechSales from '@/app/dashboard/technician/sales';
import TechProducts from '@/app/dashboard/technician/products';
import TechicianRequestsByLogistic from '@/app/dashboard/logistic/technisianRequests';
import TechWedgets from '@/app/dashboard/technician/widgets';
import LogisticWidgets from '@/app/dashboard/logistic/widgets';
import ManagerProducts from '@/app/dashboard/Manager/reports/products';
import ManagerReports from '@/app/dashboard/Manager/reports/Reports';
import ManagerWidgets from '@/app/dashboard/Manager/reports/widgets';
import { RoleProtector } from '@/core/protector';
import Error404 from '@/app/errors/4004Error';
import ProductMini from '@/app/dashboard/logistic/products/Ministock/ProductMini';
import FinanceStockin from '@/app/dashboard/Finance/stockIn';
import ProductDetails from '@/app/dashboard/productDey';
import StockProducts from '@/app/dashboard/stock/products/components';
import Stock from '@/app/dashboard/stock/stock';
import Stockout from '@/app/dashboard/stock/stockout';
import StockStockIn from '@/app/dashboard/stock/stockIn';
import TechProductMini from '@/app/dashboard/technician/ministock/ProductMini';
import RequestOtp from '@/app/Auth/reset/RequestOtp';
import ResetPassword from '@/app/Auth/reset/ResetPassword';
import InventoryReport from '@/app/dashboard/stock/inventory_report';
import SalesReport from '@/app/dashboard/Finance/salesreport';
import LeasesReport from '@/app/dashboard/Finance/leaseReport';
import AssetReports from '@/app/dashboard/Manager/reports/assetsReports';







export default function AppRoutes() {
    return useRoutes([
        { path: '/', element: <Navigate to="/login" replace /> },
        { path: 'login', element: <Login /> },
        { path: 'reset-password', element: <RequestOtp /> },
        { path: 'create-password', element: <ResetPassword /> },
        { path: 'product-info/:serial_number', element: <ProductDetails /> },
        { path: '*', element: <Error404 /> },
        {
            path: '/admin',
            element: <RoleProtector role="admin" element={<AdminLayout />} />,
            children: [
                { path: '/admin', element: <Widgets /> },
                { path: '/admin/product-info/:serial_number', element: <ProductDetails /> },
                { path: '/admin/users', element: <Users /> },
                { path: '/admin/profile', element: <Settings /> },
                { path: '/admin/clients', element: <Clients /> },
                { path: '/admin/branches', element: <Branches /> },
                { path: '/admin/offices', element: <Offices /> },
                { path: '/admin/products', element: <Products /> },
                { path: '/admin/products/categories', element: <Categories /> },
            ],
        },
        {
            path: '/finance',
            element: (
                <RoleProtector role="finance" element={<FinanceLayout />} />
            ),
            children: [
                { path: '/finance', element: <FinanceWidgets /> },
                { path: '/finance/product-info/:serial_number', element: <ProductDetails /> },
                { path: '/finance/profile', element: <Settings /> },
                { path: '/finance/reports', element: <Reports /> },
                { path: '/finance/stock-in', element: <FinanceStockin /> },
                { path: '/finance/sales-report', element: <SalesReport /> },
                { path: '/finance/leases-report', element: <LeasesReport /> },
                { path: '/finance/products', element: <FinanceProducts /> },
            ],
        },
        {
            path: '/management',
            element: (
                <RoleProtector role="management" element={<ManagerLayout />} />
            ),

            children: [
                { path: '/management', element: <ManagerWidgets /> },
                { path: '/management/product-info/:serial_number', element: <ProductDetails /> },
                { path: '/management/reports', element: <ManagerReports /> },
                { path: '/management/products', element: <ManagerProducts /> },
                { path: '/management/assets', element: <AssetReports /> },
                { path: '/management/profile', element: <Settings /> },
            ],
        },
        {
            path: '/stock_manager',
            element: (
                <RoleProtector role="stock_manager" element={<StockLayout />} />
            ),
            children: [
                { path: '/stock_manager', element: <StockWidgets /> },
                { path: '/stock_manager/product-info/:serial_number', element: <ProductDetails /> },
                { path: '/stock_manager/products', element: <StockProducts /> },
                { path: '/stock_manager/stock-in', element: <StockStockIn /> },
                { path: '/stock_manager/stock', element: <Stock/> },
                { path: '/stock_manager/stock-out', element: <Stockout /> },
                { path: '/stock_manager/inventory-report', element: <InventoryReport /> },
                {
                    path: '/stock_manager/logistic-requests',
                    element: <LogisticRequests />,
                },
                { path: '/stock_manager/profile', element: <Settings /> },
            ],
        },
        {
            path: '/logistic_manager',
            element: (
                <RoleProtector
                    role="logistic_manager"
                    element={<LogisticLayout />}
                />
            ),
            children: [
                { path: '/logistic_manager', element: <LogisticWidgets /> },
                {
                    path: '/logistic_manager/products',
                    element: <LogisticProducts />,
                },
                { path: '/logistic_manager/product-info/:serial_number', element: <ProductDetails /> },
                { path: '/logistic_manager/requests', element: <Requests /> },
                {
                    path: '/logistic_manager/techinician-request',
                    element: <TechicianRequestsByLogistic />,
                },
                {
                    path: '/logistic_manager/mini-stock',
                    element: <MiniStock />,
                },
                {
                    path: '/logistic_manager/mini-stock/:productId',
                    element: <ProductMini />,
                },
                { path: '/logistic_manager/profile', element: <Settings /> },
            ],
        },
        {
            path: '/technician',
            element: (
                <RoleProtector
                    role="technician"
                    element={<TechnicianLayout />}
                />
            ),
            children: [
                { path: '/technician', element: <TechWedgets /> },
                { path: '/technician/products', element: <TechProducts /> },
                { path: '/technician/product-info/:serial_number', element: <ProductDetails /> },
                {
                    path: '/technician/mini-stock/:productId',
                    element: <TechProductMini />,
                },
                {
                    path: '/technician/requests',
                    element: <TechicianRequests />,
                },
                { path: '/technician/mini-stock', element: <TechMniStock /> },
                { path: '/technician/profile', element: <Settings /> },
                { path: '/technician/lease', element: <TechLeases /> },
                { path: '/technician/sale', element: <TechSales /> },
            ],
        },
    ]);
}
