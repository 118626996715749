import { useEffect, useState } from 'react';
import { storage } from '@/utils';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
import { useGetSalesQuery, useGetUsersQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import formatDateToLongForm from '@/utils/DateFormattter';
import Filter from '@/components/filters/Filter';
import { More } from '@/app/dashboard/Manager/reports/sales/More';
import { useGetClientsQuery } from '@/app/dashboard/admin/Clients';
import { formatMoney } from '@/utils/Money';

const Sales = () => {
    const [userId, setUserId] = useState('');
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [role, setRole] = useState('');
    const [productId, setProductId] = useState('');
    const [status, setStatus] = useState('');
    const [clientId, setClientId] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const { isLoading, refetch, isFetching, data }: any = useGetSalesQuery(
        { pageNumber, pageSize, from, to, search, status, userId, clientId },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const resetFilters = () => {
        setRole('');
        setUserId('');
        setStatus('');
        setProductId('');
        setClientId('');
        setStartDateFilter('');
        setEndDateFilter('');
    };

    const [sortedData, setSortedData] = useState<any>([]);

    useEffect(() => {
        if (data) {
            setSortedData(data?.list);
        }
    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await refetch();
            } catch (error) {
            } finally {
            }
        };

        if (!isFetching) {
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, role, refetch]);

    const columns: TableColumn<any>[] = [
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Client Name',
            accessor: 'technician_mini_stock.client_name',
            render: row => (
                <p>{row?.technician_mini_stock?.client_name || '-'}</p>
            ),
        },

        {
            title: 'Product Name',
            accessor: 'technician_mini_stock.logistic_mini_stock.product.name',
            render: row => (
                <p>
                    {row?.technician_mini_stock?.logistic_mini_stock?.product
                        .name || '-'}
                </p>
            ),
        },
        {
            title: 'Serial Number ',
            accessor: 'technician_mini_stock.logistic_mini_stock.serial_number',
            render: row => (
                <p>
                    {row?.technician_mini_stock?.logistic_mini_stock
                        ?.serial_number || '-'}
                </p>
            ),
        },

        {
            title: 'Unit Price ',
            accessor:
                'technician_mini_stock.logistic_mini_stock.stockIn.selling_price',
            render: row => (
                <p>
                    {formatMoney(row?.technician_mini_stock?.logistic_mini_stock?.stockIn
                        ?.selling_price) || '-'}
                </p>
            ),
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <p>
                    <More list={row} />{' '}
                </p>
            ),
        },
    ];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle('Users'));
    });

    const { data: inistialUsers }: any = useGetUsersQuery(
        { pageNumber: 1, role: 'technician' },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const UserTotal = inistialUsers?.total;

    const { isLoading: userLoad, data: users } = useGetUsersQuery(
        { pageNumber: 1, pageSize: UserTotal, role: 'technician' },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const { data: initialClients }: any = useGetClientsQuery(
        { pageNumber: 1 },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const ClientToatal = initialClients?.total;

    const { isLoading: ClientLLoad, data: clients } = useGetClientsQuery(
        { pageNumber: 1, pageSize: UserTotal },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );
    return (
        <div className="   p-3 ">
            <ExportDataTable
                columns={columns}
                data={sortedData ?? []}
                total={data?.total ?? 0}
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                tableName="Sales"
                filterComponent={
                    <Filter
                        resetFilters={resetFilters}
                        title="Filter sales"
                        children={
                            <>
                                <div className="flex flex-col  ">
                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                            Filter by Technician
                                        </label>
                                        <select
                                            id="products"
                                            className="form-select"
                                            onChange={e =>
                                                setUserId(e.target.value)
                                            }
                                        >
                                            <option value="" disabled>
                                                Select Technician
                                            </option>
                                            {userLoad ? (
                                                <option value="" disabled>
                                                    Loading Technicians...
                                                </option>
                                            ) : users?.list.length === 0 ? (
                                                <option value="" disabled>
                                                    No Technician found
                                                </option>
                                            ) : (
                                                users?.list.map(
                                                    (category: {
                                                        id: string | any;
                                                        name: string | any;
                                                    }) => (
                                                        <option
                                                            key={category.id}
                                                            value={category.id}
                                                        >
                                                            {category.name}
                                                        </option>
                                                    )
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                            Filter by Client
                                        </label>
                                        <select
                                            id="products"
                                            className="form-select"
                                            onChange={e =>
                                                setClientId(e.target.value)
                                            }
                                        >
                                            <option value="" disabled>
                                                Select Client
                                            </option>
                                            {ClientLLoad ? (
                                                <option value="" disabled>
                                                    Loading clients...
                                                </option>
                                            ) : clients?.list.length === 0 ? (
                                                <option value="" disabled>
                                                    No clients found
                                                </option>
                                            ) : (
                                                clients?.list.map(
                                                    (category: {
                                                        id: string | any;
                                                        name: string | any;
                                                    }) => (
                                                        <option
                                                            key={category.id}
                                                            value={category.id}
                                                        >
                                                            {category.name}
                                                        </option>
                                                    )
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                            {' '}
                                            Status :
                                        </label>
                                        <select
                                            className="form-select"
                                            name="role"
                                            onChange={e =>
                                                setStatus(e.target.value)
                                            }
                                        >
                                            <option value="">
                                                {' '}
                                                Select status
                                            </option>

                                            <option value="complete">
                                                {' '}
                                                Complete{' '}
                                            </option>
                                            <option value="pending">
                                                {' '}
                                                Pending
                                            </option>
                                            <option value="rejected">
                                                {' '}
                                                Rejected{' '}
                                            </option>
                                            <option value="approved">
                                                {' '}
                                                Approved{' '}
                                            </option>
                                            <option value="partial">
                                                {' '}
                                                Partially Approved{' '}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                            From :
                                        </label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-input"
                                            onChange={e =>
                                                setStartDateFilter(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                            To
                                        </label>
                                        <input
                                            type="date"
                                            id="toDate"
                                            className="form-input "
                                            onChange={e =>
                                                setEndDateFilter(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
            />
        </div>
    );
};

export default Sales;
