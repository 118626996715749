import { DashCard } from '@/components/Dashcard/Index'
import IconArchive from '@/components/Icon/IconArchive';
import { storage } from '@/utils';
import { Link } from 'react-router-dom'
import { useGetproductQuery } from '../../admin/products';
import { useGetlogistic_requestQuery } from '../../logistic';
import IconShoppingBag from '@/components/Icon/IconShoppingBag';
import IconCashBanknotes from '@/components/Icon/IconCashBanknotes';
import IconBarChart from '@/components/Icon/IconBarChart';
import { useGetStcokDataQuery, useGetStock_outQuery } from '..';


function StockWidgets() {

  const { isLoading, data: Requests }: any = useGetlogistic_requestQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  const { isLoading: stockoutLoad, data: Stockout }: any = useGetStock_outQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  const { isLoading: StockinLoad, data: Stockin }: any = useGetStcokDataQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  const { isLoading: miniStockLoad, data: Products }: any = useGetproductQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  return (
    <><div>
         <ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
          <li>
              <Link to="/stock_manager" className="text-primary hover:underline">
                  Dashboard
              </Link>
          </li>

      
      </ul>
    </div>
    <div className=' grid  grid-cols-2 sm:grid-cols-2  gap-3 '>
    <Link to="/stock_manager/products">
          <DashCard
            title="Products"
            value={Products?.total}
            icon={<IconArchive />}
            backgroundColor="#00BFA5"
            iconColor="#FFFFFF" /></Link>


        <Link to="/stock_manager/stock-in">
          <DashCard
            title="stock In"
            value={Stockin?.total}
            icon={<IconShoppingBag />}
            backgroundColor="#9C27B0"
            iconColor="#FFFFFF" /></Link>


        <Link to="/stock_manager/stock-out">
          <DashCard
            title="Stock Out"
            value={Stockout?.total}
            icon={<IconBarChart />}
            backgroundColor="#2196F3"
            iconColor="#FFFFFF" />
        </Link>
        <Link to="/stock_manager/logistic-requests">
          <DashCard
            title="Logistic Requests"
            value={Requests?.total}
            icon={<IconCashBanknotes />}
            backgroundColor="#FF9800"
            iconColor="#FFFFFF" /></Link>


      </div></>
  )
}

export default StockWidgets

