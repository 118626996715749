import { IRootState } from '@/store';
import { useEffect, useState } from 'react';

import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { storage } from '@/utils';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import {
    useGetLeasingDataQuery,
    useGetLeasingDataYearlyQuery,
} from '../../stock';
export default function YearlyLeasesReport() {
    const isDark = useSelector(
        (state: IRootState) =>
            state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode
    );
    const isRtl = useSelector(
        (state: IRootState) => state.themeConfig.rtlClass === 'rtl'
    );
    const [clientId, setClientId] = useState<string | any>(undefined);
    const [branchId, setBranchId] = useState<string | any>(undefined);
    const { isLoading, data, refetch, isFetching }: any =
        useGetLeasingDataYearlyQuery(
            {
                clientId: clientId || undefined,
                branchId: branchId || undefined,
            },
            {
                skip: !storage.getToken(),
                refetchOnMountOrArgChange: true,
            }
        );
    const [Products, setProducts] = useState([]);
    const [clients, setclients] = useState([]);
    const [branches, setBranches] = useState([]);
    const [cons, setCons] = useState([]);
    
    useEffect(() => {
        setclients(data?.clients);
        setProducts(data?.data);
        setBranches(data?.branches);
        setCons(data?.daily_consumption);

    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await refetch();
            } catch (error) {}
        };

        if (!isFetching) {
            fetchData();
        }
    }, [clientId, refetch]);

    const barChart2: any = {
        series: [
            {
                name: 'amount',
                data: Array.isArray(Products)
                    ? Products.map((product: any) => product.amount)
                    : [],
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 1,
            },
            colors: ['#4361ee'],
            xaxis: {
                categories: Array.isArray(Products)
                    ? Products?.map((product: any) => product.product_name)
                    : [],

                axisBorder: {
                    color: isDark ? '#191e3a' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                reversed: isRtl ? true : false,
                labels: {
                    offsetX: isRtl ? -10 : 0,
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            fill: {
                opacity: 1,
            },
        },
    };
    const barChart: any = {
        series: [
            {
                name: 'Quantities',
                data: Array.isArray(Products)
                    ? Products.map((product: any) => product.quantity)
                    : [],
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 1,
            },
            colors: ['#4361ee'],
            xaxis: {
                categories: Array.isArray(Products)
                    ? Products.map((product: any) => product.product_name)
                    : [],
                axisBorder: {
                    color: isDark ? '#191e3a' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                reversed: isRtl ? true : false,
               
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            fill: {
                opacity: 0.8,
            },
        },
    };
    
    const columnChart3: any = {
        series: [
            {
                name: 'Total Quntitiies',
                data: Array.isArray(clients)
                    ? clients?.map((client: any) => client?.leasingCount)
                    : [],
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#805dca', '#e7515a'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            xaxis: {
                categories: Array.isArray(clients)
                    ? clients?.map((product: any) => product.name)
                    : [],
                axisBorder: {
                    color: isDark ? '#e0e6ed' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                labels: {
                    formatter: function (value: number) {
                        // Convert large numbers to K format
                        if (value >= 1000) {
                            return (value / 1000)+ 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };

    const columnChart2: any = {
        series: [
            {
                name: 'Total Quntitiies',
                data: Array.isArray(branches)
                    ? branches?.map((branch: any) => branch?.leasingCount)
                    : [],
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#805dca', '#e7515a'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            xaxis: {
                categories: Array.isArray(branches)
                    ? branches?.map((branch: any) => branch.name)
                    : [],
                axisBorder: {
                    color: isDark ? '#e0e6ed' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                labels: {
                    formatter: function (value: number) {
                        // Convert large numbers to K format
                        if (value >= 1000) {
                            return (value / 1000)+ 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };
   
    const columnChart4: any = {
        series: [
            {
                name: 'Total quantity',
                data: Array.isArray(cons)
                    ? cons?.map((branch: any) => branch?.amount)
                    : [],
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#805dca', '#e7515a'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            xaxis: {
                categories: Array.isArray(cons)
                    ? cons?.map((branch: any) => {
                        const date = new Date(branch.date);
                        const month = date.toLocaleDateString('en-US', { month: 'short' });
                        const day = date.getDate();
                        return `${month}-${day}`;
                    })
                    : [],
                axisBorder: {
                    color: isDark ? '#e0e6ed' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                labels: {
                    formatter: function (value: number) {
                        // Convert large numbers to K format
                        if (value >= 1000) {
                            return (value / 1000)+ 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };
  
    const columnChart5: any = {
        series: [
            {
                name: 'Total cost',
                data: Array.isArray(cons)
                    ? Products?.map((branch: any) => branch?.amount)
                    : [],
            },
        ],
        options: {
            chart: {
                height: 300,
                type: 'bar',
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ['#805dca', '#e7515a'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: {
                borderColor: isDark ? '#191e3a' : '#e0e6ed',
            },
            xaxis: {
                categories: Array.isArray(clients)
                    ? Products?.map((product: any) => product.product_name)
                    : [],
                axisBorder: {
                    color: isDark ? '#e0e6ed' : '#e0e6ed',
                },
            },
            yaxis: {
                opposite: isRtl ? true : false,
                labels: {
                    formatter: function (value: number) {
                        // Convert large numbers to K format
                        if (value >= 1000) {
                            return (value / 1000)+ 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };
    const formatValueInK = (value: any) => {
        if (value >= 1000000) {
            return (value / 1000000) + 'M';
        } else if (value >= 1000) {
            return (value / 1000) + 'K';
        }
        return value;
    };
    
    return (
        <>
            
            {data ? (
                <>
                    <div className=" panel">
                        <h1 className="text-3xl font-bold mb-6">
                            Yearly Leases report
                        </h1>

                        <div className="flex flex-col  justify-between gap-5">
                            <div className="grid grid-cols-2 gap-8">
                            <div className="card p-6 bg-blue-500 text-white">
    <p className="text-4xl font-bold text-center">
        {formatValueInK(data?.total?.total_revenue)}
    </p>
    <p className="text-md text-center">
        Total Cost
    </p>
</div>

                                <div className="card   p-6  bg-[#805dca] text-white">
                                    <p className="text-4xl font-bold text-center">
                                      {
                                        data?.total?.total_quantity
                                      }
                                    </p>
                                    <p className="text-md text-center">
                                        Total Quantity
                                    </p>
                                </div>
                            </div>

                            <div className=" flex flex-col justify-end ">
                                <div>
                                    <p className="text-lg font-bold ">
                                        Clients
                                    </p>
                                    <div className="grid  grid-cols-1 sm:grid-cols-2  md:grid-cols-6 gap-4">
                                        <button
                                            className={`category-item bg-gray-200 p-4 text-center w-full text-gray-800 rounded-md`}
                                            onClick={() =>
                                                
                                                setClientId('')}
                                        >
                                            All
                                        </button>
                                        {data?.clients?.map((client: any) => (
                                            <button
                                                key={client.id}
                                                className={`category-item bg-gray-200 p-4 text-center text-gray-800 rounded-md ${
                                                    clientId === client.id ? 'selected' : ''
                                                  }`}
                                                onClick={() =>{
                                                    setBranchId(null)
                                                    setClientId(client.id)}
                                                }
                                            >
                                                {client.name}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                <div className=" ">
                                    <p className="text-lg font-bold ">
                                        Branches
                                    </p>
                                    <div className="grid  grid-cols-1 sm:grid-cols-2  md:grid-cols-6 gap-4">
                                        <button
                                            className={`category-item bg-gray-200 p-4 text-center w-full text-gray-800 rounded-md`}
                                            onClick={() => setBranchId('')}
                                        >
                                            All
                                        </button>
                                        {data?.branches?.map((branch: any) => (
                                            <button
                                                key={branch.id}
                                                className={`category-item bg-gray-200 p-4 text-center text-gray-800 rounded-md ${
                                                    branchId === branch.id ? 'selected' : ''
                                                  }`}
                                                onClick={() =>{
                                                    setClientId(null)
                                                    setBranchId(branch.id)
                                                }
                                                   
                                                }
                                            >
                                                {branch.name}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mx-auto mt-8 p-8 ">
                        <div className="grid grid-cols-2 gap-2">
                        <div className="panel ">
                                <h1 className="text-xl font-bold ">
                                    Daily consumption
                                </h1>
                                <ReactApexChart
                                    series={columnChart4.series}
                                    options={columnChart4.options}
                                    className="rounded-lg bg-white dark:bg-black overflow-hidden"
                                    type="bar"
                                    height={300}
                                />
                            </div>
                            
                            <div className="panel ">
                                <h1 className="text-xl font-bold ">
                                   Weekly item consumption
                                </h1>
                                <ReactApexChart
                               series={columnChart5.series}
                               options={columnChart5.options}
                                    className="rounded-lg bg-white dark:bg-black overflow-hidden"
                                    type="bar"
                                    height={300}
                                />
                            </div>
                            <div className="panel ">
                                <h1 className="text-xl font-bold ">
                                    Leased Items by quantity
                                </h1>
                                <ReactApexChart
                                    series={barChart.series}
                                    options={barChart.options}
                                    className="rounded-lg bg-white dark:bg-black overflow-hidden"
                                    type="bar"
                                    height={300}
                                />
                            </div>
                            {!branchId || !clientId && (
                                <div className="panel ">
                                    <h1 className="text-xl font-bold ">
                                        Leased Items by clients
                                    </h1>
                                    <ReactApexChart
                                        series={columnChart3.series}
                                        options={columnChart3.options}
                                        className="rounded-lg bg-white dark:bg-black overflow-hidden"
                                        type="bar"
                                        height={300}
                                    />
                                </div>
                            )}
                            {
                                branchId && (
                                    <div className="panel ">
                                    <h1 className="text-xl font-bold ">
                                        Leased Items by Branches
                                    </h1>
                                    <ReactApexChart
                                        series={columnChart2.series}
                                        options={columnChart2.options}
                                        className="rounded-lg bg-white dark:bg-black overflow-hidden"
                                        type="bar"
                                        height={300}
                                    />
                                </div>
                                )

                            }
                         
                        </div>
                    </div>
                </>
            ) : (
                <div className="flex flex-row justify-center">
                    <CircularProgress />
                </div>
            )}
        </>
    );
}

// columnChartOptions
