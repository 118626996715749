
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Office } from './model';

interface InitialState {
	offices: Office | null;
}

const initialState: InitialState = {
	offices: null,
};
 
export const officeslice = createSlice({
	initialState,
	name: 'office',
	reducers: {
		setOffices: (state, action: PayloadAction<Office>) => {
			state.offices = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});
