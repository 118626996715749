import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import IconEye from '@/components/Icon/IconEye';
import formatDateToLongForm from '@/utils/DateFormattter';
import { useGetStockHiistoryQuery } from '../products';
import { ReceiptRefundIcon } from '@heroicons/react/24/outline';

export const StockHistory = ({ stockInId, productId }: any) => {
    const [modal1, setModal1] = useState(false);

    const { data: history,isLoading }: any = useGetStockHiistoryQuery({
        pageNumber: 1,
        productId,
    });

if(isLoading){
    return <button className='btn btn-primary btn-sm'>Loading </button>
}
  
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary"
                >
                    <IconEye className="w-4" />
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg  my-8 w-full max-w-2xl max-h-[80vh] scrollbar-hidden overflow-scroll text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3 ">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Stock History
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        {history.list && history.list.length>0  ? (
                                            <div>
                                                {history.list.map(
                                                    (item: any) => (
                                                        <div
                                                            key={item.id}
                                                            className="my-4 grid grid-cols-3 border-b  border-gray-400 dark:border-gray-600 "
                                                        >
                                                            <div className=" my-2 ">
                                                                <p className='font-semibold'>
                                                                    <span className=" text-sm font-regular">
                                                                        Quantity
                                                                        Change:
                                                                    </span >{' '}
                                                                    {
                                                                        item.qty_change
                                                                    }
                                                                </p>
                                                                <p className='font-semibold'>
                                                                <span className=" text-sm font-regular">
                                                                        New
                                                                        Quantity:
                                                                    </span>{' '}
                                                                    {
                                                                        item.new_qty
                                                                    }
                                                                </p>
                                                                <p className='font-semibold'>
                                                                <span className=" text-sm font-regular">
                                                                        Event
                                                                        Date:
                                                                    </span>{' '}
                                                                    {formatDateToLongForm(
                                                                        item.event_date
                                                                    )}
                                                                </p>
                                                                <p className='font-semibold'>
                                                                <span className=" text-sm font-regular">
                                                                        Updated
                                                                        By:
                                                                    </span>{' '}
                                                                    {item.updated_by
                                                                        ? item
                                                                              .updated_by
                                                                              .name
                                                                        : 'N/A'}
                                                                </p>
                                                            </div>

                                                            <div className=" my-2">
                                                                <p className=" text-md font-bold ">
                                                                    {' '}
                                                                User
                                                                </p>
                                                                <p className='font-semibold'>
                                                                <span className=" text-sm font-regular">
                                                                        Name:
                                                                    </span>{' '}
                                                                    {
                                                                        item
                                                                            ?.user
                                                                            ?.name
                                                                    }
                                                                </p>
                                                                <p className='font-semibold'>
                                                                <span className=" text-sm font-regular">
                                                                        User
                                                                        Name
                                                                    </span>{' '}
                                                                    {
                                                                        item
                                                                            ?.user
                                                                            ?.username
                                                                    }
                                                                </p>
                                                                <p className='font-semibold'>
                                                                <span className=" text-sm font-regular">
                                                                        User
                                                                        Phone
                                                                    </span>{' '}
                                                                    {
                                                                        item
                                                                            ?.user
                                                                            ?.phoneNumber
                                                                    }
                                                                </p>
                                                                <p className='font-semibold'>
                                                                <span className=" text-sm font-regular">
                                                                        Email
                                                                    </span>{' '}
                                                                    {
                                                                        item
                                                                            ?.user
                                                                            ?.email
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className=" my-2 ">
                                                                <p className=" text-md font-bold ">
                                                                    Stock In
                                                                </p>

                                                                <p className='font-semibold'>
                                                                <span className=" text-sm font-regular">
                                                                        Stock
                                                                        quantity
                                                                    </span>{' '}
                                                                    {
                                                                        item
                                                                            ?.stockIn
                                                                            ?.quantity
                                                                    }
                                                                </p>
                                                                <p className='font-semibold'>
                                                                <span className=" text-sm font-regular">
                                                                        Stock
                                                                        status
                                                                    </span>{' '}
                                                                    {item
                                                                        ?.stockIn
                                                                        ?.status ||
                                                                        'Unknown'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ):(
                                            <div className='flex flex-row justify-center items-center'>
                                                <p className=' text-md'> No history found </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex justify-end items-center my-2 ">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger mx-2"
                                            onClick={() => {
                                                setModal1(false);
                                            }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
