import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useDeleteBranchMutation } from '..';

export const DeleteBranch = ({ data, handleRefetch }: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [deleteClient, { isSuccess, isLoading }] = useDeleteBranchMutation();

    const handleDelete = () => {
        deleteClient(data.id)
            .then(async () => {
                await handleRefetch();
                setModal1(false);
            })
            .catch((error: any) => {
                toast.error('Operation failed');
            });
    };

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className={`btn ${data.status === 'active' ? 'btn-danger' : 'btn-primary'}`}
                >
                    {' '}
                    {data.status === 'active'
                        ? 'Deactivate '
                        : 'Activate '}
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full  ">
                                            {data.status === 'active'
                                                ? 'Deactivate Branch'
                                                : 'Activate Branch'}
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <div className="">
                                            <p>
                                                Are You sure you want to{' '}
                                                {data.status === 'active'
                                                    ? 'deactivate'
                                                    : 'activate'}{' '}
                                                <span className="font-bold capitalize mx-1">
                                                    {data?.name}
                                                </span>
                                                branch located at{' '}
                                                <span className=" font-bold capitalize">
                                                    {' '}
                                                    {data?.location}{' '}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="flex justify-end items-center mt-2">
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger mx-2 "
                                                onClick={() => {
                                                    setModal1(false);
                                                }}
                                            >
                                                Discard
                                            </button>
                                            {isLoading ? (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    <CircularProgress
                                                        size={24}
                                                        sx={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className=" btn btn-primary"
                                                    onClick={handleDelete}
                                                >
                                                    {data.status === 'active'
                                                        ? 'Deactivate'
                                                        : 'Activate'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
