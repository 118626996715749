import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCodeBranch, FaHouseUser, FaLocationArrow, FaProjectDiagram, FaSearchLocation, FaTags, FaUsers } from 'react-icons/fa';
import { DashCard } from '@/components/Dashcard/Index';
import { storage } from '@/utils';
import { useGetLeasesQuery, useGetSalesQuery, useReportsQuery } from '@/app/Auth/redux';
import { useGetMiniStockQuery, useGetlogistic_requestQuery } from '../../logistic';

import IconCashBanknotes from '@/components/Icon/IconCashBanknotes';
import IconBarChart from '@/components/Icon/IconBarChart';
import { IconBase, IconContext } from 'react-icons/lib';
import IconArchive from '@/components/Icon/IconArchive';
import IconCpuBolt from '@/components/Icon/IconCpuBolt';
import IconCode from '@/components/Icon/IconCode';
import IconCreditCard from '@/components/Icon/IconCreditCard';
import IconShoppingCart from '@/components/Icon/IconShoppingCart';
import IconShoppingBag from '@/components/Icon/IconShoppingBag';
import { useGetproductQuery } from '../../admin/products';
import { useGetTechniciainQuery } from '../../stock';

function LogisticWidgets() {
  const [leaseTotal, setLeaseTotal] = useState(0);

  const [techniTotal, setTechniTotal] = useState(0);
  const [productTotal, setProductTotal] = useState(0);
  const [miniStockTotal, setMinistockTotal] = useState(0);
  const [logisticTotal, setLogisticTotal] = useState(0);
  

  const { isLoading: techLoad, data: techRequests }: any = useGetTechniciainQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });
  const { isLoading: logisticLoad, data: Logistics }: any = useGetlogistic_requestQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });

  const { isLoading: miniStockLoad, data: miniStock }: any = useGetMiniStockQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });
  
  const { isLoading: ProductLoad, data: products }: any = useGetproductQuery({ pageNumber: 1 }, {
    skip: !storage.getToken(),
    refetchOnMountOrArgChange: true,
  });




  useEffect(() => {
    // Calculate totals when data is loaded
    if (!techLoad && techRequests) {
      const totalTechRequests = techRequests.total || 0;
      setTechniTotal(totalTechRequests);
    }
  }, [logisticLoad, techRequests]);

  useEffect(() => {
  
    if (!logisticLoad && Logistics) {
      const logicTotal = Logistics.total || 0;
      setLogisticTotal(logicTotal);
    }
  }, [logisticLoad, Logistics]);
  useEffect(() => {
    // Calculate totals when data is loaded
    if (!miniStockLoad && miniStock) {
      const totalMiniStock = miniStock.total || 0;
      setMinistockTotal(totalMiniStock);
    }
    
  }, [miniStockLoad, miniStock]);

  useEffect(() => {
    // Calculate totals when data is loaded
    if (!ProductLoad && products) {
      const TotalProducts = products.total || 0;
      setProductTotal(TotalProducts);
    }
    
  }, [ProductLoad, products]);
  return (
    <div className='grid grid-cols-2 sm:grid-cols-2 gap-3'>
      <Link to="/logistic_manager/products">
        <DashCard
          title="Products"
          value={productTotal}
          icon={<IconArchive  className='w-7 h-7 '/>}
          backgroundColor="#FF9800"
          iconColor="#FFFFFF"
        />
      </Link>

     

      <Link to="/logistic_manager/mini-stock">
        <DashCard
          title="Mini stock"
          value={miniStockTotal}
          icon={<IconBarChart className='w-7 h-7 ' />}
          backgroundColor="#2196F3"
          iconColor="#FFFFFF"
        />
      </Link>
      <Link to="/logistic_manager/requests">
        <DashCard
          title="My Requests"
          value={logisticTotal}
          icon={<IconCashBanknotes className='w-7 h-7 ' />}
          backgroundColor="#9C27B0"
          iconColor="#FFFFFF"
        />
      </Link>
      <Link to="/logistic_manager/techinician-request">
        <DashCard
          title="Technician Requests"
          value={techniTotal}
          icon={<IconCashBanknotes className='w-7 h-7 ' />}
          backgroundColor="#9C27B0"
          iconColor="#FFFFFF"
        />
      </Link>
    </div>
  );
}

export default LogisticWidgets;
