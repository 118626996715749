import { useGetLeasesQuery, useGetUsersQuery } from '@/app/Auth/redux';
import ExportDataTable, { TableColumn } from '@/components/datatable';
import Filter from '@/components/filters/Filter';
import { storage } from '@/utils';
import formatDateToLongForm from '@/utils/DateFormattter';
import React, { useEffect, useState } from 'react';

import { useGetClientsQuery } from '@/app/dashboard/admin/Clients';
import { Link } from 'react-router-dom';

function AssetsReports() {
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [userId, setUserId] = useState('');
    const [from, setStartDateFilter] = useState('');
    const [to, setEndDateFilter] = useState('');
    const [role, setRole] = useState('');
    const [clientId, setClientId] = useState('');
    const [status, setStatus] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const search = queryParams.get('search') || '';
    const pageNumber = Number(queryParams.get('pageNumber')) || 1;
    const pageSize = Number(queryParams.get('pageSize')) || 10;

    const { isLoading, refetch, isFetching, data }: any = useGetLeasesQuery(
        { pageNumber, pageSize, from, to, search, status,userId, clientId },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const resetFilters = () => {
        setRole('');
        setUserId('');
        setStatus('');
        setClientId('');
        setUserId('')
        setStartDateFilter('');
        setEndDateFilter('');
    };

    const [sortedData, setSortedData] = useState<any>([]);

    useEffect(() => {
        if (data) {
            setSortedData(data?.list);
        }
    }, [data]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoadingData(true);
                await refetch();
            } catch (error) {
            } finally {
                setIsLoadingData(false);
            }
        };

        if (!isFetching) {
            setIsLoadingData(true);
            fetchData();
        }
    }, [pageNumber, pageSize, from, to, search, role, refetch]);

    const columns: TableColumn<any>[] = [
        {
            title: 'Client',
            accessor: 'client.name',
            render: row => <p>{row?.client?.name || '-'}</p>,
        },
        {
            title: 'Branch',
            accessor: 'branch.name',
            render: row => <p>{row?.branch?.name || '-'}</p>,
        },
       
        {
            title: 'Product Name',
            accessor: 'technician_mini_stock.logistic_mini_stock.product.name',
            render: row => (
                <p>
                    {row?.technician_mini_stock?.logistic_mini_stock?.product
                        .name || '-'}
                </p>
            ),
        },
        {
            title: 'Product Color',
            accessor: 'technician_mini_stock.logistic_mini_stock.product.model',
            render: row => (
                <p>
                    {row?.technician_mini_stock?.logistic_mini_stock?.product
                        .color || '-'}
                </p>
            ),
        },
        

        {
            title: 'Serial Number ',
            accessor: 'technician_mini_stock.logistic_mini_stock.serial_number',
            render: row => (
                <p>
                    {row?.technician_mini_stock?.logistic_mini_stock
                        ?.serial_number || '-'}
                </p>
            ),
        },

     

      
        {
            title: 'Date delivered',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row.created_at)}</p>,
        },
        {
            title: 'Department',
            accessor: 'office.name',
            render: row => <p>{row?.office?.name || '-'}</p>,
        },
    ];

    const { data: inistialUsers }: any = useGetUsersQuery(
        { pageNumber: 1, role: 'technician' },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const UserTotal = inistialUsers?.total;

    const { isLoading: userLoad, data: users } = useGetUsersQuery(
        { pageNumber: 1, pageSize: UserTotal, role: 'technician' },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const { data: initialClients }: any = useGetClientsQuery(
        { pageNumber: 1 },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    const ClientToatal = initialClients?.total;

    const { isLoading: ClientLLoad, data: clients } = useGetClientsQuery(
        { pageNumber: 1, pageSize: UserTotal },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );
    return (
        <><ul className="flex space-x-2 rtl:space-x-reverse mb-4 ">
            <li>
                <Link
                    to="/management"
                    className="text-primary hover:underline"
                >
                    Dashboard
                </Link>
            </li>

            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2 text-gray-400">
                <span>Assets Reports</span>
            </li>
        </ul><ExportDataTable
                columns={columns}
                data={sortedData ?? []}
                total={data?.total ?? 0}
                tableName="Assets Reports"
                currentPage={data?.currentPage ?? 0}
                nextPage={data?.nextPage ?? 0}
                previousPage={data?.previousPage ?? 0}
                lastPage={data?.lastPage ?? 0}
                isLoading={isLoading}
                filterComponent={<Filter
                    resetFilters={resetFilters}
                    title="Filter  Assets"
                    children={<>
                        <div className="flex flex-col  ">
                           
                            <div className="mb-2">
                                <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                    Filter by Client
                                </label>
                                <select
                                    id="products"
                                    className="form-select"
                                    onChange={e => setClientId(e.target.value)}
                                >
                                    <option value="" disabled>
                                        Select Client
                                    </option>
                                    {ClientLLoad ? (
                                        <option value="" disabled>
                                            Loading clients...
                                        </option>
                                    ) : clients?.list.length === 0 ? (
                                        <option value="" disabled>
                                            No clients found
                                        </option>
                                    ) : (
                                        clients?.list.map(
                                            (category: {
                                                id: string | any;
                                                name: string | any;
                                            }) => (
                                                <option
                                                    key={category.id}
                                                    value={category.id}
                                                >
                                                    {category.name}
                                                </option>
                                            )
                                        )
                                    )}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                    {' '}
                                    Status :
                                </label>
                                <select
                                    className="form-select"
                                    name="role"
                                    onChange={e => setStatus(e.target.value)}
                                >
                                    <option value=""> Select status</option>

                                    <option value="complete">
                                        {' '}
                                        Complete{' '}
                                    </option>
                                    <option value="pending">
                                        {' '}
                                        Pending
                                    </option>
                                    <option value="rejected">
                                        {' '}
                                        Rejected{' '}
                                    </option>
                                    <option value="approved">
                                        {' '}
                                        Approved{' '}
                                    </option>
                                    <option value="partial">
                                        {' '}
                                        Partially Approved{' '}
                                    </option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                    From :
                                </label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    className="form-input"
                                    onChange={e => setStartDateFilter(e.target.value)} />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-600 dark:text-gray-400">
                                    To
                                </label>
                                <input
                                    type="date"
                                    id="toDate"
                                    className="form-input "
                                    onChange={e => setEndDateFilter(e.target.value)} />
                            </div>
                        </div>
                    </>} />} /></>
    );
}

export default AssetsReports;
