import React from 'react';
import { useParams } from 'react-router-dom';
import { useProductDetailQuery } from '@/app/Auth/redux';
import { storage } from '@/utils';

function ProductDetails() {
    const { serial_number: urlSerialNumber }: any = useParams();
    const cleanedSerialNumber = urlSerialNumber.replace(/^0+/, '');
    const { isLoading, data, error }: any = useProductDetailQuery(
        { serial_number: cleanedSerialNumber },
        {
            skip: !storage.getToken(),
            refetchOnMountOrArgChange: true,
        }
    );

    if (isLoading) {
        return <p className="text-lg text-center">Loading...</p>;
    }

    if (error || !data) {
        return (
            <p className="text-lg text-center">
                Product details not available.
            </p>
        );
    }

    const { product, stockIn, availability_status } = data;

    return (
        <>
            <h2 className="text-2xl font-semibold mb-4">Product Details</h2>
            <div className=" shadow rounded dark:bg-slate-900 bg-gray-100 w-fit p-2  ">
            <p className=" p-1 text-lg text-center ">Product</p>
                <div className=" grid grid-cols-2">
 
                <p className=" p-2 text-md">
                        <strong>Product status:</strong> { data?.technician_mini_stock?.type || '-'}
                    </p>
                    <p className=" p-2 text-md">
                        <strong>Product Name:</strong> {product?.name || data?.technician_mini_stock?.logistic_mini_stock?.product?.name}
                    </p>

                    <p className=" p-2 text-md">
                        <strong>Product Model:</strong> {product?.model ||  data?.technician_mini_stock?.logistic_mini_stock?.product?.model}
                    </p>
                    <p className=" p-2 text-md">
                        <strong>Product Color:</strong> {product?.color ||  data?.technician_mini_stock?.logistic_mini_stock?.product?.color}
                    </p>
                    <p className=" p-2 text-md">
                        <strong>Product Selling Price:</strong>{' '}
                        {stockIn?.selling_price}
                    </p>
                    <p className=" p-2 text-md">
                        <strong>Serial Number:</strong> {cleanedSerialNumber}
                    </p>
                    <p className=" p-2 text-md">
                        <strong>Availability Status:</strong>{' '}
                        {availability_status || data?.technician_mini_stock?.availability_status}                   </p>
                </div>

           
                <p className=" p-1 text-lg text-center "> user</p>
                <div className="grid grid-cols-2">
                    <p className=" p-2 text-md">
                        <strong> Name:</strong> {data?.user?.name}
                    </p>
                    <p className=" p-2 text-md">
                        <strong> username:</strong> {data?.user?.username}
                    </p>
                    <p className=" p-2 text-md">
                        <strong> Phone:</strong> {data?.user?.phoneNumber}
                    </p>
                    <p className=" p-2 text-md">
                        <strong> Email:</strong> {data?.user?.email}
                    </p>
                </div>
                {
                    data.client && (
                        <><p className=" p-1 text-lg text-center "> Client</p><div className="grid grid-cols-2">
                            <p className=" p-2 text-md">
                                <strong> Name:</strong> {data?.client?.name}
                            </p>



                            <p className=" p-2 text-md">
                                <strong> Phone:</strong> {data?.client?.phoneNumber}
                            </p>
                            <p className=" p-2 text-md">
                                <strong> Email:</strong> {data?.client?.email}
                            </p>
                            <p className=" p-2 text-md">
                                <strong> Office:</strong> {data?.client?.office}
                            </p>
                            <p className=" p-2 text-md">
                                <strong> Type:</strong> {data?.client?.type}
                            </p>
                        </div></>
                    )
                }
               
            </div>
        </>
    );
}

export default ProductDetails;
