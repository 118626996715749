import React, { useEffect } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import IconPlus from '@/components/Icon/IconPlus';
import { Form } from '@/components/form';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { useAdminSignMutation, useUpdateUserMutation } from '@/app/Auth/redux';
import { useDispatch } from 'react-redux';
import { InputField } from '@/components/input';
import { CircularProgress } from '@mui/material';
import IconPencil from '@/components/Icon/IconPencil';
import IconEdit from '@/components/Icon/IconEdit';

const schema = z.object({
    username: z.string().min(1, 'Email address is  required'),
    name: z.string().min(1, 'Names are  required'),
    role: z.string().min(1, 'Role is  required'),
    phoneNumber: z.string().min(1, 'Phone Number  is  required'),
    email: z.string().min(1, 'Email is  required'),
   
});

export type EditUserInput = z.TypeOf<typeof schema>;

export const UpdateUser = ({user,handleRefetch}:any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const [OnPost, { isSuccess, isLoading }] = useUpdateUserMutation();

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-warning"
                >
                    <IconEdit className="w-4" />
                  
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Update User
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<EditUserInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (payload) => {
                                              await OnPost({ id: user.id, data: payload });
                                            
                                              setModal1(false)
                                              await handleRefetch()
                                              
                                          }}
                                            className="mt-10  "
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="gap-2 grid grid-cols-2">
                                                        <InputField
                                                            type="text"
                                                            label="Names"
                                                            placeholder="Ex. John Doe"
                                                            defaultValue={user?.name}
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .name
                                                            }
                                                            registration={register(
                                                                'name'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Username"
                                                            defaultValue={user?.username}
                                                            placeholder="Username"
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .username
                                                            }
                                                            registration={register(
                                                                'username'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Email"
                                                            defaultValue={user?.email}
                                                            placeholder="Email"
                                                            loading={isLoading}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .email
                                                            }
                                                            registration={register(
                                                                'email'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            label="Phone Number"
                                                            placeholder="Phone"
                                                            loading={isLoading}
                                                            defaultValue={user?.phoneNumber}
                                                            focus
                                                            error={
                                                                formState.errors
                                                                    .phoneNumber
                                                            }
                                                            registration={register(
                                                                'phoneNumber'
                                                            )}
                                                            
                                                        />
                                                        <div className="">
                                                            <label htmlFor="">
                                                                {' '}
                                                                Role
                                                            </label>
                                                            <select
                                                           
                                                                id=""
                                                                className="form-select "
                                                                {...register('role')}
                                                                defaultValue={user?.role}
                                                            >
                                                                <option value="admin">
                                                                    {' '}
                                                                    Admin{' '}
                                                                </option>
                                                                    <option value="finance">
                                                                    {' '}
                                                                    Finance{' '}
                                                                </option>
                                                                <option value="management">
                                                                    {' '}
                                                                    Manager{' '}
                                                                </option>
                                                                <option value="technician">
                                                                    {' '}
                                                                    Technician{' '}
                                                                </option>
                                                                <option value="logistic_manager">
                                                                    Logistic
                                                                    manager
                                                                </option>
                                                                <option value="stock_manager">
                                                                    Stock
                                                                    manager
                                                                </option>
                                                            </select>
                                                        </div>

                                                       
                                                    </div>
                                                    <div className="flex justify-end items-center mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger mx-2 "
                                                            onClick={() => {
                                                                setModal1(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            Discard
                                                        </button>
                                                        {isLoading ? (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                            >
                                                                <CircularProgress
                                                                    size={24}
                                                                    sx={{
                                                                        color: 'white',
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className=" btn btn-primary"
                                                            >
                                                                Update
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
