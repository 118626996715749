import React, { useEffect } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { FaReadme } from 'react-icons/fa';
import formatDateToLongForm from '@/utils/DateFormattter';
import IconEye from '@/components/Icon/IconEye';

export const More = ({ list }: any) => {
    const [modal1, setModal1] = useState(false);
   

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary"
                >
                    <IconEye className="w-4" />
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-4xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full  ">
                                            Sales Details
                                        </div>
                                    </div>
                                    {list && (
                                        <div className="grid grid-cols-2 gap-4 p-4">
                                            {/* Lease Information */}
                                            <div className="flex flex-col border-r border-gray-300 pr-4">
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Lease Information:
                                                </p>
                                               
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Count:
                                                    </span>{' '}
                                                    {list?.count}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Return Count:
                                                    </span>{' '}
                                                    {list?.returnCount}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Reason:
                                                    </span>{' '}
                                                    {list?.reason}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Used Percentage:
                                                    </span>{' '}
                                                    {list?.usedPercentage}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Created At:
                                                    </span>{' '}
                                                    {formatDateToLongForm(
                                                        list?.created_at
                                                    )}
                                                </p>
                                            </div>
                                            {/* Technician Mini Stock Information */}
                                            <div className="flex flex-col pl-4">
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Technician Mini Stock
                                                    Information:
                                                </p>
                                               
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Availability Status:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.availability_status
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Status:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.status
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Client Name:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.client_name
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Type:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.type
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Technician Mini Stock
                                                        Created At:
                                                    </span>{' '}
                                                    {formatDateToLongForm(
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.created_at
                                                    )}
                                                </p>
                                            </div>
                                            {/* Logistic Mini Stock Information */}
                                            <div className="flex flex-col border-r border-gray-300 pr-4">
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Logistic Mini Stock
                                                    Information:
                                                </p>
                                               
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Serial Number:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.serial_number
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        TIN Number:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.tin_number
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Availability Status:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.availability_status
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Technician Owned:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.technician_owned
                                                    || '-'}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Status:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.status
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Logistic Mini Stock
                                                        Created At:
                                                    </span>{' '}
                                                    {formatDateToLongForm(
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.created_at
                                                    )}
                                                </p>
                                            </div>
                                            {/* Product Information */}
                                            <div className="flex flex-col pl-4">
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    Product Information:
                                                </p>
                                               
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Name:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.product?.name
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Count:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.product?.count
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Model:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.product?.model
                                                    }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Color:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.product?.color
                                                    }
                                                </p>
                                                
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Selling Price:
                                                    </span>{' '}
                                                    {
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.product
                                                            ?.selling_price
                                                   || '-'  }
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Product Created At:
                                                    </span>{' '}
                                                    {formatDateToLongForm(
                                                        list
                                                            ?.technician_mini_stock
                                                            ?.logistic_mini_stock
                                                            ?.product
                                                            ?.created_at
                                                    )}
                                                </p>
                                            </div>
                                            {/* User Information */}
                                            <div className="flex flex-col">
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    User Information:
                                                </p>
                                               
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Name:
                                                    </span>{' '}
                                                    {list?.user?.name}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Username:
                                                    </span>{' '}
                                                    {list?.user?.username}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Email:
                                                    </span>{' '}
                                                    {list?.user?.email}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Phone Number:
                                                    </span>{' '}
                                                    {list?.user?.phoneNumber}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Role:
                                                    </span>{' '}
                                                    {list?.user?.role}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        Status:
                                                    </span>{' '}
                                                    {list?.user?.status}
                                                </p>
                                                <p className="text-sm">
                                                    <span className="font-semibold">
                                                        User Created At:
                                                    </span>{' '}
                                                    {formatDateToLongForm(
                                                        list?.user?.created_at
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    )}

                                    {/* Add more details as needed */}
                                    <div className="flex justify-end p-4">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger mx-2 "
                                            onClick={() => {
                                                setModal1(false);
                                            }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
